/* eslint-disable */
import { jsx } from "@emotion/react";
import React, { useState, useEffect } from "react";
import "./styles.scss";

const UsersModal = ({ handleClose, data, reach, filters }) => {
  const [jsonData, setJsonData] = useState({});
  const [foundAgencies, setFoundAgencies] = useState([]);

  useEffect(() => {
    if (data) setJsonData(JSON.parse(data));
  }, [data]);

  useEffect(() => {
    jsonData?.carLiabilityInsuranceAgencies?.$in?.map((agenc) => {
      filters.filter((filt) => filt?._id === agenc);
    });
  }, [filters]);

  console.log(reach);
  return (
    <div className="users-modal-component">
      <div className="flex-container full-width align-center" style={{ marginBottom: "20px" }}>
        <div className="close-icon" style={{ marginRight: "2%" }} onClick={() => handleClose()} />
        <h2>Targets</h2>
      </div>
      <div className="info-container">
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Targeted users:</p>
            <p style={{ paddingTop: "5px", fontSize: "18px" }}>
              <b style={{ fontSize: "18px", marginRight: "5px" }}>{reach?.all} | </b> {reach?.agreement || "---"} with a direct marketing agreement
            </p>
          </div>
        </div>
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Cities:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.cities ? (jsonData?.cities?.$in?.length > 99 ? "Target All" : jsonData?.cities?.$in?.map((el) => el + "; ")) : "Target all"}</h3>
          </div>
        </div>
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Gender:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.genders ? jsonData?.genders?.$in?.map((el) => el.toUpperCase() + "; ") : "Target all"}</h3>
          </div>
        </div>
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Age groups:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.ages ? `${jsonData?.ages?.$gte || "0"} - ${jsonData?.ages?.$lte || "∞"}` : "Target all"}</h3>
          </div>
        </div>
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Vehicle's brand:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.carMakes ? jsonData?.carMakes?.$in?.map((el) => el.toUpperCase() + "; ") : "Target all"}</h3>
          </div>
        </div>
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Vehicle's age:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.carAges ? `${jsonData?.carAges?.$gte || "0"} - ${jsonData?.carAges?.$lte || "∞"}` : "Target all"}</h3>
          </div>
        </div>
        <div className="inner-info-container flex-container" style={{ height: "unset" }}>
          <div
            className="divider vertical"
            style={{
              width: "10px",
              margin: "0px",
              paddingRight: "10px",
              height: "60px",
            }}
          />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Car insurance company:</p>
            <h3 style={{ paddingTop: "5px" }}>
              {jsonData?.carLiabilityInsuranceAgencies
                ? jsonData?.carLiabilityInsuranceAgencies?.$in?.map((agenc) => {
                    return filters.filter((filt) => filt?._id === agenc)[0].name + ", ";
                  })
                : "Target all"}
            </h3>
          </div>
        </div>
        <div className="inner-info-container flex-container" style={{ height: "unset" }}>
          <div
            className="divider vertical"
            style={{
              width: "10px",
              margin: "0px",
              paddingRight: "10px",
              height: "60px",
            }}
          />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Autocasco insurance company:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.kaskoInsuranceAgencies ? jsonData?.kaskoInsuranceAgencies?.$in?.map((el) => el + "; ") : "Target all"}</h3>
          </div>
        </div>
        <div className="inner-info-container flex-container">
          <div className="divider vertical" style={{ width: "10px", margin: "0px" }} />
          <div style={{ padding: "5px" }}>
            <p style={{ fontSize: "0.9rem" }}>Operating system:</p>
            <h3 style={{ paddingTop: "5px" }}>{jsonData?.os ? jsonData?.os?.$in?.map((el) => el.toUpperCase() + "; ") : "Target all"}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersModal;
