export const adsStats = {
  GET_ADS: "ads/GET_ADS",
  GET_ADS_SUCCESS: "ads/GET_ADS_SUCCESS",
  CREATE_AD: "ads/CREATE_AD",
  GET_ALL_FILTERS: "ads/GET_ALL_FILTERS",
  GET_ALL_FILTERS_SUCCESS: "ads/GET_ALL_FILTERS_SUCCESS",
  CHECK_REACHED: "ads/CHECK_REACHED",
  CHECK_REACHED_SUCCESS: "ads/CHECK_REACHED_SUCCESS",
  LIST_REACHED: "ads/LIST_REACHED",
  LIST_REACHED_SUCCESS: "ads/LIST_REACHED_SUCCESS",
  FIND_USER_BY_PHONE: "ads/FIND_USER_BY_PHONE",
  GET_CURRENT_AD: "ads/GET_CURRENT_AD",
  GET_CURRENT_AD_SUCCESS: "ads/GET_CURRENT_AD_SUCCESS",
  EDIT_AD: "ads/EDIT_AD",
  SET_QUERY: "ads/SET_QUERY",
};
export const getAds = (payload) => ({
  type: adsStats.GET_ADS,
  payload,
});
export const createAd = (payload) => ({
  type: adsStats.CREATE_AD,
  payload,
});
export const getAllFilters = (payload) => ({
  type: adsStats.GET_ALL_FILTERS,
  payload,
});
export const checkReached = (payload) => ({
  type: adsStats.CHECK_REACHED,
  payload,
});
export const listReached = (payload) => ({
  type: adsStats.LIST_REACHED,
  payload,
});
export const findUserByPhone = (payload) => ({
  type: adsStats.FIND_USER_BY_PHONE,
  payload,
});
export const getCurrentAd = (payload) => ({
  type: adsStats.GET_CURRENT_AD,
  payload,
});
export const editAd = (payload) => ({
  type: adsStats.EDIT_AD,
  payload,
});

export const setCurrentQuery = (payload) => ({
  type: adsStats.SET_QUERY,
  payload,
});
