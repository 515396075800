import { default as Button } from "./Button";
import { default as TextInput } from "./TextInput";
import { default as RadioButtons } from "./RadioButtons";
import { default as Switch } from "./Switch";
import { default as RichText } from "./RichText";
import { default as Dropdown } from "./Dropdown";
import { default as ImageInput } from "./ImageInput";
import { default as SingleSelect } from "./SingleSelect";
import { default as GooglePlacesAutoComplete } from "./GooglePlacesAutoComplete";
import { default as DatePicker } from "./DatePicker";
import { default as CheckBoxes } from "./Checkboxes";
import { default as FileUpload } from "./FileUpload";
import { default as TextAreaInput } from "./TextAreaInput";
const Inputs = {
  Button,
  TextInput,
  ImageInput,
  RadioButtons,
  Switch,
  RichText,
  Dropdown,
  SingleSelect,
  GooglePlacesAutoComplete,
  DatePicker,
  CheckBoxes,
  FileUpload,
  TextAreaInput
};

export default Inputs;
