/* eslint-disable */
import React from "react";
import "./styles.scss";
import { forwardRef } from "react";

const TextAreaInput = forwardRef(({ outerStyle, outerClassName, inputClassName, inputStyle, label, compulsory, disabled, suffix, ...props }, ref) => {
  return (
    <div className={`${outerClassName} input-container`} style={outerStyle}>
      {label && (
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
        </label>
      )}

      <div className={` prefix-input-container ${disabled && "disabled"} ${inputClassName}`} style={inputStyle}>
        <textarea
          style={{ border: "none", resize: "none", outline: "none", padding: "0px 8px", height: "90%", width: "100%", borderRadius: "7px" }}
          disabled={disabled}
          {...props}
          ref={ref}
        />
        {suffix && suffix}
      </div>
    </div>
  );
});

export default TextAreaInput;
