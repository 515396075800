/* eslint-disable */
import React, { useEffect, useState } from "react";
import Inputs from "../../components/Inputs";
import { getDiscountsUsed, getDiscounts } from "../../actions";
import { connect } from "react-redux";
import DisTypesGrids from "./Grids";
import "./styles.scss";
import { history } from "../../config/stores";

const Discounts = ({ getDiscounts, getDiscountsUsed, discounts, discountsUsed }) => {
  const [selectedTab, setSelectedTab] = useState("discounts");
  const [loading, setLoading] = useState(true);

  const renderContent = () => {
    switch (selectedTab) {
      case "discounts":
        return <DisTypesGrids.DiscountsGrid docs={discounts} getDiscounts={getDiscounts} loading={loading} setLoading={setLoading} />;
      case "used":
        return (
          <DisTypesGrids.UsedDiscountsGrid docs={discountsUsed} getDiscountsUsed={getDiscountsUsed} loading={loading} setLoading={setLoading} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-container">
      <div className="body-container" style={{ height: "100%", marginTop: "0%" }}>
        <div className="flex-container space-between align-center full-width">
          <div className="flex-container" style={{ width: "25%" }}>
            <div
              className={`tab-button flex-container justify-center align-center ${selectedTab === "discounts" && "selected"}`}
              onClick={() => {
                setLoading(true);
                setSelectedTab("discounts");
              }}
            >
              Discounts
            </div>
            <div
              className={`tab-button flex-container justify-center align-center ${selectedTab === "used" && "selected"}`}
              onClick={() => {
                setLoading(true);
                setSelectedTab("used");
              }}
            >
              Used
            </div>
          </div>
          <Inputs.Button text="GENERATE" selected onClick={() => history.push("/discount/add")} />
        </div>
        {renderContent()}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  discounts: state.discounts.discounts,
  discountsUsed: state.discounts.discountsUsed,
});
const mapDispatchToProps = (dispatch) => ({
  getDiscounts: (payload) => dispatch(getDiscounts(payload)),
  getDiscountsUsed: (payload) => dispatch(getDiscountsUsed(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Discounts);
