/* eslint-disable */
import React, { useState } from "react";
import Inputs from "../../../../components/Inputs";

const AddUserModal = ({ handleClose, handleUpdate }) => {
  const [user, setUser] = useState({
    name: "",
    phoneNumber: "",
  });
  return (
    <div className="inner-modal-component" style={{ height: "35%", width: "35%" }}>
      <div className="full-width">
        <div className="flex-container full-width align-center" style={{ marginBottom: "20px" }}>
          <div className="close-icon" style={{ marginRight: "2%" }} onClick={() => handleClose()} />
          <h3>ADD USER</h3>
        </div>
        <Inputs.TextInput
          label="Names"
          compulsory
          value={user.name}
          onChange={(e) => {
            setUser({ ...user, name: e.target.value });
          }}
        />
        <Inputs.TextInput
          label="Phone number"
          style={{ marginTop: "10%" }}
          value={user.phoneNumber}
          number
          compulsory
          prefix={<div className="flex-container align-center">+359</div>}
          onChange={(e) => {
            setUser({ ...user, phoneNumber: e.target.value });
          }}
        />
      </div>
      <Inputs.Button
        style={{ width: "100%", paddingTop: "1%" }}
        text="Apply"
        selected
        onClick={() => {
          handleUpdate(user);
          setUser({ name: "", phoneNumber: "" });
          handleClose();
        }}
      />
    </div>
  );
};

export default AddUserModal;
