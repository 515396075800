import React from "react"
import "./styles.scss"

const RadioButton = (props) => {
  return (
    <div className={`RadioButton ${props.color ? props.color : ''}`}>
      {props.onClick ?
      <input
        id={props.id}
        onClick={() => {
          props.onClick()
        }}
        value={props.value}
        type="radio"
        checked={props.isSelected}
      /> :
      <input
      id={props.id}
      onChange={() => {
        props.changed()
      }}
      value={props.value}
      type="radio"
      checked={props.isSelected}
    />}
      <label htmlFor={props.id}>{props.label}</label>
    </div>
  )
}

export default RadioButton
