import { discountsStats } from "../actions";

const initialState = {
  discounts: {},
  discountsUsed: {},
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;
  else return [...state?.[key]?.docs, ...payload?.docs];
};

const discounts = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case discountsStats.GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        discounts: {
          totalPages: payload.totalPages,
          docs: handleNewDocs(state, payload, "discounts"),
        },
      };
    case discountsStats.GET_DISCOUNTS_USED_SUCCESS:
      return {
        ...state,
        discountsUsed: {
          totalPages: payload.totalPages,
          docs: handleNewDocs(state, payload, "discountsUsed"),
        },
      };
    default:
      return state;
  }
};

export default discounts;
