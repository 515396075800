export const socketTypes = {
  SOCKET_INIT: "socket/init",

  STATS_CONNECT: "socket/STATS_CONNECT",
  STATS_CONNECTED: "socket/STATS_CONNECTED",
  STATS_EMIT_EVENT: "socket/STATS_EMIT_EVENT",
  STATS_DISCONNECT: "socket/STATS_DISCONNECT",

  TASKS_CONNECT: "socket/TASKS_CONNECT",
  TASKS_CONNECTED: "socket/TASKS_CONNECTED",
  TASKS_EMIT_EVENT: "socket/TASKS_EMIT_EVENT",
  TASKS_DISCONNECT: "socket/TASKS_DISCONNECT",
};

export const setLoadingOn = () => ({
  type: socketTypes.SOCKET_INIT,
});

export const statsSocketConnect = (payload) => ({
  type: socketTypes.STATS_CONNECT,
  payload,
});

export const statsSocketConnected = () => ({
  type: socketTypes.STATS_CONNECTED,
});

export const statsSocketEmit = (payload) => ({
  type: socketTypes.STATS_EMIT_EVENT,
  payload,
});

export const statsSocketDisconnect = () => ({
  type: socketTypes.STATS_DISCONNECT,
});

export const tasksSocketConnect = (payload) => ({
  type: socketTypes.TASKS_CONNECT,
  payload,
});

export const tasksSocketConnected = () => ({
  type: socketTypes.TASKS_CONNECTED,
});

export const tasksSocketEmit = (payload) => ({
  type: socketTypes.TASKS_EMIT_EVENT,
  payload,
});

export const tasksSocketDisconnect = () => ({
  type: socketTypes.TASKS_DISCONNECT,
});
