import { combineEpics } from "redux-observable";
// import * as starter from "./starter";
import * as dashboard from "./dashboard";
import * as app from "./app";
import * as articles from "./articles";
import * as offices from "./offices";
import * as finances from "./finances";
import * as auth from "./auth";
import * as ads from "./ads";
import * as socket from "./socket";
import * as discounts from "./discounts";
export const rootEpic = combineEpics(
  // starter.loadingEpic,
  dashboard.getActiveUsersEpic,
  dashboard.getTotalAppDownloadsEpic,
  dashboard.getIncomeRevenueEpic,
  dashboard.getAppSessionsEpic,
  dashboard.getRegisteredUsersEpic,
  dashboard.getInnerLicensesEpic,
  dashboard.getInnerVehiclesEpic,
  dashboard.getVehiclesEpic,
  dashboard.getAppGrowthEpic,
  dashboard.getDownloadsByDayEpic,
  dashboard.getReviewsByOsEpic,
  dashboard.getRatingsEpic,
  dashboard.getNewSubsEpic,
  dashboard.getActiveSubsEpic,
  app.getAddedPoliciesEpic,
  app.getOrderedPoliciesEpic,
  app.getOrderedInstallementsEpic,
  app.getPaidFinesEpic,
  app.getVignettesEpic,
  app.getClaimsEpic,
  app.getParkingEpic,
  app.getGeoWashEpic,
  app.getDashCamEpic,
  app.getManualEventsEpic,
  app.getManualExpensesEpic,
  app.getNewPoliciesInfoEpic,
  app.getAddedPoliciesInfoEpic,
  app.getOrderedInstallementsInfoEpic,
  app.getPaidFinesInfoEpic,
  app.getVignettesInfoEpic,
  app.getParkingInfoEpic,
  app.getClaimsInfoEpic,
  app.getCostsInfoEpic,
  app.getEventsInfoEpic,
  app.getGeowashOrdersEpic,
  app.getDashCamStatsEpic,
  articles.getArticlesEpic,
  articles.getCurrentArticleEpic,
  articles.addArticleEpic,
  articles.deleteArticleEpic,
  articles.editArticleEpic,
  offices.getOfficesEpic,
  offices.getCurrentOfficeEpic,
  offices.addOfficeEpic,
  offices.deleteOfficeEpic,
  offices.editOfficeEpic,
  finances.getGeneratedIncomeOnlineEpic,
  finances.getGeneratedIncomeInstallmentsEpic,
  finances.getGeneratedIncomePoliciesEpic,
  finances.getPotentialIncomeEpic,
  auth.loginRequestCodeEpic,
  auth.checkCodeEpic,
  auth.logInEpic,
  auth.logOut,
  ads.getAds,
  ads.getAllFiltersEpic,
  ads.checkReachedEpic,
  ads.findUserByPhoneEpic,
  ads.createAd,
  ads.getCurrentAdEpic,
  ads.editAdEpic,
  ads.listReachedEpic,
  socket.statsConnectEpic,
  socket.statsListenerEpic,
  // socket.tasksConnectEpic,
  // socket.tasksListenerEpic,
  // socket.tasksSenderEpic,
  discounts.getDiscountsEpic,
  discounts.getDiscountsUsedEpic,
  discounts.createDiscountEpic,
  discounts.editUpcomingDiscountEpic,
  discounts.deleteUpcomingDiscountEpic,
  discounts.getInsuranceAgenciesEpic,
  discounts.deactivateActiveDiscountEpic,
  discounts.getCurrentDiscountEpic
);
