import { adsStats } from "../actions";

const initialState = {
  filters: {},
  reached: null,
  reachedList: {
    totalPages: 0,
    docs: [],
  },
  ads: {},
  currentAd: {},
  query: {},
};

const handleNewDocs = (state, payload, key) => {
  // console.log("payload", payload, "state", state?.[key]);
  if (payload?.page === 1) return payload?.docs;
  else if (payload?.docs) return [...state?.[key]?.docs, ...payload?.docs];
};

const ads = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case adsStats.GET_ALL_FILTERS_SUCCESS:
      return {
        ...state,
        filters: payload,
      };
    case adsStats.CHECK_REACHED_SUCCESS:
      return {
        ...state,
        reached: payload,
      };
    case adsStats.LIST_REACHED_SUCCESS:
      return {
        ...state,
        reachedList: {
          totalPages: payload.totalPages,
          docs: handleNewDocs(state, payload, "reachedList"),
        },
      };
    case adsStats.GET_ADS_SUCCESS:
      return {
        ...state,
        ads: {
          totalPages: payload.totalPages,
          docs: handleNewDocs(state, payload, "ads"),
        },
      };
    case adsStats.GET_CURRENT_AD_SUCCESS:
      return {
        ...state,
        currentAd: payload,
      };
    case adsStats.SET_QUERY:
      return {
        ...state,
        query: payload,
      };
    default:
      return state;
  }
};

export default ads;
