import React from "react";
import Inputs from "../../../components/Inputs";

const DeleteModal = ({ handleClose, handleSubmit, header, body }) => {
  return (
    <div className="delete-modal-container p-8" style={{ width: "40%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <h1>{header}</h1>
        <p>{body}</p>
        <div className="flex-container full-width">
          <Inputs.Button text="Откажи" style={{ width: "49%", marginRight: "1%" }} onClick={handleClose} />
          <Inputs.Button
            text="Изтрий"
            cancel
            style={{ width: "49%" }}
            onClick={() => {
              handleSubmit();
              handleClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
