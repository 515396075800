import React from "react";
import Popup from "reactjs-popup";
import { monthOptions, options } from "../../../config/constants";
import Inputs from "../../Inputs";

const SelectPeriod = ({ selPeriod, setSelPeriod, setFiltered, growth, selected, startField, endField, dash }) => {
  return (
    <div style={{ display: "flex" }}>
      {" "}
      {growth ? (
        <p className="period-text">
          {monthOptions.filter((opt) => opt[startField] === selPeriod[startField]).length > 0
            ? monthOptions.filter((opt) => opt[startField] === selPeriod[startField])[0].label
            : "All"}
        </p>
      ) : (
        <p className="period-text">
          {options.filter((opt) => opt.value === selPeriod[startField]).length > 0 ? options.filter((opt) => opt.value === selPeriod[startField])[0].label : "All"}
        </p>
      )}
      <Popup className="popup-header-options-container" trigger={<div className={`settings-icon ${selected && "selected"}`} />} position="bottom right">
        {(close) => (
          <Inputs.RadioButtons
            options={growth ? monthOptions : options}
            column
            value={selPeriod[startField]}
            onChange={(period) => {
              if (growth) {
                setSelPeriod({
                  [startField]: period[startField] === null ? null : period[startField],
                  [endField]: period?.[endField] === null ? null : period?.[endField],
                });
              } else {
                setSelPeriod({
                  ...selPeriod,
                  [startField]: period?.value === null ? null : period,
                  label: period.label,
                });
              }
              setFiltered(true);
              close();
            }}
          />
        )}
      </Popup>
    </div>
  );
};

export default SelectPeriod;
