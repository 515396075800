import React, { useEffect, useRef, useState } from "react";
import Inputs from "../../Inputs";
import { isArray, omit, uniqBy } from "lodash";
import moment from "moment";

const FiltersPopup = ({ close, filter: filterProp = {}, filterOptions, handleSubmit }) => {
  const { type, key, options, fetch, transform } = filterOptions;

  const [filter, setFilter] = useState(filterProp);
  const [query, setQuery] = useState("");

  const [dbOptions, setDbOptions] = useState([]);
  const allOptions = uniqBy([...(isArray(filter[key]) ? filter[key] : []), ...dbOptions], "value");
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current || type !== "dbDropdown" || !["departments"].includes(key)) return;
    firstRender.current = false;
    fetch()
      .then(({ data: { payload } }) => setDbOptions(transform(payload)))
      .catch((error) => console.error(error));
  }, [dbOptions.length, fetch, key, transform, type]);

  const timerRef = useRef(null);
  useEffect(() => {
    const timer = timerRef.current;
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);
  const handleUpdateDbOptions = (pattern) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(
      () =>
        fetch({ pattern })
          .then(({ data: { payload } }) => setDbOptions(transform(payload)))
          .catch((error) => console.error(error)),
      300
    );
  };

  const renderContent = () => {
    switch (type) {
      case "dbDropdown":
        return (
          <div>
            <Inputs.TextInput
              placeholder="Търси"
              value={query}
              onChange={({ target: { value } }) => {
                setQuery(value);
                handleUpdateDbOptions(value);
              }}
              {...(query && { suffix: <div className="close-icon h-3 w-3 mr-2" onClick={() => setQuery("")} /> })}
            />
            {allOptions.length > 0 && (
              <>
                <div className="mt-[10px] h-[1px] bg-[#515867]" />
                <Inputs.Checkboxes
                  style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
                  column
                  full
                  options={allOptions}
                  value={filter[key]?.map(({ value }) => value) ?? []}
                  onChange={(value) => {
                    const current = filter[key] ?? [];
                    if (current.some((c) => c.value === value.value)) setFilter({ ...filter, [key]: current.filter((c) => c.value !== value.value) });
                    else setFilter({ ...filter, [key]: [...current, value] });
                  }}
                />
              </>
            )}
          </div>
        );
      case "checkboxes":
        return (
          <Inputs.CheckBoxes
            style={{ maxHeight: "400px", overflow: "auto" }}
            column
            full
            special
            options={options}
            value={filter[key] || ""}
            onChange={({ value }) => {
              const current = filter[key] ?? [];
              if (current.includes(value)) setFilter({ ...filter, [key]: current.filter((v) => v !== value) });
              else setFilter({ ...filter, [key]: [...current, value] });
            }}
          />
        );
      case "dateRange":
        return (
          <div>
            <Inputs.DatePicker
              label="От дата"
              compulsory
              value={filter?.[key]?.min ? moment(filter?.[key]?.min)?._d : ""}
              onChange={(value) => {
                setFilter({ ...filter, [key]: { ...filter?.[key], min: value === "" ? undefined : value } });
              }}
              maxDate={filter?.[key]?.max ?? null}
            />
            <Inputs.DatePicker label="До дата" compulsory value={filter?.[key]?.max ? moment(filter?.[key]?.max)?._d : ""} onChange={(value) => setFilter({ ...filter, [key]: { ...filter?.[key], max: value === "" ? undefined : value } })} minDate={filter?.[key]?.min ?? null} />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="filters-popup-container">
      <div className="flex-container full-width space-between align-center" style={{ padding: "10px" }}>
        <h1 style={{ fontSize: "16px" }}>Филтрирай по</h1>
        <div className="close-icon w-4 h-4" onClick={close} />
      </div>
      <div className="filters-popup-content px-[10px] py-[10px]">{renderContent()}</div>
      {(type !== "dbDropdown" || filter[key] || allOptions.length > 0) && (
        <div className="filters-popup-footer rounded-b-md w-full flex gap-[5px] px-[10px] py-[5px] shadow-[0px_-1px_6px_-4px_#515867]">
          <Inputs.Button
            text="ИЗЧИСТИ"
            className={"border border-[#515867] w-1/2"}
            disabled={!filterProp[key]}
            onClick={() => {
              handleSubmit(omit(filter, [key]));
              close();
            }}
          />
          <Inputs.Button
            text="ЗАПАЗИ"
            className={"selected w-1/2"}
            disabled={!filter[key]}
            onClick={() => {
              handleSubmit(filter);
              close();
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FiltersPopup;
