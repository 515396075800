/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { getAllFilters, checkReached, findUserByPhone, createAd, getCurrentAd, setCurrentQuery, editAd } from "../../actions";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import ModalComponent from "../ModalComponent/ModalComponent";
import "./styles.scss";
import InnerModal from "./InnerModal";
import { mapValues } from "lodash";
import { API_URL } from "../../config/settings";
import { Headers } from "../../utilites";
import { toast } from "react-toastify";

const options = [
  {
    label: "Impressions",
    value: "impressions",
  },
  {
    label: "Push notifications",
    value: "push",
  },
  {
    label: "Email",
    value: "email",
  },
];

const modalOptions = [
  {
    text: "Cities",
    modal: "cities",
  },
  {
    text: "Gender",
    modal: "genders",
  },
  {
    text: "Age groups",
    modal: "ages",
  },
  {
    text: "Vehicle's brand",
    modal: "carMakes",
  },
  {
    text: "Vehicle's age",
    modal: "carAges",
  },
  {
    text: "Car insurance company",
    modal: "carLiabilityInsuranceAgencies",
  },
  {
    text: "Autocasco insurance company",
    modal: "kaskoInsuranceAgencies",
  },
  {
    text: "Operating system",
    modal: "os",
  },
  {
    text: "Spending amount",
    modal: "spendTotal",
  },
];

const AdForm = ({ getAllFilters, filters, checkReached, reached, createAd, getCurrentAd, currentAd, editAd, setCurrentQuery }) => {
  const adId = window.location.href.split("/")[5];
  const [selectedData, setSelectedData] = useState({
    cities: [],
    genders: [],
    ages: [],
    carMakes: [],
    carAges: [],
    carLiabilityInsuranceAgencies: [],
    kaskoInsuranceAgencies: [],
    os: [],
    spendTotal: [],
  });
  const [openModals, setOpenModals] = useState({
    cities: false,
    genders: false,
    ages: false,
    carMakes: false,
    carAges: false,
    carLiabilityInsuranceAgencies: false,
    kaskoInsuranceAgencies: false,
    os: false,
    spendTotal: false,
  });
  const [phoneNumbers, setPhoneNumbers] = useState([""]);
  const [query, setQuery] = useState();

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    name: "",
    advertiser: "",
    startDate: "",
    endDate: "",
    budget: null,
    impressions: false,
    push: false,
    email: false,
  });

  useEffect(() => {
    if (adId) {
      getCurrentAd({
        _id: adId,
        onSuccess: (res) => {
          // setValue("users", res?.users);
          setPhoneNumbers(res?.users?.map((user) => user?.phoneNumber?.slice(4, user?.phoneNumber?.length)));
          setValue("name", res.name);
          setValue("advertiser", res.advertiser);
          var startDate = new Date(res.startDate);
          var endDate = new Date(res.endDate);
          setValue("startDate", moment(startDate)._d);
          setValue("endDate", moment(endDate)._d);
          setValue("budget", res.budget);
          setValue("impressions", res.impressions);
          setValue("push", res.push);
          setValue("email", res.email);
          var usersQueryObj = JSON?.parse(res?.userFindQuery);
          setQuery(res.userFindQuery);
          setValue("userFindQuery", res?.userFindQuery);
          setSelectedData({
            cities: usersQueryObj?.cities && usersQueryObj?.cities?.$in,
            carMakes: usersQueryObj?.carMakes && usersQueryObj?.carMakes?.$in,
            genders: usersQueryObj?.genders && usersQueryObj?.genders?.$in,
            carLiabilityInsuranceAgencies: usersQueryObj?.carLiabilityInsuranceAgencies && usersQueryObj?.carLiabilityInsuranceAgencies?.$in,
            kaskoInsuranceAgencies: usersQueryObj?.kaskoInsuranceAgencies && usersQueryObj?.kaskoInsuranceAgencies?.$in,
            os: usersQueryObj?.os && usersQueryObj?.os?.$in,
          });
          checkReached({
            query: JSON.parse(res?.userFindQuery),
          });
          localStorage.setItem("adQuery", res?.userFindQuery);
        },
      });
    }
  }, []);

  useEffect(() => {
    getAllFilters();
    checkReached();
  }, []);

  const actionCampaign = (payload) => {
    if (phoneNumbers?.length < 2 && phoneNumbers?.[0]?.length < 1 && !adId) {
      toast.error("You must add at least one phone number!");
      return;
    }

    const promiseArray = phoneNumbers
      .filter((phone) => phone?.length > 5)
      ?.map((phone) => {
        return new Promise((resolve, reject) => {
          resolve(
            window.fetch(`${API_URL}/campaign/findByPhone`, {
              method: "POST",
              headers: Headers.get_auth(),
              body: JSON.stringify({
                phoneNumber: "+359" + phone,
              }),
            })
          );
        });
      });
    Promise.all(promiseArray)
      .then((res) => Promise.all(res.map((r) => r.json())))
      .then((objs) => {
        if (moment(payload.endDate).isAfter(moment(payload.startDate))) {
          const finalPayload = {
            ...payload,
            startDate: moment(payload?.startDate)?.format("YYYY-MM-DD") + "T00:00:00.000Z",
            endDate: moment(payload?.endDate)?.format("YYYY-MM-DD") + "T00:00:00.000Z",
            email: payload?.email ? payload.email : false,
            push: payload?.push ? payload.push : false,
            impressions: payload?.impressions ? payload?.impressions : false,
            users: [...new Set([...objs?.filter((obj) => obj?.payload)?.map((obj) => obj?.payload?._id)])],
            potentialReach: reached?.allUsers,
            potentialReachWithAgreement: reached?.usersWithDirectMarketingAgreement,
            userFindQuery: payload?.userFindQuery || JSON?.stringify(query),
          };
          console.log(finalPayload);
          if (adId) {
            editAd({
              _id: adId,
              ...finalPayload,
              onSuccess: () => {
                toast.success("Campaign edited successfully!");
                history.push("/advertisers");
              },
            });
          } else
            createAd({
              ...finalPayload,
              onSuccess: () => {
                toast.success("Campaign created successfully!");
                history.push("/advertisers");
              },
            });
        } else toast.error("End date must be after start!");
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const mapValuesForQuery = () => {
    const queryPayload = mapValues(selectedData, (value, key) => {
      if (!value?.length) return undefined;
      if (["cities", "genders", "carMakes", "carLiabilityInsuranceAgencies", "kaskoInsuranceAgencies", "os"].includes(key)) {
        return {
          $in: Array.isArray(value) ? value.map((ins) => ins?.value || ins) : value,
        };
      } else if (["ages", "carAges", "spendTotal"].includes(key)) {
        const ranges = value
          .map((val) => [...val.split("-")])
          .sort((a, b) => {
            return b[0] - a[0];
          });
        return isNaN(ranges[0][1]) || !ranges[0][1] //check if it is to infinity
          ? {
              $gte: Number(ranges[ranges.length - 1][0]),
            }
          : {
              $gte: Number(ranges[ranges.length - 1][0]),
              $lte: Number(ranges[0][1]),
            };
      } else return value;
    });
    setQuery(queryPayload);
    localStorage.setItem("adQuery", JSON.stringify(queryPayload));
    checkReached({ query: queryPayload });
  };

  console.log(reached);
  return (
    <div className="main-container" style={{ overflow: "auto" }}>
      <div className="inner-header-container">
        <div className="left-part" style={{ width: "92%" }}>
          <div className="close-icon" onClick={() => history.push(`/advertisers/campaign/${adId}`)} />
          <h2 className="inner-title">{adId ? "EDIT" : "CREATE A"} CAMPAIGN</h2>
        </div>
        <Inputs.Button
          text={adId ? "EDIT" : "CREATE"}
          selected
          style={{ width: "7%" }}
          onClick={handleSubmit((e) => {
            actionCampaign(e);
          })}
        />
      </div>
      <div className="body-container" style={{ height: "30%" }}>
        <h3 style={{ margin: "10px 0px" }}>General information</h3>
        <Inputs.TextInput label={"Campaign name"} compulsory className={errors.name && "failed"} {...register(`name`, { required: true })} />
        <div className="flex-container align-center space-between" style={{ marginTop: "1%", width: "95%" }}>
          <Inputs.TextInput label={"Advertiser"} compulsory style={{ width: "40%" }} className={errors.advertiser && "failed"} {...register(`advertiser`, { required: true })} />
          <Controller
            control={control}
            name={"startDate"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.DatePicker
                label={"Start date"}
                compulsory
                style={{ width: "15%", height: "100%" }}
                value={value}
                onChange={(e) => {
                  setValue("startDate", e);
                }}
                className={error && "failed"}
              />
            )}
            rules={{ required: true }}
          />
          <Controller
            control={control}
            name={"endDate"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.DatePicker
                label={"End date"}
                compulsory
                style={{ width: "15%", height: "100%" }}
                value={value}
                onChange={(e) => {
                  setValue("endDate", e);
                }}
                className={error && "failed"}
              />
            )}
            rules={{ required: true }}
          />
          <Inputs.TextInput label={"Budget"} compulsory style={{ width: "20%" }} suffix={"BGN"} className={errors.budget && "failed"} {...register(`budget`, { required: true })} />
        </div>
      </div>
      <div className="body-container" style={{ height: "20%" }}>
        <h3 style={{ margin: "10px 0px" }}>Campaign type</h3>
        <div className="flex-container full-width align-center">
          {options.map((option, i) => {
            return (
              <Controller
                key={i}
                control={control}
                name={option.value}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Inputs.Switch
                    label={option.label}
                    value={value}
                    labelOn="Yes"
                    labelOff="No"
                    type="row"
                    style={{ marginRight: "4%" }}
                    onChange={(e) => {
                      setValue(option.value, !value);
                      // else setValue(option.value, true);
                    }}
                    className={error && "failed"}
                  />
                )}
              />
            );
          })}
        </div>
      </div>
      <div className="body-container flex-container space-between" style={{ height: "37%", padding: "0px", flexDirection: "column" }}>
        <h3 style={{ padding: "10px", marginTop: "20px" }}>Targets</h3>
        <div className="flex-container full-width wrap" style={{ padding: "15px" }}>
          {modalOptions.map((el, i) => {
            return (
              <div className="input-container" style={{ width: "20%", marginTop: i > 4 && "10px" }} key={i}>
                <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
                  {el.text}
                  <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
                </label>
                <div
                  className="prefix-input-container"
                  style={{ width: "98%", cursor: "pointer" }}
                  onClick={() => {
                    setOpenModals({
                      ...openModals,
                      [el.modal]: true,
                    });
                  }}
                >
                  <input
                    type="text"
                    disabled
                    value={selectedData?.[el.modal]?.length ? selectedData?.[el.modal]?.map((key) => (key?.label ? key?.label + ", " : key + ", ")) : "Target all"}
                    className="inner-input"
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                      backgroundColor: "white",
                    }}
                  />
                  <div className="open-modal-icon" />
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex-container targets align-center">
          <div className="divider vertical" style={{ height: "80%", width: "3px" }} />
          <div className="flex-container align-center" style={{ width: "90%" }}>
            <h3 style={{ marginLeft: "20px" }}>{reached?.allUsers}</h3>
            <p style={{ margin: "0px 20px" }}>TARGETED USERS</p>
            <div className="divider vertical" style={{ height: "40px", backgroundColor: "gray" }} />
            <h3 style={{ margin: "0px 20px", fontSize: "16px" }}>{reached?.usersWithDirectMarketingAgreement}</h3>
            <p>WITH DIRECT MARKETING AGREEMENT</p>
          </div>
          {<Inputs.Button text={"USER DETAILS"} className={"transparent"} disabled={!query} style={{ height: "70%", whiteSpace: "nowrap", marginRight: "1%" }} onClick={() => query && window.open(`/advertisers/reached-users`)} />}
        </div>
      </div>
      <div className="body-container" style={{ height: "25%", overflow: "auto" }}>
        <div>
          <h3 style={{ margin: "10px 0px" }}>Advertisers access</h3>
          <p>Provide access to this campaign for people in your company</p>
        </div>
        <div className="flex-container align-center full-width wrap" style={{ marginTop: "1%", overflowY: "auto" }}>
          <div
            className="plus-icon-container"
            onClick={() => {
              const newPhones = phoneNumbers.slice(0);
              newPhones.push("");
              setPhoneNumbers(newPhones);
            }}
          >
            <div className="plus-icon" />
          </div>
          {phoneNumbers.map((phone, i) => {
            return (
              <Inputs.TextInput
                label={"Phone number"}
                compulsory
                prefix
                key={i}
                suffix={
                  phoneNumbers.length > 1 && (
                    <div
                      className="close-icon"
                      onClick={() => {
                        const newPhones = phoneNumbers.slice(0);
                        newPhones.splice(i, 1);
                        setPhoneNumbers(newPhones);
                      }}
                    />
                  )
                }
                style={{ width: "15%", margin: i > 4 ? "1%" : "0% 1%" }}
                value={phone}
                onChange={(e) => {
                  const newPhones = phoneNumbers.slice(0);
                  newPhones[i] = e.target.value;
                  setPhoneNumbers(newPhones);
                }}
              />
            );
          })}
        </div>
      </div>
      <ModalComponent open={openModals.cities} children={<InnerModal label={"cities"} options={filters?.cities && ["SELECT ALL", ...filters.cities]} handleClose={() => setOpenModals({ ...openModals, cities: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent open={openModals.genders} children={<InnerModal label={"genders"} options={filters.genders && ["SELECT ALL", ...filters.genders]} handleClose={() => setOpenModals({ ...openModals, genders: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent open={openModals.ages} children={<InnerModal label={"ages"} options={filters.ages} handleClose={() => setOpenModals({ ...openModals, ages: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent open={openModals.carMakes} children={<InnerModal label={"carMakes"} options={filters.carMakes && ["SELECT ALL", ...filters.carMakes]} handleClose={() => setOpenModals({ ...openModals, carMakes: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent open={openModals.carAges} children={<InnerModal label={"carAges"} options={filters.carAges} handleClose={() => setOpenModals({ ...openModals, carAges: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent
        open={openModals.carLiabilityInsuranceAgencies}
        children={
          <InnerModal
            label={"carLiabilityInsuranceAgencies"}
            options={filters.insuranceAgencies && ["SELECT ALL", ...filters.insuranceAgencies]}
            handleClose={() =>
              setOpenModals({
                ...openModals,
                carLiabilityInsuranceAgencies: false,
              })
            }
            setSelectedData={setSelectedData}
            selectedData={selectedData}
            mapValuesForQuery={mapValuesForQuery}
          />
        }
      />
      <ModalComponent open={openModals.kaskoInsuranceAgencies} children={<InnerModal label={"kaskoInsuranceAgencies"} options={filters.insuranceAgencies && ["SELECT ALL", ...filters.insuranceAgencies]} handleClose={() => setOpenModals({ ...openModals, kaskoInsuranceAgencies: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent open={openModals.os} children={<InnerModal label={"os"} options={filters.os && ["SELECT ALL", ...filters.os]} handleClose={() => setOpenModals({ ...openModals, os: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
      <ModalComponent open={openModals.spendTotal} children={<InnerModal label={"spendTotal"} options={filters.spent} handleClose={() => setOpenModals({ ...openModals, spendTotal: false })} setSelectedData={setSelectedData} selectedData={selectedData} mapValuesForQuery={mapValuesForQuery} />} />
    </div>
  );
};
const mapStateToProps = (state) => ({
  filters: state.ads.filters,
  reached: state.ads.reached,
  currentAd: state.ads.currentAd,
});
const mapDispatchToProps = (dispatch) => ({
  getAllFilters: (payload) => dispatch(getAllFilters(payload)),
  deleteArticle: (payload) => dispatch(deleteArticle(payload)),
  editArticle: (payload) => dispatch(editArticle(payload)),
  getCurrentArticle: (payload) => dispatch(getCurrentArticle(payload)),
  checkReached: (payload) => dispatch(checkReached(payload)),
  findUserByPhone: (payload) => dispatch(findUserByPhone(payload)),
  createAd: (payload) => dispatch(createAd(payload)),
  getCurrentAd: (payload) => dispatch(getCurrentAd(payload)),
  editAd: (payload) => dispatch(editAd(payload)),
  setCurrentQuery: (payload) => dispatch(setCurrentQuery(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdForm);
