/* eslint-disable */
const User = {
  isAuthenticated: localStorage.getItem("amarantAdsToken") !== null,
  isAdmin: localStorage.getItem("role") === "admin",
  token: localStorage.getItem("amarantAdsToken"),
  phoneNumber: localStorage.getItem("phoneNumber"),
  user: JSON.parse(localStorage.getItem("user")),
  role: localStorage.getItem("role"),
  getToken() {
    return User.token;
  },
  getUser() {
    return User.user;
  },
  getPhoneNumber() {
    return User.phoneNumber;
  },
  getRole() {
    return User.role;
  },

  setUserKey(key, value) {
    return new Promise((resolve, reject) => {
      User.user[key] = value;
      localStorage.setItem("user", JSON.stringify(User.user));
      resolve();
    });
  },
  authenticate(token, user) {
    return new Promise((resolve, reject) => {
      User.isAuthenticated = true;
      user.adminRole === "admin" ? (User.isAdmin = true) : (User.isAdmin = false);
      User.token = token;
      User.user = user;
      User.phoneNumber = user.phoneNumber;
      User.role = user.adminRole;
      localStorage.setItem("amarantAdsToken", token);
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("phoneNumber", user.phoneNumber);
      localStorage.setItem("role", user.adminRole);
      resolve();
    });
  },
  signout(cb) {
    return new Promise((resolve, reject) => {
      User.isAuthenticated = false;
      User.isAdmin = false;
      User.token = null;
      User.user = null;
      User.phoneNumber = null;
      User.role = null;
      localStorage.removeItem("amarantAdsToken");
      localStorage.removeItem("user");
      localStorage.removeItem("phoneNumber");
      localStorage.removeItem("role");
      resolve();
    });
  },
};

export { User };
