import React from "react";
import { useSelector } from "react-redux";
import { Player } from "@lottiefiles/react-lottie-player";
import Popup from "reactjs-popup";
import loadingAnimation from "../../assets/animations/loadingAnimation.json";
import "./styles.scss";

const LoaderGlobal = () => {
  const { loading } = useSelector(({ starter }) => starter);
  return (
    <Popup modal className="modal-loading-container" open={loading} closeOnDocumentClick={false} closeOnEscape={false}>
      <div className="modal-loading-text">Please wait</div>
      <div className="modal-loading-animation">
        <Player src={loadingAnimation} className="player" autoplay loop />
      </div>
    </Popup>
  );
};

export default LoaderGlobal;
