/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import Scrollbars from "react-custom-scrollbars";
import Statistics from "../../components/Statistics";

const headerElements = [
  {
    name: "Date",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Taxes",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "After taxes",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Delivery price",
    query: { filter: "Role", options: [] },
  },
  {
    name: "After delivery price",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Paid amount",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Product",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Agency",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Total installments",
    query: { filter: "installments", options: [] },
  },
  {
    name: "User",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Email",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Additional Information",
    query: { filter: "Role", options: [] },
  },
];

const GeneratedIncomeInstallments = ({
  docs,
  getGeneratedIncomeInstallements,
  loading,
  setLoading,
}) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && docs.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getGeneratedIncomeInstallements({
        pageNumber: curPage,
        pageSize: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getGeneratedIncomeInstallements({
      page: 1,
      pageSize: 20,
      onSuccess: () => setLoading(false),
    });
  }, []);

  return (
    <Scrollbars
      style={{ height: "93%", width: window.innerWidth < 1200 ? "180%" : "100%", marginTop: "2%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
    >
      <div className="table-header" style={{ width: "150%" }}>
        {headerElements?.map((header, i) => {
          return (
            <div
              className="header-cont"
              key={i}
              style={{
                width: i === 6 ? "15%" : i === 8 ? "14%" : "9%",
              }}
            >
              <div className="header">{header.name}</div>
            </div>
          );
        })}
      </div>
      {docs?.data?.map((doc, i) => {
        return (
          <div className="table-body-row" key={i} style={{ width: "150%" }}>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {moment(doc?.createdAt).format("DD.MM.YYYY HH:mm")}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.stripeFee + " BGN" || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.netAfterStripeFee + " BGN" || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.deliveryPrice + " BGN" || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.netAfterDelivery + " BGN" || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.amount + " BGN" || "---"}
            </div>
            <div
              className="row-data"
              style={{
                width: "15%",
                wordBreak: "break-word",
                fontSize: "13.5px",
              }}
            >
              {doc?.paymentFor || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.insuranceAgency?.name || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.totalInstallments || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.fullName || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "14%", fontSize: "13.5px" }}
            >
              {doc?.email || "---"}
            </div>
            <div
              className="row-data"
              style={{ width: "9%", fontSize: "13.5px" }}
            >
              {doc?.phoneNumber || "---"}
            </div>
            <div
              className="row-data"
              style={{
                width: "9%",
                fontSize: "13.5px",
                wordBreak: "break-word",
              }}
            >
              {doc?.description || "---"}
            </div>
          </div>
        );
      })}
      {innerLoading && (
        <div className="inner-loader-container">
          <Statistics.LoaderInline center />
        </div>
      )}
    </Scrollbars>
  );
};

export default GeneratedIncomeInstallments;
