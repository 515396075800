const servers = {
  DEV: "https://amarant-dev2.herokuapp.com",
  PROD: "https://amarant.herokuapp.com",
  BOS_DEV: "https://amarant-bos-dev.herokuapp.com",
  BOS_PROD: "https://bos-backend.fidweb.net",
  mexus: "https://tests.mexus.org",
};

export const API_URL = process.env.REACT_APP_API_URL || servers.PROD;
export const API_BOS_URL = process.env.REACT_APP_API_BOS_URL || servers.BOS_PROD;
export const MAPBOX_API_KEY = "pk.eyJ1IjoiaW9ua28iLCJhIjoiY2wwcnQzd205MDUxZDNqczBtZ2YxeXdhNSJ9.obIklcld90kwyuwcNNSWjA";

export const s3Credentials = {
  bucketName: "amarant-admin",
  dirName: "amarant-articles",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5HL7P5SYC",
  secretAccessKey: "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",
};
export const s3CredentialsTasks = {
  bucketName: "amarant-admin",
  dirName: "amarant-tasks",
  region: "eu-central-1",
  accessKeyId: "AKIA5L7PZ7W5HL7P5SYC",
  secretAccessKey: "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",
};
