/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { discountsStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getDiscountsEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.GET_DISCOUNTS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount/list`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: discountsStats.GET_DISCOUNTS_SUCCESS,
              payload: response.payload,
            });
            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getDiscountsUsedEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.GET_DISCOUNTS_USED),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount/listUsed`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: discountsStats.GET_DISCOUNTS_USED_SUCCESS,
              payload: response.payload,
            });
            if (payload?.onSuccess) payload.onSuccess();
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCurrentDiscountEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.GET_CURRENT_DISCOUNT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount/${payload._id}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response?.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const createDiscountEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.CREATE_DISCOUNT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const editUpcomingDiscountEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.EDIT_UPCOMING_DISCOUNT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const deleteUpcomingDiscountEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.DELETE_UPCOMING_DISCOUNT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount`,
        method: "DELETE",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getInsuranceAgenciesEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.GET_INSURANCE_AGENCIES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/insuranceAgency`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const deactivateActiveDiscountEpic = (action$) =>
  action$.pipe(
    ofType(discountsStats.DEACTIVATE_ACTIVE_DISCOUNT),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/discount/cancel`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
