/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  getGeneratedIncomeOnline,
  getGeneratedIncomeInstallements,
  getGeneratedIncomePolicies,
  getPotentialIncome,
} from "../../actions";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import "./styles.scss";
import GeneratedIncome from "./GeneratedIncome";
import GeneratedIncomeInstallments from "./GeneratedIncomeInstallements";
import GeneratedIncomePolicies from "./GeneratedIncomePolicies";
import PotentialIncome from "./PotentialIncome";
import { exportAppStatistics } from "../../utilites/HelperFunctions";
import Statistics from "../../components/Statistics";

const Finances = ({
  getGeneratedIncomeOnline,
  getGeneratedIncomeInstallements,
  getGeneratedIncomePolicies,
  getPotentialIncome,
  incomeInstallments,
  incomeOnline,
  incomePolicies,
  incomePotential,
}) => {
  const [selectedTab, setSelectedTab] = useState("incomeOnline");
  const [loading, setLoading] = useState(true);

  return (
    <div className="main-container">
      <div
        className="body-container"
        style={{ height: "100%", marginTop: "0%",overflowX:window.innerWidth < 1200 && "auto" }}
      >
        <div className="flex-container full-width space-between">
          <div className="flex-container" style={{ width: "85%" }}>
            <Inputs.Button
              text="GENERATED INCOME - ONLINE"
              selected={selectedTab === "incomeOnline"}
              onClick={() => {
                setSelectedTab("incomeOnline");
                setLoading(true);
              }}
            />
            <Inputs.Button
              text="GENERATED INCOME - INSTALLMENTS"
              selected={selectedTab === "incomeDeliveryInstallments"}
              style={{ marginLeft: "2%" }}
              onClick={() => {
                setSelectedTab("incomeDeliveryInstallments");
                setLoading(true);
              }}
            />
            <Inputs.Button
              text="GENERATED INCOME - POLICIES"
              selected={selectedTab === "incomeDeliveryPolicies"}
              style={{ marginLeft: "2%" }}
              onClick={() => {
                setSelectedTab("incomeDeliveryPolicies");
                setLoading(true);
              }}
            />
            <Inputs.Button
              text="POTENTIAL INCOME"
              selected={selectedTab === "potential"}
              style={{ marginLeft: "2%" }}
              onClick={() => setSelectedTab("potential")}
            />
          </div>
          <div
            className="export-icon"
            onClick={() => {
              setLoading(true);
              exportAppStatistics(selectedTab, setLoading);
            }}
          />
        </div>
        {selectedTab === "incomeOnline" ? (
          <GeneratedIncome
            docs={incomeOnline}
            getGeneratedIncomeOnline={getGeneratedIncomeOnline}
            loading={loading}
            setLoading={setLoading}
          />
        ) : selectedTab === "incomeDeliveryInstallments" ? (
          <GeneratedIncomeInstallments
            docs={incomeInstallments}
            getGeneratedIncomeInstallements={getGeneratedIncomeInstallements}
            loading={loading}
            setLoading={setLoading}
          />
        ) : selectedTab === "incomeDeliveryPolicies" ? (
          <GeneratedIncomePolicies
            docs={incomePolicies}
            getGeneratedIncomePolicies={getGeneratedIncomePolicies}
            loading={loading}
            setLoading={setLoading}
          />
        ) : (
          <PotentialIncome
            docs={incomePotential}
            getPotentialIncome={getPotentialIncome}
          />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  incomeOnline: state.finances.incomeOnline,
  incomeInstallments: state.finances.incomeInstallments,
  incomePolicies: state.finances.incomePolicies,
  incomePotential: state.finances.incomePotential,
});
const mapDispatchToProps = (dispatch) => ({
  getGeneratedIncomeOnline: (payload) =>
    dispatch(getGeneratedIncomeOnline(payload)),
  getGeneratedIncomeInstallements: (payload) =>
    dispatch(getGeneratedIncomeInstallements(payload)),
  getGeneratedIncomePolicies: (payload) =>
    dispatch(getGeneratedIncomePolicies(payload)),
  getPotentialIncome: (payload) => dispatch(getPotentialIncome(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Finances);
