/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Inputs from "../../../../components/Inputs";
import "../../styles.scss";
import AddUserModal from "./AddUserModal";
import ModalComponent from "../../../../components/ModalComponent/ModalComponent";
import Scrollbars from "react-custom-scrollbars";
import { parseXLSX } from "../../../../utilites";

const attachOptions = [
  {
    label: "Upload file",
    value: "file",
  },
  {
    label: "Add manually",
    value: "manually",
  },
];

const headerElements = [
  {
    name: "Names",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone number",
    query: { filter: "Time", options: [] },
  },
];

const AttachToUsers = ({ data, setData, disabled, removedData, setRemovedData }) => {
  const [openModal, setOpenModal] = useState(false);
  const uploadRef = useRef();
  const [file, setFile] = useState();

  return (
    <div className="body-container" style={{ height: "unset", marginTop: "1%" }}>
      <h2 className="inner-title" style={{ margin: "1% 0%" }}>
        Attach to users (optional)
      </h2>
      <div className="flex-container full-width align-center">
        <div style={{ width: "22%" }}>
          <Inputs.RadioButtons
            options={attachOptions}
            value={data.type}
            disabled={disabled}
            label="Add users"
            onChange={(opt) => {
              setData({ ...data, type: opt });
            }}
          />
        </div>
        {data.type === "manually" && !disabled ? (
          <>
            <Inputs.Button
              className={"dotted"}
              text={"ADD USERS"}
              icon={<div className="plus-icon black" />}
              disabled={disabled}
              style={{ width: "15%", padding: "5px 15px", justifyContent: "space-between" }}
              onClick={() => {
                setOpenModal(true);
                window.scrollTo({ top: 0 });
              }}
            />
          </>
        ) : (
          data.type === "file" &&
          !disabled && (
            <>
              <Inputs.FileUpload
                style={{ width: "30%", margin: "0px 10px" }}
                value={file}
                clear={() => {
                  setFile(null);
                  setData({ ...data, users: [] });
                }}
                onChange={(e) => {
                  setFile(e.target.files[0].name);
                  parseXLSX(e.target.files[0], (res) => {
                    const newUsers = data.users.slice(0);
                    res.filter((user) => user?.fullName).map((user) => newUsers.push({ fullName: user?.fullName, phoneNumber: user?.phoneNumber[0] === "+" ? user?.phoneNumber : `+${user?.phoneNumber}` }));
                    setData({ ...data, users: newUsers });
                  });
                }}
              />
            </>
          )
        )}
      </div>
      {data?.users?.length ? (
        <Scrollbars style={{ height: "200px", width: "100%" }} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
          <div className="table-header">
            {headerElements?.map((header, i) => {
              return (
                <div
                  className="header-cont"
                  key={i}
                  style={{
                    width: "12%",
                  }}
                >
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {data?.users?.map((user, i) => {
            return (
              <div className="table-body-row" key={i}>
                <div className="row-data" style={{ width: "12%" }}>
                  {user?.fullName || "---"}
                </div>
                <div className="row-data flex" style={{ width: "86%", justifyContent: "space-between" }}>
                  <p>{user?.phoneNumber || "---"}</p>
                  {!disabled && (
                    <div
                      className="delete-icon"
                      onClick={() => {
                        const newUsers = data?.users?.slice(0);
                        newUsers.splice(i, 1);
                        if (removedData) setRemovedData([...removedData, user]);
                        setData({ ...data, users: newUsers });
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </Scrollbars>
      ) : null}
      <ModalComponent
        open={openModal}
        children={
          <AddUserModal
            handleClose={() => setOpenModal(false)}
            setData={setData}
            handleUpdate={(user) => {
              const newUsers = data.users.slice(0);
              newUsers.push({ fullName: user.name, phoneNumber: "+359" + user.phoneNumber });
              setData({ ...data, users: newUsers });
            }}
          />
        }
      />
      <input
        ref={uploadRef}
        style={{ display: "none" }}
        disabled={disabled}
        type="file"
        onChange={(e) => {
          console.log(e.target.files);
        }}
      />
    </div>
  );
};

export default AttachToUsers;
