export const StatisticsStats = {
  GET_STATISTICS_SUCCESS: "statistics/GET_STATISTICS_SUCCESS",
  GET_STATISTICS_SUCCESS_MERGE: "statistics/GET_STATISTICS_SUCCESS_MERGE",
  GET_ACTIVE_USERS: "statistics/GET_ACTIVE_USERS",
  GET_TOTAL_APP_DOWNLOADS: "statistics/GET_TOTAL_APP_DOWNLOADS",
  GET_INCOME_REVENUE: "statistics/GET_INCOME_REVENUE",
  GET_APP_SESSIONS: "statistics/GET_APP_SESSIONS",
  GET_REGISTERED_USERS: "statistics/GET_REGISTERED_USERS",
  GET_INNER_VEHICLES: "statistics/GET_INNER_VEHICLES",
  GET_INNER_LICENSES: "statistics/GET_INNER_LICENSES",
  GET_VEHICLES: "statistics/GET_VEHICLES",
  GET_APP_GROWTH: "statistics/GET_APP_GROWTH",
  GET_DOWNLOADS_BY_DAY: "statistics/GET_DOWNLOADS_BY_DAY",
  GET_DOWNLOADS_BY_DAY_SUCCESS: "statistics/GET_DOWNLOADS_BY_DAY_SUCCESS",
  GET_REVIEWS_BY_OS: "statistics/GET_REVIEWS_BY_OS",
  GET_REVIEWS_BY_OS_SUCCESS: "statistics/GET_REVIEWS_BY_OS_SUCCESS",
  GET_RATINGS: "statistics/GET_RATINGS",
  GET_ACTIVE_SUBS: "statistics/GET_ACTIVE_SUBS",
  GET_ACTIVE_SUBS_SUCCESS: "statistics/GET_ACTIVE_SUBS_SUCCESS",
  GET_NEW_SUBS: "statistics/GET_NEW_SUBS",
  GET_NEW_SUBS_SUCCESS: "statistics/GET_NEW_SUBS_SUCCESS",
};

export const getStatisticsSuccess = (payload) => ({
  type: StatisticsStats.GET_STATISTICS_SUCCESS,
  payload,
});
export const getActiveUsers = (payload) => ({
  type: StatisticsStats.GET_ACTIVE_USERS,
  payload,
});
export const getTotalAppDownloads = (payload) => ({
  type: StatisticsStats.GET_TOTAL_APP_DOWNLOADS,
  payload,
});
export const getIncomeRevenue = (payload) => ({
  type: StatisticsStats.GET_INCOME_REVENUE,
  payload,
});
export const getAppSessions = (payload) => ({
  type: StatisticsStats.GET_APP_SESSIONS,
  payload,
});
export const getRegisteredUsers = (payload) => ({
  type: StatisticsStats.GET_REGISTERED_USERS,
  payload,
});
export const getInnerVehicles = (payload) => ({
  type: StatisticsStats.GET_INNER_VEHICLES,
  payload,
});
export const getInnerLicenses = (payload) => ({
  type: StatisticsStats.GET_INNER_LICENSES,
  payload,
});
export const getVehicles = (payload) => ({
  type: StatisticsStats.GET_VEHICLES,
  payload,
});
export const getAppGrowth = (payload) => ({
  type: StatisticsStats.GET_APP_GROWTH,
  payload,
});
export const getDownloadsByDay = (payload) => ({
  type: StatisticsStats.GET_DOWNLOADS_BY_DAY,
  payload,
});
export const getReviewsByOs = (payload) => ({
  type: StatisticsStats.GET_REVIEWS_BY_OS,
  payload,
});
export const getRatings = (payload) => ({
  type: StatisticsStats.GET_RATINGS,
  payload,
});
export const getActiveSubs = (payload) => ({
  type: StatisticsStats.GET_ACTIVE_SUBS,
  payload,
});
export const getNewSubs = (payload) => ({
  type: StatisticsStats.GET_NEW_SUBS,
  payload,
});