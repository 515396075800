/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { AppStats } from "../actions";
import { API_BOS_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const getOrderedPoliciesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/orderedPolicies`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getAddedPoliciesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/addedPolicies`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getOrderedInstallementsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/orderedInstallments`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getPaidFinesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/paidFines`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getVignettesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/orderedVignettes`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getParkingEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/parking`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getClaimsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/claims`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getManualExpensesEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/manualExpenses`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getManualEventsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/manualEvents`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getGeoWashEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/geoWash`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );

export const getDashCamEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_STATS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/dashcam`,
        //  url: `${API_BOS_URL}/stats/dashcam`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_STATS_SUCCESS,
              payload: response.payload,
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );

export const getNewPoliciesInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_NEW_POLICIES),
    mergeMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/addedPolicies`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        mergeMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "newPolicies",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getAddedPoliciesInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_ADDED_POLICIES),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/orderedPolicies`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "orderedPolicies",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getOrderedInstallementsInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_INSTALLMENTS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/orderedInstallments`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "installments",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getPaidFinesInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_PAID_FINES),
    mergeMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/paidFines`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        mergeMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "paidFines",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getVignettesInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_VIGNETTES),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/orderedVignettes`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "vignettes",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getParkingInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_PARKING),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/parking`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "parking",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );

export const getClaimsInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_CLAIMS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/claims`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "claims",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getCostsInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_COSTS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/manualExpenses`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "costs",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getEventsInfoEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_EVENTS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/manualEvents`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "events",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getGeowashOrdersEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_GEOWASH_ORDERS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/geoWash`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "geoWash",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
export const getDashCamStatsEpic = (action$) =>
  action$.pipe(
    ofType(AppStats.GET_DASHCAM),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/admin/dashcam`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            obs.next({
              type: AppStats.GET_INFO_SUCCESS,
              payload: response.payload,
              key: "dashcam",
            });
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            toast.error("Възникна грешка при обработката на информацията!");
            obs.complete();
          })
        )
      )
    )
  );
