/* eslint-disable */
import React, { useState, useEffect } from "react";
import Inputs from "../../../components/Inputs";
import { getDiscountsUsed, getDiscounts, createDiscount, getInsuranceAgencies } from "../../../actions";
import { connect } from "react-redux";
import "../styles.scss";
import { useForm } from "react-hook-form";
import GeneralInformation from "./Fields/GeneralInformation";
import DiscountInformation from "./Fields/DiscountInformation";
import AttachToUsers from "./Fields/AttachToUsers";
import { toast } from "react-toastify";
import { history } from "../../../config/stores";

const insurancesOptions = [
  {
    label: "General liability",
    value: "go",
    companies: [
      /*all*/
    ],
  },

  {
    label: "Property insurance",
    value: "property",
    companies: [{ insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" }],
  },

  {
    label: "Autocasco",
    value: "kasko",
    companies: [
      /*all*/
    ],
  },

  {
    label: "Home insurance",
    value: "home",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d61", insuranceAgencyName: "Уника" },
      { insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" },
    ],
  },

  {
    label: "BG assist insurance",
    value: "bgAssist",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d5c", insuranceAgencyName: "Дженерали" },
      { insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" },
      { insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" },
    ],
  },
  {
    label: "EU assist insurance",
    value: "euAssist",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d5c", insuranceAgencyName: "Дженерали" },
      { insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" },
    ],
  },
  {
    label: "EU medicine insurance",
    value: "euMedicine",
    companies: [{ insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" }],
  },
  {
    label: "Car accident insurance",
    value: "carAccident",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" },
      { insuranceAgency: "5f106cc4be38834b48208d5c", insuranceAgencyName: "Дженерали" },
    ],
  },
  { label: "Аccident insurance", value: "accident", companies: [{ insuranceAgency: "5f106cc4be38834b48208d5d", insuranceAgencyName: "ДЗИ" }] },
  {
    label: "Trip cancelation insurance",
    value: "cancelTravel",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d62", insuranceAgencyName: "Групама" },
      { insuranceAgency: "5f106cc4be38834b48208d5e", insuranceAgencyName: "Евроинс" },
    ],
  },
  {
    label: "Travel insurance",
    value: "travel",
    companies: [
      { insuranceAgency: "5f106cc4be38834b48208d62", insuranceAgencyName: "Групама" },
      { insuranceAgency: "5f106cc4be38834b48208d5e", insuranceAgencyName: "Евроинс" },
      { insuranceAgency: "5f106cc4be38834b48208d61", insuranceAgencyName: "Уника" },
    ],
  },
  {
    label: "Medical insurance for foreigners",
    value: "medicineForeign",
    companies: [{ insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" }],
  },
  { label: "Firearm", value: "firearm", companies: [{ insuranceAgency: "5f106cc4be38834b48208d59", insuranceAgencyName: "БулИнс" }] },
];

const AddDiscount = ({ createDiscount, getInsuranceAgencies }) => {
  const [discountInfo, setDiscountInfo] = useState({
    type: "",
    insurances: [],
  });

  const [assignUsers, setAssignUsers] = useState({
    type: "",
    users: [],
  });

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      startDate: "",
      endDate: null,
      discountCondition: "",
      discountCode: "",
      discountInPercent: null,
      genericDiscount: [],
      policyTypes: [],
      discount: null,
      customDiscounts: [],
      comment: "",
      assignUsers: [],
    },
  });
  useEffect(() => {
    getInsuranceAgencies({
      onSuccess: (res) => {
        res.map((agency) => {
          insurancesOptions[0].companies.push({
            insuranceAgency: agency._id,
            insuranceAgencyName: agency?.name,
            discount: null,
          });
          insurancesOptions[2].companies.push({
            insuranceAgency: agency._id,
            insuranceAgencyName: agency?.name,
            discount: null,
          });
        });
      },
    });
  }, []);

  useEffect(() => {
    if (errors?.discountCode) toast.error("You have entered invalid code");
    if (errors?.discountInPercent) toast.error("You didn't choose the type of the discount");
  }, [errors]);

  const handleCreate = (e) => {
    let payload = {};
    if (e.genericDiscount) {
      payload = { ...e, policyTypes: [] };
      if (assignUsers.users.length) payload.assignUsers = assignUsers.users;
    } else {
      payload = {
        name: e.name,
        startDate: e.startDate,
        endDate: e.endDate,
        discountCondition: e.discountCondition,
        discountCode: e.discountCode,
        discountInPercent: e.discountInPercent || false,
        genericDiscount: e.genericDiscount,
        policyTypes: e.customDiscounts.map((ins) => ins.insuranceType),
        customDiscounts: e.customDiscounts.map((ins) => {
          return {
            policyType: ins.insuranceType,
            discount: ins.discount,
            genericDiscount: ins.general ? true : false,
            customDiscountsPerInsuranceAgency: ins.companies.filter((comp) => comp.discount),
          };
        }),
        comment: e.comment,
      };
    }

    if (assignUsers.users.length) payload.assignUsers = assignUsers.users;

    createDiscount({
      ...payload,
      onSuccess: (res) => {
        document.getElementsByTagName("body")[0].style.overflow = "hidden hidden";
        toast.success("Discount code added successfully!");
        history.push("/discounts");
      },
    });
  };

  return (
    <div className="main-container" style={{ overflow: "auto" }}>
      <div className="inner-header-container flex-container space-between">
        <div className="left-part">
          <div className="close-icon" onClick={() => history.push("/discounts")} />
          <h2 className="inner-title">CREATE DISCOUNT</h2>
        </div>

        <Inputs.Button text={"ADD"} selected style={{ width: "7%", marginRight: "1%" }} onClick={handleSubmit((e) => handleCreate(e))} />
      </div>
      <GeneralInformation control={control} setValue={setValue} register={register} errors={errors} />
      <DiscountInformation control={control} setValue={setValue} register={register} errors={errors} watch={watch} setDiscountInfo={setDiscountInfo} discountInfo={discountInfo} insurancesOptions={insurancesOptions} />
      <AttachToUsers control={control} setValue={setValue} register={register} errors={errors} watch={watch} setData={setAssignUsers} data={assignUsers} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  discounts: state.discounts.discounts,
  discountsUsed: state.discounts.discountsUsed,
});
const mapDispatchToProps = (dispatch) => ({
  getDiscounts: (payload) => dispatch(getDiscounts(payload)),
  getDiscountsUsed: (payload) => dispatch(getDiscountsUsed(payload)),
  createDiscount: (payload) => dispatch(createDiscount(payload)),
  getInsuranceAgencies: (payload) => dispatch(getInsuranceAgencies(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddDiscount);
