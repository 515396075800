/* eslint-disable */
import { authTypes } from "../actions";

const initialState = {
  token: null,
  user: {},
  phoneNumber: "",
};
const auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case authTypes.LOG_IN_SUCCESS:
      return { ...state, user: payload, token: payload.token };
    case authTypes.LOGIN_SUCCESS_LOCAL_STORAGE:
      return { ...state, user: payload, token: payload.token };
    case authTypes.LOG_OUT_SUCCESS:
      return { ...state, user: {}, token: null };
    default:
      return state;
  }
};

export default auth;
