import React from "react";
import "./styles.scss";

const Switch = ({
  value,
  className,
  label,
  labelOn,
  labelOff,
  onChange,
  style,
  compulsory,
}) => {
  return (
    <div
      className={`flex-container align-center`}
      style={style}
    >
      <label
        style={{
          display: "flex",
          fontSize: "0.8rem",
          whiteSpace:"nowrap",
          color: className && "red",
        }}
      >
        {label}{" "}
        {compulsory && (
          <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
        )}
      </label>
      <div
        className={`inputs-switch-container ${value ? "switch-on" : ""}`}
        onClick={onChange}
      >
        <div className={"inputs-switch-inner-container"}>
          <span className={`label-on ${value ? "fade-in" : "fade-out"}`}>
            {labelOn || "Yes"}
          </span>
          <div className="inputs-switch-circle" />
          <span className={`label-off ${!value ? "fade-in" : "fade-out"}`}>
            {labelOff || "No"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Switch;
