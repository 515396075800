/* eslint-disable */
import React, { useEffect } from "react";
import { history } from "./config/stores";
import { Router } from "react-router-dom";
import { User } from "./utilites/User";
import Routes from "./components/routes/Routes";
import { connectTasksSocket, controller, loginSuccessFromLocalStorage, replaceSocketField, statsSocketConnect, statsSocketEmit, tasksSocketConnect } from "./actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { connect, useDispatch } from "react-redux";
import { API_URL } from "./config/settings";
import io from "socket.io-client";
import 'react-quill/dist/quill.snow.css'

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    User.isAuthenticated ? loginSuccessFromLocalStorage(User.getUser(), User.getToken()) : history.push("/login");

    if (User.isAuthenticated) {
      const query = `type=stats&token=${User.getToken()}`;
      dispatch(statsSocketConnect({ query }));
    }
  }, [User.isAuthenticated, dispatch]);

  useEffect(() => {
    if (User.isAuthenticated) {
      const socket = io(API_URL, { query: `type=task&token=${User.getToken()}` });
      socket.on("connect", () => dispatch(replaceSocketField(socket)));
      socket.on("task-message", ({ action, payload }) => {
        console.log("Socket in: ", { action, payload });
        if (controller[action]) {
          controller[action](payload);
        }
      });
      socket.on("error", (text) => {
        console.log(text)
      });
      return () => socket.disconnect();
    }
  }, [User.isAuthenticated]);

  return (
    <Router history={history}>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Routes />
    </Router>
  );
};

const mapStateToProps = (state) => ({
  token: state.login.token,
});
const mapDispatchToProps = (dispatch) => ({
  checkUser: (payload) => dispatch(checkUser(payload)),
  setUserSupportId: (payload) => dispatch(setUserSupportId(payload)),
  loginSuccessFromLocalStorage: (payload) => dispatch(loginSuccessFromLocalStorage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
