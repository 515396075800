/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getAppGrowth, getDownloadsByDay } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import moment from "moment";

const AppGrowth = ({ getStatisticsSuccess, getAppGrowth, appGrowth, getDownloadsByDay, downloadsByDay }) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: moment().startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
    endDate: moment().endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
  });

  const [filtered, setFiltered] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  useEffect(() => {
    getStatisticsSuccess({ type: "appGrowth", loading: true });
    getAppGrowth({
      startDate: moment().startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
      endDate: moment().endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
    getDownloadsByDay({
      startDate: moment().startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
      endDate: moment().endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setInnerLoading(true);
      getDownloadsByDay({
        ...selPeriod,
      });
      getAppGrowth({
        ...selPeriod,
        onSuccess: () => setInnerLoading(false),
      });
    }
  }, [selPeriod]);

  return (
    <div className="statistics-sessions-container col">
      {innerLoading ? (
        <Statistics.LoaderInline />
      ) : (
        <>
          <div style={{ display: "flex", width: "100%" }}>
            <h2 className="main-header">
              <b>app Growth</b>
            </h2>
            <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} growth startField={"startDate"} endField={"endDate"} />
          </div>
          <div className="border-container background" style={{ marginRight: "1%" }}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div className="indicator-circle dark-gray" />
              <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "17px" }}>
                <b style={{ fontSize: "19px" }}>
                  {Object.values(downloadsByDay)
                    ?.filter((val) => val !== false && val !== "downloadsByDay")
                    ?.reduce((a, b) => a + b, 0)}
                </b>{" "}
                APP DOWNLOADS TOTAL
              </h2>
            </div>
          </div>
          <div className="statistics-sessions-content" style={{ overflowY: "scroll", overflowX: "hidden", padding: "5px" }}>
            <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
              {downloadsByDay.loading ? (
                <Statistics.LoaderInline />
              ) : (
                <Statistics.BarChart
                  color={"#0077FF"}
                  data={
                    downloadsByDay &&
                    Object?.fromEntries(
                      Object?.entries(downloadsByDay)
                        ?.filter(([key, value]) => key !== "loading" && key !== "type")
                        ?.map(([key, value]) => {
                          return [`${Number(key.split(".")[0])}`, value];
                        })
                    )
                  }
                />
              )}
            </div>

            <div className="border-container background" style={{ marginRight: "1%" }}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div className="indicator-circle dark-gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "17px" }}>
                  <b style={{ fontSize: "19px" }}>{appGrowth?.vehiclesAdded && Object.values(appGrowth?.userSignup)?.reduce((a, b) => a + b, 0)}</b> USER SIGNUPS
                </h2>
              </div>
            </div>
            <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
              {appGrowth.loading ? (
                <Statistics.LoaderInline />
              ) : (
                <Statistics.BarChart
                  color={"#0077FF"}
                  data={
                    appGrowth?.userSignup &&
                    Object?.fromEntries(
                      Object?.entries(appGrowth?.userSignup)
                        ?.filter(([key, value]) => key !== "loading" && key !== "type")
                        ?.map(([key, value]) => {
                          return [`${Number(key.split(".")[0])}`, value];
                        })
                    )
                  }
                />
              )}
            </div>
            <div className="border-container background" style={{ marginRight: "1%" }}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div className="indicator-circle dark-gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "17px" }}>
                  <b style={{ fontSize: "19px" }}>{appGrowth?.vehiclesAdded && Object.values(appGrowth?.vehiclesAdded)?.reduce((a, b) => a + b, 0)}</b> NEWLY ADDED VEHICLES
                </h2>
              </div>
            </div>
            <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }}>
              {appGrowth.loading ? (
                <Statistics.LoaderInline />
              ) : (
                <Statistics.BarChart
                  color={"#0077FF"}
                  data={
                    appGrowth?.vehiclesAdded &&
                    Object?.fromEntries(
                      Object?.entries(appGrowth?.vehiclesAdded)
                        ?.filter(([key, value]) => key !== "loading" && key !== "type")
                        ?.map(([key, value]) => {
                          return [`${Number(key.split(".")[0])}`, value];
                        })
                    )
                  }
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  appGrowth: state.dashboard.appGrowth,
  downloadsByDay: state.dashboard.downloadsByDay,
});
const mapDispatchToProps = (dispatch) => ({
  getAppGrowth: (payload) => dispatch(getAppGrowth(payload)),
  getDownloadsByDay: (payload) => dispatch(getDownloadsByDay(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppGrowth);
