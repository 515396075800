import React, { useState } from "react";
import Inputs from "../../../components/Inputs";
import { emit } from "../../../utilites/helpers";
import { useSelector } from "react-redux";

const EditModal = ({ handleClose, id }) => {
  const [title, setTitle] = useState("");
  const socket = useSelector(({ tasks }) => tasks?.taskSocket) || null;
  return (
    <div className="delete-modal-container p-8" style={{ width: "40%" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <h1 className="text-base font-bold">Редактирай име на колона</h1>
        <Inputs.TextInput
          label="Заглавие"
          style={{ width: "100%", margin: "20px 0px" }}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <div className="flex-container full-width">
          <Inputs.Button text="Откажи" style={{ width: "49%" }} onClick={handleClose} />
          <Inputs.Button
            text="Запази"
            disabled={title.length < 2}
            selected
            style={{ width: "49%", marginLeft: "1%" }}
            // className={"selected w-1/2 h-9 "}
            onClick={() => {
              emit(socket, { action: "task/updateCategory", payload: { categoryId: id, title: title } });
              emit(socket, { action: "task/getTasks", payload: { categoryId: id } });
              setTitle("");
              handleClose();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditModal;
