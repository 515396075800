/* eslint-disable */
import React, { useRef } from "react";
import "./styles.scss";
const FileUpload = ({ label, compulsory, style, inputStyle, className, setValue, innerWidth, files, icon, clear, ...props }) => {
  const uploadRef = useRef();

  return (
    <div className="input-container" style={style}>
      {label && (
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
        </label>
      )}
      {icon ? (
        <>
          <input ref={uploadRef} multiple style={{ display: "none" }} type="file" {...props} />
          <img
            className="plus-icon"
            src={require("../../../assets/icons/light-plus-icon.svg")}
            onClick={() => {
              if (uploadRef) {
                if (!props.value) uploadRef.current.click();
              }
            }}
          />
        </>
      ) : (
        <div
          className={`prefix-input-container ${!props.value && "dotted"} ${className}`}
          style={{
            width: innerWidth ? "98%" : "50%",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => {
            if (uploadRef) {
              if (!props.value) uploadRef.current.click();
            }
          }}
        >
          {!props.value ? (
            <>
              {" "}
              <>
                {" "}
                <h3 style={{ marginLeft: "4%" }}>UPLOAD</h3>
                <div className="upload-icon" />
              </>
              <input ref={uploadRef} style={{ display: "none" }} type="file" {...props} />
            </>
          ) : (
            <>
              <h3 style={{ marginLeft: "4%" }}>{props.value.slice(0, 25)}</h3>
              <div className="close-icon" style={{ marginRight: "4%" }} onClick={clear} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
