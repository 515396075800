/* eslint-disable */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getStats } from "../../actions";
import "./styles.scss";
import moment from "moment";
import Statistics from "../../components/Statistics";
import AppGrids from "../../components/AppGrids";
import { options } from "../../config/constants";

const cardTypes = [
  {
    header: "New insurance policies",
    firstLine: "purchased policies",
    secondLine: "Users with purchased policies",
    firstField: "purchasedPolicies",
    secondField: "purchasedPoliciesUsers",
    tab: "newPolicies",
  },
  {
    header: "Added insurance policies",
    firstLine: "Added insurance policies",
    secondLine: "users with added policies",
    firstField: "addedPolicies",
    secondField: "addedPoliciesUsers",
    tab: "orderedPolicies",
  },
  {
    header: "Insurance instalments",
    firstLine: "Paid Insurance instalments",
    secondLine: "users with paid instalments",
    firstField: "installmentsPaid",
    secondField: "installmentsPaidUsers",
    tab: "installments",
  },
  {
    header: "Police fines",
    firstLine: "paid tickets and fines",
    secondLine: "users with paid tickets and fines",
    firstField: "finesPaid",
    secondField: "finesPaidUsers",
    tab: "fines",
  },
  {
    header: "Vignettes",
    firstLine: "purchased Vignettes",
    secondLine: "Users with purchased Vignettes",
    firstField: "purchasedVignettes",
    secondField: "purchasedVignettesUsers",
    tab: "vignettes",
  },
  {
    header: "Parking zones",
    firstLine: "paid Parking zones",
    secondLine: "users with paid parking zone",
    firstField: "parkingReminders",
    secondField: "parkingRemindersUsers",
    tab: "parking-zones",
  },
  {
    header: "Claims",
    firstLine: "Filled Claims",
    secondLine: "Users with claims",
    firstField: "claims",
    secondField: "claimsUsers",
    tab: "claims",
  },
  {
    header: "Costs",
    firstLine: "manually added costs",
    secondLine: "Users with manually added costs",
    firstField: "manualExpenses",
    secondField: "manualExpensesUsers",
    tab: "costs",
  },
  {
    header: "Calendar",
    firstLine: "manually added events",
    secondLine: "Users with manually added events",
    firstField: "manualEvents",
    secondField: "manualEventsUsers",
    tab: "calendar",
  },
  {
    header: "Dash Cam",
    firstLine: "dash cam sessions",
    secondLine: "created highlights",
    thirdLine: "sent reports",
    fourthLine: "users with dash cam sessions",
    fifthLine: "users with created highlights",
    sixthLine: "users with sent reports",
    firstField: "dashcamSessions",
    secondField: "dashcamVideos",
    thirdField: "dashcamReportedVideos",
    fourthField: "dashcamSessionsUsers",
    fifthField: "dashcamSessionUsersWithVideos",
    sixthField: "dashcamReportedVideosUsers",
    tab: "dash",
  },
];

const App = ({ stats, getStats }) => {
  const [selTab, setSelTab] = useState("newPolicies");
  const [selPeriod, setSelPeriod] = useState({
    createdAtFrom: options[1].value,
    createdAtTo: moment(),
  });
  const [filtered, setFiltered] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    getStats({
      startDate: selPeriod.createdAtFrom || moment("2020-07-21").startOf("day"),
      endDate: selPeriod.createdAtTo,
    });
  }, [selPeriod]);

  return (
    <div className="main-container">
      <div
        style={{
          height: "25%",
          width: "100%",
          padding: "10px",
          display: "flex",
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        {cardTypes.map((card, i) => {
          return (
            <React.Fragment key={i}>
              <div className={`card-grid ${card.tab === "dash" && "bigger"}`}>
                <div
                  className={`card-header ${card.tab === selTab && "selected"}`}
                  onClick={() => {
                    if (selTab !== card.tab) {
                      setLoading(true);
                      setSelTab(card.tab);
                    }
                  }}
                >
                  <h2
                    className={`${card.tab === selTab ? "font-bold" : "font-semiBold"}`}
                    style={{
                      textTransform: "uppercase",
                      width: "93%",
                      marginLeft: "5px",
                    }}
                  >
                    {card.header}
                  </h2>

                  <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"createdAtFrom"} endField={"createdAtFrom"} selected={card.tab === selTab} />
                </div>
                <div className={`card-body ${card.tab === "dash" && "bigger"}`}>
                  <div className={`card-row ${card.tab === "dash" && "semi-width"}`}>
                    <div className="indicator blue" />
                    <p className="row-data">
                      <b>{stats?.[card?.firstField] || "---"} </b>
                      {card.firstLine}
                    </p>
                  </div>
                  <div className={`card-row ${card.tab === "dash" && "semi-width"}`}>
                    <div className="indicator blue" />
                    <p className="row-data">
                      <b>{card.secondField.includes("/") ? stats?.[card?.secondField.split("/")[0]] + stats?.[card?.secondField.split("/")[1]] : stats?.[card?.secondField] || "---"} </b>
                      {card.secondLine}
                    </p>
                  </div>
                  {card.tab === "dash" && (
                    <>
                      <div className={`card-row ${card.tab === "dash" && "semi-width"}`}>
                        <div className="indicator gray" />
                        <p className="row-data">
                          <b>{stats?.[card?.thirdField] || "---"} </b>
                          {card.thirdLine}
                        </p>
                      </div>
                      <div className={`card-row ${card.tab === "dash" && "semi-width"}`}>
                        <div className="indicator gray" />
                        <p className="row-data">
                          <b>{stats?.[card?.fourthField] || "---"} </b>
                          {card.fourthLine}
                        </p>
                      </div>
                      <div className={`card-row ${card.tab === "dash" && "semi-width"}`}>
                        <div className="indicator gray" />
                        <p className="row-data">
                          <b>{stats?.[card?.fifthField] || "---"} </b>
                          {card.fifthLine}
                        </p>
                      </div>
                      <div className={`card-row ${card.tab === "dash" && "semi-width"}`}>
                        <div className="indicator gray" />
                        <p className="row-data">
                          <b>{stats?.[card?.sixthField] || "---"} </b>
                          {card.sixthLine}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="body-container" style={{ height: "73%", overflowX: window.innerWidth < 1200 && "auto" }}>
        {/*To-do: add all tables */}
        {selTab === "newPolicies" ? (
          <AppGrids.OrderedInsurances period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "orderedPolicies" ? (
          <AppGrids.NewInsurances period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "installments" ? (
          <AppGrids.Installments period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "fines" ? (
          <AppGrids.PaidFines period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "vignettes" ? (
          <AppGrids.Vignettes period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "parking-zones" ? (
          <AppGrids.Parking period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "claims" ? (
          <AppGrids.Claims period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "costs" ? (
          <AppGrids.Costs period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "calendar" ? (
          <AppGrids.Events period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : selTab === "dash" ? (
          <AppGrids.Dash period={selPeriod} setLoading={setLoading} loading={loading} />
        ) : null}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  stats: state.app.stats,
});
const mapDispatchToProps = (dispatch) => ({
  getStats: (payload) => dispatch(getStats(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
