/* eslint-disable */
import React, { useState } from "react";
import "./styles.scss";
import Inputs from "../../components/Inputs";
import {toast} from "react-toastify";

const CommentModal = ({
  handleClose,
  file,
  files,
  editAd,
  id,
  adID,
  getCurrentAd,
}) => {
  const [comment, setComment] = useState("");

  return (
    <div
      className="inner-modal-component"
      style={{ height: "22%", width: "25%" }}
    >
      <div className="full-width">
        <div
          className="flex-container full-width align-center"
          style={{ marginBottom: "20px" }}
        >
          <div
            className="close-icon"
            style={{ marginRight: "2%" }}
            onClick={() => handleClose()}
          />
          <h3>ADD COMMENT</h3>
        </div>
        <Inputs.TextInput
          label="Comment"
          compulsory
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </div>
      <Inputs.Button
        style={{ width: "100%", paddingTop: "1%" }}
        text="Apply"
        selected
        onClick={() => {
          const newFile = { ...file };
          newFile.comment = comment;

          const newFiles = files.slice(0);
          newFiles[id] = newFile;

          editAd({
            _id: adID,
            files: newFiles,
            onSuccess: (res) => {
              setComment("");
              getCurrentAd({
                _id: adID,
              });
              toast.success("Comment added successfully");
              handleClose();
            },
          });
          handleClose();
        }}
      />
    </div>
  );
};

export default CommentModal;
