/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError, mergeMap } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { StatisticsStats } from "../actions";
import { API_BOS_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getActiveUsersEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_ACTIVE_USERS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/activeUsers`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "activeUsers",
                loading: false,
                ...response.payload,
              },
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getTotalAppDownloadsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_TOTAL_APP_DOWNLOADS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/totalDownloads`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "totalDownloads",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getIncomeRevenueEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_INCOME_REVENUE),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/transactionsIncomeRevenue`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "incomes",
                loading: false,
                ...response.payload,
              },
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getAppSessionsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_APP_SESSIONS),
    mergeMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/appSessions`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        mergeMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.merge) {
              obs.next({
                type: StatisticsStats.GET_STATISTICS_SUCCESS_MERGE,
                payload: {
                  type: "sessions",
                  loading: false,
                  ...response.payload,
                },
                body: payload.endDate,
              });
            } else
              obs.next({
                type: StatisticsStats.GET_STATISTICS_SUCCESS,
                payload: {
                  type: "sessions",
                  loading: false,
                  ...response.payload,
                },
              });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            if (payload.onError) payload.onError(payload);
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getRegisteredUsersEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_REGISTERED_USERS),
    mergeMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/users`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        mergeMap(({ response }) =>
          ActionsObservable.create((obs) => {
            if (payload.merge) {
              obs.next({
                type: StatisticsStats.GET_STATISTICS_SUCCESS_MERGE,
                payload: {
                  type: "registeredUsers",
                  loading: false,
                  ...response.payload,
                },
                body: payload.endDate,
              });
            } else
              obs.next({
                type: StatisticsStats.GET_STATISTICS_SUCCESS,
                payload: {
                  type: "registeredUsers",
                  loading: false,
                  ...response.payload,
                },
              });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            if (payload.onError) payload.onError(payload);
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getInnerVehiclesEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_INNER_VEHICLES),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/vehiclesAdded`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "innerVehicles",
                loading: false,
                ...response.payload,
              },
            });
            if (payload?.onSuccess) payload?.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const getInnerLicensesEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_INNER_LICENSES),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/drivingLicensesAdded`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "innerLicenses",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getVehiclesEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_VEHICLES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/vehicles`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "vehicles",
                loading: false,
                ...response.payload,
              },
            });
            if (payload?.onSuccess) payload?.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getAppGrowthEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_APP_GROWTH),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/appGrowth`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "appGrowth",
                loading: false,
                ...response.payload,
              },
            });
            if (payload?.onSuccess) payload?.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getDownloadsByDayEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_DOWNLOADS_BY_DAY),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/downloadsByDay`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            /*obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "downloadsByDay",
                loading: false,
                ...response.payload,
              },
            });*/
            obs.next({
              type: StatisticsStats.GET_DOWNLOADS_BY_DAY_SUCCESS,
              payload: {
                type: "downloadsByDay",
                loading: false,
                ...response.payload,
              },
            });
            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getReviewsByOsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_REVIEWS_BY_OS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/reviews/${payload.os}`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          startDate: payload.startDate,
          endDate: payload.endDate,
          pageNumber: payload.pageNumber,
        }),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_REVIEWS_BY_OS_SUCCESS,
              payload: {
                ...response.payload,
              },
            });
            if (payload?.onSuccess) payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getRatingsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_RATINGS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/ratings`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "ratings",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getActiveSubsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_ACTIVE_SUBS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/activeSubscriptions`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "activeSubscriptions",
                loading: false,
                elements: [...response.payload],
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getNewSubsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_NEW_SUBS),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_BOS_URL}/statsAdmin/newSubscriptions`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "newSubscriptions",
                loading: false,
                elements: [...response.payload],
              },
            });
            if (payload?.onSuccess) payload?.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
