/* eslint-disable */
import React, { useState } from "react";
import "../styles.scss";
import { getCurrentDiscount, getInsuranceAgencies } from "../../../actions";
import { useEffect } from "react";
import { connect } from "react-redux";

const AgenciesModal = ({ handleClose, id, discounts, getCurrentDiscount, getInsuranceAgencies }) => {
  const [discount, setDiscount] = useState();
  useEffect(() => {
    if (id) {
      getInsuranceAgencies({
        onSuccess: (res) => {
          console.log(res);
        },
      });
      getCurrentDiscount({
        _id: id,
        onSuccess: (res) => {
          setDiscount(res);
        },
      });
    }
  }, [id]);

  console.log(discount);
  return (
    <div className="inner-modal-component" style={{ height: "90%", width: "35%", borderRadius: "7px", marginRight: "2%" }}>
      <div className="full-width" style={{ height: "100%" }}>
        <div className="flex-container full-width align-center" style={{ marginBottom: "20px" }}>
          <div className="close-icon" style={{ marginRight: "2%" }} onClick={() => handleClose()} />
          <h3>INCLUDED INSURANCES</h3>
        </div>
        <div className="full-width" style={{ borderLeft: "1px solid black", height: "90%" }}>
          {discount?.customDiscounts?.map((disc) => {
            return (
              <div className="company-box">
                <div className="divider vertical" style={{ minHeight: "50px", width: "4px", height: "unset" }} />
                <div style={{ width: "100%" }}>
                  <h2 style={{ fontSize: "17px", textTransform: "capitalize" }}>{disc?.policyType} insurance</h2>
                  {disc?.genericDiscount === true ? (
                    <p style={{ width: "100%", margin: "5px 0px" }}>All companies: {discount?.discountInPercent ? ` -${disc?.discount}%` : ` -${disc?.discount}лв`}</p>
                  ) : (
                    disc?.customDiscountsPerInsuranceAgency?.map((custom) => (
                      <p style={{ width: "100%", margin: "5px 0px" }}>
                        <span style={{ fontWeight: "600" }}>{custom?.insuranceAgencyName}</span> : {discount?.discountInPercent ? ` -${custom?.discount}%` : ` -${custom?.discount}лв`}
                      </p>
                    ))
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  discounts: state.discounts.discounts,
});
const mapDispatchToProps = (dispatch) => ({
  getCurrentDiscount: (payload) => dispatch(getCurrentDiscount(payload)),
  getInsuranceAgencies: (payload) => dispatch(getInsuranceAgencies(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AgenciesModal);
