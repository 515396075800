/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getEvents } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "User",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Name of calendar event",
    query: { filter: "Invitation sent by", options: [] },
  },
];

const Events = ({ getEvents, events, period, loading, setLoading }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && events.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getEvents({
        pageNumber: curPage,
        limit: 20,
        ...period,
        createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getEvents({
      pageNumber: 1,
      limit: 20,
      ...period,
      createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
      onSuccess: (res) => setLoading(false),
    });
    setCurPage(2);
  }, [period]);

  return (
    <>
      {loading === true ? (
        <div style={{ height: "90%" }}>{<Statistics.LoaderInline center />}</div>
      ) : (
        <>
          <h2 style={{ marginBottom: "20px", textTransform: "uppercase" }}>Users with manually added events</h2>
          <Scrollbars style={{ height: "92%", width: "100%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {events?.docs?.map((event, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12%" }}>
                    {event?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {event?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "75%" }}>
                    {event?.title || "---"}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  events: state.app.events,
});
const mapDispatchToProps = (dispatch) => ({
  getEvents: (payload) => dispatch(getEvents(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Events);
