import { FinancesStats } from "../actions";
const initialState = {
  incomeOnline: {
    totalPages: 0,
    data: [],
  },
  incomeInstallments: {
    totalPages: 0,
    data: [],
  },
  incomePolicies: {
    totalPages: 0,
    data: [],
  },
  incomePotential: {},
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.data;
  return [...state?.[key]?.data, ...payload.data];
};

const finances = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case FinancesStats.GET_INCOME_SUCCESS:
      return {
        ...state,
        [key]: {
          totalPages: payload.totalPages,
          data: handleNewDocs(state, payload, key),
        },
      };
    case FinancesStats.GET_POTENTIAL_INCOME_SUCCESS:
      return {
        ...state,
        incomePotential: {...payload},
      };
    default:
      return state;
  }
};

export default finances;
