export const FinancesStats = {
  GET_GENERATED_INCOME_ONLINE: "finances/GET_GENERATED_INCOME_ONLINE",
  GET_INCOME_SUCCESS: "finances/GET_INCOME_SUCCESS",
  GET_GENERATED_INCOME_INSTALLEMENTS:
    "finances/GET_GENERATED_INCOME_INSTALLEMENTS",
  GET_GENERATED_INCOME_POLICIES: "finances/GET_GENERATED_INCOME_POLICIES",
  GET_POTENTIAL_INCOME: "finances/GET_POTENTIAL_INCOME",
  GET_POTENTIAL_INCOME_SUCCESS: "finances/GET_POTENTIAL_INCOME_SUCCESS",
};

export const getGeneratedIncomeOnline = (payload) => ({
  type: FinancesStats.GET_GENERATED_INCOME_ONLINE,
  payload,
});
export const getGeneratedIncomeInstallements = (payload) => ({
  type: FinancesStats.GET_GENERATED_INCOME_INSTALLEMENTS,
  payload,
});
export const getGeneratedIncomePolicies = (payload) => ({
  type: FinancesStats.GET_GENERATED_INCOME_POLICIES,
  payload,
});
export const getPotentialIncome = (payload) => ({
  type: FinancesStats.GET_POTENTIAL_INCOME,
  payload,
});
