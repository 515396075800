import React from "react";
import { forwardRef } from "react";
import "./styles.scss";

const RadioButtons = forwardRef(({ className, options, disabled, onChange, value, label, compulsory, column, outerStyle, innerStyle }, ref) => {
  return (
    <>
      <label
        style={{
          display: "flex",
          width: "100%",
          fontSize: "0.8rem",
          marginTop: "2%",
          color: className && "red",
        }}
      >
        {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
      </label>
      <div className={`radio-buttons-container ${column && "column"}`} style={outerStyle}>
        {options?.map((opt, i) => {
          return (
            <div
              className={`radio-button-container ${column && "column"}`}
              key={i}
              style={{ marginLeft: !column && i > 0 && "3%" }}
              
              onClick={() => {
                if (disabled || !onChange || value === (opt?.value || opt)) return;
                onChange(opt?.value || opt);
              }}
            >
              <div className="outer-circle">
                <div className={`inner-circle ${opt.value === value && "selected"}`} />
              </div>
              <label style={{ paddingLeft: "6px" }}>{opt?.label}</label>
            </div>
          );
        })}
      </div>
    </>
  );
});

export default RadioButtons;
