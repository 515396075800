import moment from "moment";
export const options = [
  {
    label: "Last 24 hours",
    value: moment().subtract(1, "days").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "Last 7 days",
    value: moment().subtract(7, "days").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "Last 31 days",
    value: moment().subtract(31, "days").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "Last 6 months",
    value: moment().subtract(6, "months").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "Last 12 months",
    value: moment().subtract(1, "year").startOf("day").format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "All",
    value: null,
  },
];
export const monthOptions = [
  {
    label: "January",
    startDate: moment().startOf("year").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment().startOf("year").endOf("month").format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "February",
    startDate: moment().startOf("year").add("1", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("1", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "March",
    startDate: moment().startOf("year").add("2", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("2", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "April",
    startDate: moment().startOf("year").add("3", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("3", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "May",
    startDate: moment().startOf("year").add("4", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("4", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "June",
    startDate: moment().startOf("year").add("5", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("5", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "July",
    startDate: moment().startOf("year").add("6", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("6", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "August",
    startDate: moment().startOf("year").add("7", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("7", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "September",
    startDate: moment().startOf("year").add("8", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("8", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "October",
    startDate: moment().startOf("year").add("9", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("9", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "November",
    startDate: moment().startOf("year").add("10", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("10", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
  {
    label: "December",
    startDate: moment().startOf("year").add("11", "month").format('YYYY-MM-DD') + "T00:00:00.000Z",
    endDate: moment()
      .startOf("year")
      .add("11", "month")
      .endOf("month")
      .format('YYYY-MM-DD') + "T00:00:00.000Z",
  },
];

export const editorConfig = {
  toolbar: [
      { name: 'corrections', items: ['Undo', 'Redo'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike'] },
      { name: 'styles', items: ['Format'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'NumberedList', 'BulletedList'] },
      { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText'] },
      { name: 'tools', items: ['Maximize'] },
  ],
  enterMode: 'ENTER_BR',
  plugins: [
      'basicstyles',
      'clipboard',
      'emoji',
      'enterkey',
      'entities',
      'floatingspace',
      'indentlist',
      'justify',
      'link',
      'list',
      'toolbar',
      'undo',
      'wysiwygarea',
      "maximize",
      'format',
  ],
}

export const editorNotificationsConfig = {
  enterMode: 'ENTER_BR',
  toolbar: [{ name: 'links', items: ['Link', 'Unlink'] },],
  plugins: ['link', 'toolbar', 'undo', 'wysiwygarea',],
  uiColor: "#FFFFFF",
  height:"100px"
}