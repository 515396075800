import { default as Sessions } from "./Sessions";
import { default as LoaderInline } from "./LoaderInline";
import { default as Horizontal } from "./Horizontal";
import { default as BarChart } from "./BarChart";
import { default as Pie } from "./Pie";
import { default as Income } from "./Income";
import { default as AppSessions } from "./AppSessions";
import { default as RegisteredUsers } from "./RegisteredUsers";
import { default as PremiumUsers } from "./PremiumUsers";
import { default as Vehicles } from "./Vehicles";
import { default as AppGrowth } from "./AppGrowth";
import { default as Reviews } from "./Reviews";
import { default as SelectPeriod } from "./Popup";
const Statistics = {
  LoaderInline,
  Horizontal,
  BarChart,
  Pie,
  SelectPeriod,
  PremiumUsers,
  Sessions,
  Income,
  AppSessions,
  RegisteredUsers,
  Vehicles,
  AppGrowth,
  Reviews,
};

export default Statistics;
