import { OfficesStats } from "../actions";

const initialState = {
  offices: [],
  office: {},
};

const offices = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case OfficesStats.GET_OFFICES_SUCCESS:
      return {
        ...state,
        offices: payload,
      };
    case OfficesStats.GET_CURRENT_OFFICE_SUCCESS:
      return {
        ...state,
        office: payload,
      };
    default:
      return state;
  }
};

export default offices;
