/* eslint-disable */
import io from "socket.io-client";
import { ofType, ActionsObservable } from "redux-observable";
import { map, mergeMap, catchError, switchMap } from "rxjs/operators";
import { socketTypes, statsSocketConnected, tasksSocketConnected } from "../actions";
import { fromEvent } from "rxjs";
import { API_URL } from "../config/settings";

let statsSocket;

const SOCKET_SESSION_EVENT = "stats-action";

export const statsConnectEpic = (action$) =>
  action$.pipe(
    ofType(socketTypes.STATS_CONNECT),
    map(({ payload }) => {
      statsSocket = io(API_URL, { query: payload.query });

      return statsSocketConnected();
    })
  );
export const statsListenerEpic = (action$) =>
  action$.pipe(
    ofType(socketTypes.STATS_CONNECTED),
    mergeMap(() =>
      fromEvent(statsSocket, SOCKET_SESSION_EVENT).pipe(
        switchMap(({ action, payload }) => {
          return ActionsObservable.create((obs) => {
            obs.next({ type: action, payload });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      )
    )
  );

// let tasksSocket;

// const SOCKET_SESSION_EVENT_TASKS = "task-message";

// export const tasksConnectEpic = (action$) =>
//   action$.pipe(
//     ofType(socketTypes.TASKS_CONNECT),
//     map(({ payload }) => {
//       console.log(payload);
//       tasksSocket = io(API_URL, { query: payload.tasksQuery });
//       console.log(tasksSocket);
//       return tasksSocketConnected();
//     })
//   );
// export const tasksListenerEpic = (action$) =>
//   action$.pipe(
//     ofType(socketTypes.TASKS_CONNECTED),
//     mergeMap(() =>
//       fromEvent(tasksSocket, SOCKET_SESSION_EVENT_TASKS).pipe(
//         switchMap(({ action, payload }) => {
//           console.log(payload, action);
//           return ActionsObservable.create((obs) => {
//             console.log(payload);
//             // tasksSocket.emit(SOCKET_SESSION_EVENT_TASKS, { action: "task/getTasks", payload: {} });
//             obs.next({ type: action, payload });
//             obs.complete();
//           });
//         }),
//         catchError((err) => {
//           return ActionsObservable.create((obs) => {
//             console.log(err);
//             obs.complete();
//           });
//         })
//       )
//     )
//   );

// export const tasksSenderEpic = (action$) =>
//   action$.pipe(
//     ofType(socketTypes.TASKS_EMIT_EVENT),
//     switchMap(({ payload }) =>
//       ActionsObservable.create((obs) => {
//         tasksSocket.emit(SOCKET_SESSION_EVENT_TASKS, payload);
//         obs.complete(SOCKET_SESSION_EVENT_TASKS, payload);
//       })
//     )
//   );
