/* eslint-disable */
import React, { useState } from "react";
import "./styles.scss";
import Inputs from "../Inputs";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 49,
    height: 40,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const InnerModal = ({ handleClose, options, selectedData, setSelectedData, label, mapValuesForQuery }) => {
  return (
    <div className="inner-modal-component">
      <div className="full-width">
        <div className="flex-container full-width align-center" style={{ marginBottom: "20px" }}>
          <div className="close-icon" style={{ marginRight: "2%" }} onClick={() => handleClose()} />
          <h3>Select {label}</h3>
        </div>
        <Inputs.Dropdown
          optionsArray={options?.map((opt) => {
            return {
              label: opt?.from ? `${opt?.from}-${opt?.to || "+"}` : opt?.name ? opt?.name : opt?.from === 0 ? "0-0" : opt,
              value: opt?.from ? `${opt?.from}-${opt?.to || "+"}` : opt?.name ? opt?._id : opt?.from === 0 ? "0-0" : opt,
            };
          })}
          styles={{ width: "100%", marginLeft: "0%" }}
          customStyles={customStyles}
          menuIsOpen={true}
          value={selectedData?.[label]?.map((opt) => {
            return {
              label: opt,
              value: opt,
            };
          })}
          onChange={(e) => {
            if (e[0]?.label !== "SELECT ALL") {
              setSelectedData({
                ...selectedData,
                [label]: e.map((pair) => (pair?.value === pair?.label ? pair.value : pair)),
              });
            }
          }}
        />
      </div>
      <Inputs.Button
        style={{ width: "100%", paddingTop: "1%" }}
        text="Apply selection"
        selected
        onClick={() => {
          mapValuesForQuery();
          handleClose();
        }}
      />
    </div>
  );
};

export default InnerModal;
