/* eslint-disable */
import moment from "moment";
import React, { useState, useRef, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import Statistics from "../../../components/Statistics";
import "../styles.scss";

const headerElements = [
  {
    name: "Code",
    query: { filter: "Time", options: [] },
  },
  {
    name: "User",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Order date",
    query: { filter: "Invitation sent to", options: [] },
  },
  // {
  //   name: "Type of insurance",
  //   query: { filter: "Role", options: [] },
  // },
  {
    name: "Insurance company",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Orginal amount",
    query: { filter: "Role", options: [] },
  },
  // {
  //   name: "Discount",
  //   query: { filter: "Role", options: [] },
  // },
  {
    name: "Discounted amount",
    query: { filter: "Role", options: [] },
  },
];

const UsedDiscountsGrid = ({ docs, loading, setLoading, getDiscountsUsed }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && docs.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getDiscountsUsed({
        pageNumber: curPage,
        pageSize: 40,
        pagination: true,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getDiscountsUsed({
      pageNumber: 1,
      pageSize: 40,
      pagination: true,
      onSuccess: () => {
        setLoading(false);
      },
    });
  }, []);

  console.log(docs);

  return (
    <>
      {loading === true ? (
        <Statistics.LoaderInline />
      ) : (
        <Scrollbars style={{ height: "93%", width: "100%", marginTop: "2%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
          <div className="table-header">
            {headerElements?.map((header, i) => {
              return (
                <div className="header-cont" key={i} style={{ width: "14%" }}>
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {docs?.docs?.map((doc, i) => {
            return (
              <div className="table-body-row" key={i}>
                <div className="discount used row-data">{doc?.discountCode || "---"}</div>
                <div className="discount used row-data">{doc?.fullName || "---"}</div>
                <div className="discount used row-data"> {doc?.phoneNumber || "---"}</div>
                <div className="discount used row-data"> {moment(doc?.createdAt).format("DD.MM.YYYY")}</div>
                <div className="discount used row-data" style={{ textTransform: "capitalize" }}>
                  {doc?.insuranceAgency || "---"}
                </div>
                <div className="discount used row-data">{doc?.originalAmount + " BGN"}</div>
                {/* <div className="discount used row-data">{Math.round(100 - (doc?.discountedAmount / doc?.originalAmount) * 100)}</div> */}
                <div className="discount used row-data">{doc?.discountedAmount + " BGN"}</div>
              </div>
            );
          })}
          {innerLoading && (
            <div className="inner-loader-container">
              <Statistics.LoaderInline center />
            </div>
          )}
        </Scrollbars>
      )}
    </>
  );
};

export default UsedDiscountsGrid;
