/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { getArticles, getOffices } from "../../actions";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import "./styles.scss";
import ArticlesGrid from "./Grids/ArticlesGrid";
import OfficesGrid from "./Grids/OfficesGrid";

const Website = ({ articles, getArticles, getOffices, offices }) => {
  const [selectedTab, setSelectedTab] = useState("articles");

  useEffect(() => {
    getArticles({
      page: 1,
      limit: 20,
    });
    getOffices();
  }, []);

  return (
    <div className="main-container">
      <div
        className="body-container"
        style={{ height: "100%", marginTop: "0%" }}
      >
        <div className="flex-container full-width space-between">
          <div className="flex-container" style={{ width: "25%" }}>
            <div
              className={`tab-button flex-container justify-center align-center ${
                selectedTab === "articles" && "selected"
              }`}
              onClick={() => setSelectedTab("articles")}
            >
              Articles
            </div>
            <div
              className={`tab-button flex-container justify-center align-center ${
                selectedTab === "offices" && "selected"
              }`}
              onClick={() => setSelectedTab("offices")}
            >
              Offices
            </div>
          </div>
          <Inputs.Button
            text="ADD NEW"
            selected
            onClick={() => {
              if (selectedTab === "articles") history.push("/articles/add");
              else history.push("/office/add");
            }}
          />
        </div>
        {selectedTab === "articles" ? (
          <ArticlesGrid getArticles={getArticles} articles={articles} />
        ) : (
          <OfficesGrid offices={offices} />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  articles: state.articles.articles,
  offices: state.offices.offices,
});
const mapDispatchToProps = (dispatch) => ({
  getArticles: (payload) => dispatch(getArticles(payload)),
  getOffices: (payload) => dispatch(getOffices(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Website);
