export const ArticlesStats = {
  GET_ARTICLES: "article/GET_ARTICLES",
  GET_ARTICLES_SUCCESS: "article/GET_ARTICLES_SUCCESS",
  GET_CURRENT_ARTICLE: "article/GET_CURRENT_ARTICLE",
  GET_CURRENT_ARTICLE_SUCCESS: "article/GET_CURRENT_ARTICLE_SUCCESS",
  ADD_ARTICLE: "article/ADD_ARTICLE",
  EDIT_ARTICLE: "article/EDIT_ARTICLE",
  DELETE_ARTICLE: "article/DELETE_ARTICLE",
};
export const getArticles = (payload) => ({
  type: ArticlesStats.GET_ARTICLES,
  payload,
});
export const getCurrentArticle = (payload) => ({
  type: ArticlesStats.GET_CURRENT_ARTICLE,
  payload,
});
export const addArticle = (payload) => ({
  type: ArticlesStats.ADD_ARTICLE,
  payload,
});
export const editArticle = (payload) => ({
  type: ArticlesStats.EDIT_ARTICLE,
  payload,
});
export const deleteArticle = (payload) => ({
  type: ArticlesStats.DELETE_ARTICLE,
  payload,
});
