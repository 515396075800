/* eslint-disable */
import { TaskTypes } from "../actions/tasks";
import moment from "moment";

const initialState = {
  loading: false,
  categories: [],
  category: {},
  tasks: [],
  task: {},
  comments: [],
  activities: [],
  files: [],
  users: [],
  filters: {},
  horizontalScroll: 0,
  lastOpenedTask: {},
  taskSocket: null,
};

const createTask = (state, payload) => {
  if (payload && state.tasks.length) {
    const newData = state.tasks.slice(0);
    const categoryToUpdate = newData.find((el) => el._id === payload.request.categoryId);
    const categoryToUpdateIndex = newData.findIndex((c) => c._id === categoryToUpdate._id);

    categoryToUpdate._tasks = categoryToUpdate?._tasks || [];
    categoryToUpdate._tasks.push(payload.task);
    newData.splice(categoryToUpdateIndex, 1, categoryToUpdate);

    return newData;
  } else {
    return state.tasks;
  }
};

const updateTask = (state, payload) => {
  if (payload && state.tasks.length) {
    const newData = state.tasks.slice(0);
    const categoryToUpdate = newData.find((el) => el._id === payload.task.category._id);
    const categoryToUpdateIndex = newData.findIndex((c) => c._id === categoryToUpdate._id);
    let indexToUpdate;

    if (categoryToUpdate?._tasks?.length) {
      indexToUpdate = categoryToUpdate._tasks.findIndex((el) => el._id === payload.task._id);
    } else {
      indexToUpdate = 0;
    }

    console.log(categoryToUpdate);

    if (categoryToUpdate?._tasks?.length) {
      categoryToUpdate._tasks.splice(indexToUpdate, 1, payload.task);
      newData.splice(categoryToUpdateIndex, 1, categoryToUpdate);
    }

    return newData;
  } else {
    return state.tasks;
  }
};

const deleteTask = (state, payload) => {
  if (payload) {
    let newData = state.tasks.slice(0);

    newData = newData.map((category) => {
      let index = category._tasks.findIndex((el) => el._id === payload.taskId);

      if (index !== -1) {
        category._tasks.splice(index, 1);
      }

      return category;
    });

    return newData;
  } else {
    return state.tasks;
  }
};

const updateStep = (state, payload) => {
  if (payload) {
    const newData = state.task._steps.slice();
    const indexToUpdate = newData.findIndex((el) => el._id === payload.step._id);
    newData.splice(indexToUpdate, 1, payload.step);
    return newData;
  } else {
    return state.task._steps;
  }
};

const deleteFile = (state, payload) => {
  if (payload) {
    const newData = state.tasks.slice(0);
    const indexToDelete = newData.findIndex((el) => el._id === payload.file._id);
    newData.splice(newData.indexOf(indexToDelete), 1);
    return newData;
  } else {
    return state.files;
  }
};

const updateCategory = (state, payload) => {
  if (payload) {
    const newData = state.tasks.slice(0);
    const indexToUpdate = newData.findIndex((el) => el?._id === payload?._id);
    newData.splice(indexToUpdate, 1, payload);
    return newData;
  } else {
    return state.tasks;
  }
};

const updateCategories = (state, payload) => {
  if (payload !== null && payload !== undefined) {
    const newData = state.tasks.slice(0);
    const newCategory = newData.find((category) => category._id === payload.newCategoryId);
    const oldCategory = newData.find((category) => category._id === payload.oldCategoryId);
    // const newTasks = payload.newOrder.map(taskId => newCategory._tasks.find(el => el._id === taskId) || oldCategory._tasks.find(el => el._id === taskId))

    // const newTasks = newCategory._tasks.slice()
    // newTasks.splice(payload.request.newCategoryId, 0, payload.task)
    // newCategory._tasks = newTasks

    // oldCategory._tasks.splice(oldCategory._tasks.findIndex(el => el._id === payload.taskId), 1)
    newData.splice(
      newData.findIndex((el) => el._id === newCategory._id),
      1,
      newCategory
    );
    newData.splice(
      newData.findIndex((el) => el._id === oldCategory._id),
      1,
      oldCategory
    );

    return newData;
  } else {
    return state.tasks;
  }
};

const toggleStep = (state, payload) => {
  if (payload) {
    let newSteps = JSON.parse(JSON.stringify(state.task._steps));
    const indexToToggle = newSteps.indexOf(newSteps.find((el) => el._id === payload.request.stepId));
    newSteps.splice(indexToToggle, 1, payload.step);
    return newSteps;
  } else {
    return state.task._steps;
  }
};

const tasks = (state = initialState, { type, payload }) => {
  switch (type) {
    case TaskTypes.GET_TASKS_SUCCESS:
      return { ...state, loading: false, tasks: payload.tasksByCategory, filters: payload.filters, task: {} };

    case TaskTypes.REPLACE_SOCKET:
      return { ...state, taskSocket: payload };
    case TaskTypes.GET_TASK_SUCCESS:
      return { ...state, task: { ...payload.task, _steps: payload.task._steps.sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt))) } };
    case TaskTypes.CREATE_TASK_SUCCESS:
      return { ...state, tasks: createTask(state, payload), task: {} };

    case TaskTypes.UPDATE_TASK_SUCCESS:
      return { ...state, tasks: updateTask(state, payload), task: {} };
    case TaskTypes.DELETE_TASK_SUCCESS:
      return { ...state, tasks: deleteTask(state, payload), task: {} };
    case TaskTypes.FINISH_TASK_SUCCESS:
      return { ...state, task: payload?.task };
    case TaskTypes.FINISH_TASK_GLOBAL_SUCCESS:
      return { ...state, tasks: updateTask(state, payload) };

    case TaskTypes.CHANGE_TASK_CATEGORY_SUCCESS:
      return { ...state, tasks: updateCategories(state, payload) };

    case TaskTypes.GET_CATEGORIES_SUCCESS:
      return { ...state, categories: payload?.categories, task: {} };
    case TaskTypes.CREATE_CATEGORY_SUCCESS:
      return { ...state, categories: [...state.categories, payload?.category], tasks: [...state.tasks, payload?.category] };
    case TaskTypes.UPDATE_CATEGORY_SUCCESS:
      return { ...state, tasks: updateCategory(state, payload) };
    case TaskTypes.DELETE_CATEGORY_SUCCESS:
      return { ...state, categories: state.categories.filter((c) => c?._id !== payload?.categoryId), tasks: state.tasks.filter((el) => el._id !== payload.categoryId) };
    case TaskTypes.REORDER_CATEGORY_SUCCESS:
      return { ...state, tasks: payload?.newOrder?.map((_id) => state.tasks.find((c) => c._id === _id)) };
    case TaskTypes.CREATE_STEP_SUCCESS:
      return { ...state, task: { ...state.task, _steps: [...state.task._steps, payload.step] } };
    case TaskTypes.TOGGLE_STEP_SUCCESS:
      return { ...state, task: { ...state.task, _steps: toggleStep(state, payload), activities: [...state.task._activity, payload?.activity] } };
    case TaskTypes.DELETE_STEP_SUCCESS:
      return {
        ...state,
        task: { ...state.task, _steps: [...state.task._steps.filter((step) => step?._id !== payload?.step?._id)], activities: [...state.task._activity, payload?.activity] },
      };
    case TaskTypes.UPDATE_STEP_SUCCESS:
      return { ...state, task: { ...state.task, _steps: updateStep(state, payload) } };
    case TaskTypes.UPDATE_STEPS_SUCCESS:
      return { ...state, task: { ...state.task, _steps: payload.steps } };
    case TaskTypes.CREATE_COMMENT_SUCCESS:
      return { ...state, task: { ...state.task, comments: [...state.comments, payload?.request] } };
    case TaskTypes.GET_USERS_SUCCESS:
      return { ...state, users: payload?.users };
    case TaskTypes.UPLOAD_FILE_SUCCESS:
      return { ...state, files: [...state.files, payload?.file] };
    case TaskTypes.DELETE_FILE_SUCCESS:
      return { ...state, files: deleteFile(state, payload) };
    case TaskTypes.SET_HORIZONTAL_SCROLL:
      return { ...state, horizontalScroll: payload };
    case TaskTypes.SET_LAST_OPENED_TASK:
      return { ...state, lastOpenedTask: payload };

    case TaskTypes.MARK_SEEN_ACTIVITY_SUCCESS:

    default:
      return state;
  }
};

export default tasks;
