/* eslint-disable */
import React from "react";
import "./styles.scss";
import { history } from "../../../config/stores";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CheckListPopup } from "../../../components/CheckListPopup";
import "./styles.scss";
import moment from "moment";
import { RadioButton } from "../../../components/RadioButton";
import { emit } from "../../../utilites/helpers";
import { nanoid } from "nanoid";
import Inputs from "../../../components/Inputs";
import { User } from "../../../utilites";
import Popup from "reactjs-popup";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import Modals from "../../../components/ModalComponent/Modals";
import ReactQuill from "react-quill";

export default function AddEditTask() {
  const checkListRef = useRef();
  const { _id, categoryId } = useParams();
  const currentUser = User.getUser();
  const users = useSelector((state) => state.tasks.users);
  const task = useSelector((state) => state.tasks.task);
  const socket = useSelector(({ tasks }) => tasks?.taskSocket) || null;

  const [payload, setPayload] = useState({
    title: "",
    categoryId: "",
    startDate: moment().toDate(),
    endDate: null,
    priority: "high",
    assignedTo: [],
    description: "",
  });

  const [isSelected, setIsSelected] = useState("high");
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });

  let isFinished = task?.status === "completed";

  const filterUsers = () => {
    if (users?.length && currentUser?._id) {
      let newUsers = users.slice();
      let user = users?.find((user) => user?._id === currentUser?._id);
      let userIndex = users?.findIndex((user) => user?._id === currentUser?._id);
      newUsers.splice(userIndex, 1);
      newUsers.unshift(user);
      newUsers = newUsers.map((user) => user?.name + " " + user?.lastName);

      if (newUsers.length) return newUsers;
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (socket) {
      emit(socket, { action: "task/getUsers" });
      if (_id) {
        emit(socket, { action: "task/getTask", payload: { taskId: _id } });
      } else if (categoryId) {
        emit(socket, { action: "task/getTasks", payload: {} });
      }
    }
  }, [socket]);

  useEffect(() => {
    if (Object.values(task || {}).length) setEdit(true);
    else if (!Object.values(task || {}).length) setEdit(false);
  }, [task]);

  useEffect(() => {
    if (categoryId && !edit) {
      setPayload({ ...payload, categoryId: categoryId });
    } else if (Object.values(task || {}).length && edit) {
      let newPayload = {
        taskId: task?._id,
        title: task?.title,
        startDate: moment(task?.startDate),
        endDate: task?.endDate ? moment(task?.endDate) : null,
        priority: task?.priority,
        assignedTo: task?.assignedTo,
        description: task?.description,
      };

      setPayload(newPayload);
      setIsSelected(task?.priority);

      if (users) {
        let newUsersList = usersList.slice();
        newUsersList = newPayload.assignedTo.map((el) => el.name + " " + el?.lastName);
        setUsersList(newUsersList);
      }
    }

    if (users && Object.values(currentUser)) {
      filterUsers();
    }
  }, [_id, edit, users, currentUser, socket]);

  console.log(edit);

  return (
    <div className={`task-screen-wrapper ${_id ? "edit" : "add"}`}>
      <div className="task-screen-inner-wrapper">
        <div className="task-wrapper-top">
          {edit ? <h2>Редакция на задача</h2> : isFinished ? <h2>Отвори задача</h2> : <h1 className="inner-title">Нова задача</h1>}
          <div className="buttons-wrapper">
            {edit && <div className="delete-icon" onClick={() => setDeleteModal({ open: true, id: _id })} />}
            <button className="secondary-bttn" onClick={() => history.push("/tasks")}>
              Откажи
            </button>
            {edit ? (
              <button
                disabled={!payload?.startDate || !payload?.endDate || !payload?.title || !payload?.assignedTo}
                onClick={() => {
                  emit(socket, { action: "task/updateTask", payload: payload });
                  history.goBack();
                }}
              >
                Запази
              </button>
            ) : (
              <button
                className="primary-bttn"
                disabled={!payload?.title || !payload?.assignedTo || isCreated}
                onClick={() => {
                  emit(socket, { action: "task/createTask", payload: payload });
                  setIsCreated(true);
                  window.close();
                }}
              >
                Добави
              </button>
            )}
          </div>
        </div>

        <div className="task-wrapper-main">
          <h3>Основна информация</h3>
          <div className="task-wrapper-main-top">
            <div className="row wide">
              <div className="input-wrapper wide">
                <Inputs.TextInput
                  label="Заглавие"
                  value={payload?.title}
                  style={{ margin: "20px 0px" }}
                  id="activity-input"
                  onChange={(e) => {
                    setPayload({ ...payload, title: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="input-wrapper">
                <label>Начална дата</label>
                {edit && Object.keys(payload)?.length ? (
                  <Inputs.DatePicker
                    placeholder={moment(payload?.startDate).format("DD.MM.YYYY")}
                    value={payload?.startDate && moment(payload?.startDate)?._d}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        startDate: moment(e)?.toISOString(),
                        endDate:
                          moment(e).startOf("minute").isSame(moment(payload?.endDate).startOf("minute")) || moment(e).isAfter(moment(payload?.endDate))
                            ? null
                            : payload?.endDate,
                      });
                    }}
                  />
                ) : (
                  <Inputs.DatePicker
                    placeholder={moment(payload?.startDate).format("DD.MM.YYYY")}
                    value={payload?.startDate && moment(payload?.startDate)?._d}
                    onChange={(e) => {
                      setPayload({
                        ...payload,
                        startDate: moment(e)?.toISOString(),
                        endDate:
                          moment(e).startOf("minute").isSame(moment(payload?.endDate).startOf("minute")) || moment(e).isAfter(moment(payload?.endDate))
                            ? null
                            : payload?.endDate,
                      });
                    }}
                  />
                )}
              </div>

              <div className="input-wrapper">
                <label>Крайна дата</label>
                {edit && Object.keys(payload)?.length ? (
                  <Inputs.DatePicker
                    disabled={!payload?.startDate}
                    minDate={payload?.startDate || moment().toDate()}
                    placeholder={moment(payload?.endDate).format("DD.MM.YYYY")}
                    value={payload?.endDate && !isNaN(payload?.endDate?._i) ? moment(payload?.endDate)?._d : null}
                    onChange={(e) => {
                      console.log(e);
                      setPayload({ ...payload, endDate: moment(e).endOf("day").endOf("hour").toDate() });
                    }}
                  />
                ) : (
                  <Inputs.DatePicker
                    placeholder=""
                    disabled={!payload?.startDate}
                    minDate={payload?.startDate}
                    pickDate={payload?.startDate ? payload?.endDate : null}
                    value={payload?.endDate ? moment(payload?.endDate)?._d : null}
                    onChange={(e) => {
                      setPayload({ ...payload, endDate: moment(e).endOf("day").endOf("hour").toDate() });
                    }}
                  />
                )}
              </div>
              <div className="input-wrapper radio">
                <label>Приоритет</label>

                <div className="flex">
                  <RadioButton
                    onClick={() => {
                      setIsSelected("low");
                      setPayload({ ...payload, priority: "low" });
                    }}
                    key="low-priority"
                    id={nanoid()}
                    isSelected={isSelected === "low"}
                    label="Нисък"
                    value="low-priority"
                    color="green"
                  />
                  <RadioButton
                    onClick={() => {
                      setIsSelected("medium");
                      setPayload({ ...payload, priority: "medium" });
                    }}
                    key="medium-priority"
                    id={nanoid()}
                    isSelected={isSelected === "medium"}
                    label="Среден"
                    value="medium-priority"
                    color="yellow"
                  />
                  <RadioButton
                    onClick={() => {
                      setIsSelected("high");
                      setPayload({ ...payload, priority: "high" });
                    }}
                    key="high-priority"
                    id={nanoid()}
                    isSelected={isSelected === "high"}
                    label="Висок"
                    value="high-priority"
                    color="red"
                  />
                </div>
              </div>

              <div className="contractors-section-wrapper" ref={checkListRef}>
                {isComponentVisible ? (
                  <>
                    <Popup
                      trigger={
                        <img
                          src={require("../../../assets/icons/light-plus-icon.svg")}
                          onClick={() => {
                            setIsComponentVisible(true);
                          }}
                        ></img>
                      }
                      className="participants-popUp anvil"
                      position="bottom right"
                      on={"click"}
                    >
                      {(close) => (
                        <CheckListPopup
                          // data={performers}
                          data={filterUsers() || []}
                          handleCheckList={(list) => {
                            let newList = list.slice();
                            newList = newList.map((user, index) => users.find((el) => user?.includes(el?.lastName))._id);
                            setUsersList(list);
                            setPayload({ ...payload, assignedTo: newList });
                          }}
                          onClose={() => close()}
                          addButtonDisabled={true}
                          usersList={usersList}
                        />
                      )}
                    </Popup>
                  </>
                ) : (
                  <div
                    className="plus-icon"
                    onClick={() => {
                      setIsComponentVisible(true);
                    }}
                  />
                )}

                {payload?.assignedTo && (
                  <>
                    {usersList?.map((performer, index) => {
                      return (
                        <div className="single-contractor-img-wrapper" key={`performer-${index}-${performer}`}>
                          <p>{performer}</p>
                          <img
                            src={require("../../../assets/icons/trash.svg")}
                            alt="x"
                            style={{ margin: "0px 10px" }}
                            onClick={() => {
                              let newPayload = { ...payload };
                              let userInfo = users.find((user) => performer?.includes(user?.lastName));

                              if (edit) newPayload.assignedTo = newPayload.assignedTo.filter((el) => el?._id !== userInfo._id);
                              else newPayload.assignedTo = newPayload.assignedTo.filter((el) => el !== userInfo._id);

                              let newUsersList = usersList.slice();
                              if (edit) {
                                newUsersList = newPayload.assignedTo.map(
                                  (el) => users.find((user) => user._id === el?._id).name + " " + users.find((user) => user._id === el?._id).lastName
                                );
                              } else {
                                newUsersList = newPayload.assignedTo.map(
                                  (el) => users.find((user) => user._id === el).name + " " + users.find((user) => user._id === el).lastName
                                );
                              }

                              setPayload(newPayload);
                              setUsersList(newUsersList);
                            }}
                          ></img>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>

          <h3>Кратко описание</h3>
          <div className="task-wrapper-main-bottom">
            {payload?.description && edit && (
              <ReactQuill
                style={{ boxShadow: `0 0 2px #909090`, borderColor: "transparent", width: "100%", height: "37vh", display: !edit ? "none" : "" }}
                value={payload?.description || ""}
                onChange={(e) => { setPayload((payload) => ({ ...payload, description: e })); }}
              />
            )}

            {!edit && (
              <ReactQuill
                style={{ boxShadow: `0 0 2px #909090`, borderColor: "transparent", width: "100%", height: "37vh", display: edit ? "none" : "" }}
                value={payload?.description || ""}
                onChange={(e) => { setPayload((payload) => ({ ...payload, description: e })); }}
              />
            )}
          </div>
        </div>
      </div>
      <ModalComponent
        open={deleteModal.open}
        children={
          <Modals.DeleteModal
            header="Изтриване на задача"
            body={"Сигурни ли сте, че искате да изтриете този елемент?"}
            handleClose={() =>
              setDeleteModal({
                open: false,
                id: "",
              })
            }
            handleSubmit={() => {
              emit(socket, { action: "task/deleteTask", payload: { taskId: deleteModal.id } });
              window.close();
            }}
          />
        }
      />
    </div>
  );
}
