/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { OfficesStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getOfficesEpic = (action$) =>
  action$.pipe(
    ofType(OfficesStats.GET_OFFICES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/office`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            obs.next({
              type: OfficesStats.GET_OFFICES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCurrentOfficeEpic = (action$) =>
  action$.pipe(
    ofType(OfficesStats.GET_CURRENT_OFFICE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/office/${payload.id}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: OfficesStats.GET_CURRENT_OFFICE_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const addOfficeEpic = (action$) =>
  action$.pipe(
    ofType(OfficesStats.ADD_OFFICE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/office`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const deleteOfficeEpic = (action$) =>
  action$.pipe(
    ofType(OfficesStats.DELETE_OFFICE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/office/${payload.id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const editOfficeEpic = (action$) =>
  action$.pipe(
    ofType(OfficesStats.EDIT_OFFICE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/office`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
