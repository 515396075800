/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ArticlesStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getArticlesEpic = (action$) =>
  action$.pipe(
    ofType(ArticlesStats.GET_ARTICLES),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/blog/${payload.page}/${payload.limit}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            obs.next({
              type: ArticlesStats.GET_ARTICLES_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getCurrentArticleEpic = (action$) =>
  action$.pipe(
    ofType(ArticlesStats.GET_CURRENT_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/blog/post/${payload.id}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            obs.next({
              type: ArticlesStats.GET_CURRENT_ARTICLE_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

export const addArticleEpic = (action$) =>
  action$.pipe(
    ofType(ArticlesStats.ADD_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/blog/`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const deleteArticleEpic = (action$) =>
  action$.pipe(
    ofType(ArticlesStats.DELETE_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/blog/${payload.id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const editArticleEpic = (action$) =>
  action$.pipe(
    ofType(ArticlesStats.EDIT_ARTICLE),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/blog/`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
