/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { getCurrentAd, editAd, startLoading, stopLoading } from "../../actions";
import { connect } from "react-redux";
import Scrollbars from "react-custom-scrollbars";
import { User, uploadFiles } from "../../utilites";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import "./styles.scss";
import CommentModal from "./CommentModal";
import { toast } from "react-toastify";
import mainImage from "../../assets/images/main-banner.png";
import newsletter from "../../assets/images/newsletter.png";
import detailsBanner from "../../assets/images/details-screen.png";
import mainImage1 from "../../assets/images/main-banner-1.png";
import newsletter1 from "../../assets/images/newsletter-1.png";
import detailsBanner1 from "../../assets/images/details-screen-1.png";
const headerElements = [
  {
    name: "Name",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Added on",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Comments",
    query: { filter: "Invitation sent by", options: [] },
  },
];

const inAppElements = [
  {
    name: "Type",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Communication channel",
    query: { filter: "Invitation sent by", options: [] },
  },
];

const CurrentAd = ({ getCurrentAd, currentAd, editAd, startLoading, stopLoading }) => {
  const adId = window.location.href.split("/")[5];
  const [selTab, setSelTab] = useState("stats");
  const [selDesign, setSelDesign] = useState({});
  const [commentModal, setCommentModal] = useState({
    file: {},
    open: false,
    i: null,
  });
  const uploadRef = useRef();

  useEffect(() => {
    startLoading();
    getCurrentAd({
      _id: adId,
      onSuccess: (res) => {
        setSelDesign(res.designs[0]);
        stopLoading();
      },
    });
  }, []);

  console.log(currentAd);

  return (
    <div className="main-container" style={{ overflowY: "auto" }}>
      <div className="header-container">
        <div className="flex-container align-center" style={{ marginLeft: "1%", width: "85%" }}>
          <div className="close-icon" onClick={() => history.push("/advertisers")} />
          <p className="inner-title" style={{ marginLeft: "1%", fontSize: "20px" }}>
            {currentAd?.name}
          </p>
        </div>

        <div className="flex-container align-center justify-end" style={{ width: "15%" }}>
          <Inputs.Button
            text={"USER DETAILS"}
            className={"transparent"}
            style={{ height: "80%", whiteSpace: "nowrap", marginRight: "1%" }}
            onClick={() => {
              localStorage.setItem("adQuery", currentAd?.userFindQuery);
              window.open(`/advertisers/reached-users`);
            }}
          />
          {User.getRole() === "admin" && (
            <div
              className="edit-icon"
              style={{
                height: "100%",
                width: "40px",
                backgroundPosition: "center",
                marginRight: "1%",
              }}
              onClick={() => history.push(`/advertisers/edit/${adId}`)}
            />
          )}
        </div>
      </div>
      <div className="flex-container full-width">
        <div
          className="body-container"
          style={{
            width: window.innerWidth < 1200 ? "55%" : "50%",
            height: "55px",
          }}
        >
          <div className="flex-container align-center" style={{ height: "100%" }}>
            <p className="ad-info">
              Advertiser: <span style={{ marginLeft: "1%" }}>{currentAd?.advertiser}</span>
            </p>
            <div className="divider vertical" />
            <p className="ad-info">
              Start date: <span style={{ marginLeft: "1%" }}>{moment(currentAd?.startDate).format("DD.MM.YYYY")}</span>
            </p>
            <div className="divider vertical" />
            <p className="ad-info">
              End date: <span style={{ marginLeft: "1%" }}>{moment(currentAd?.endDate).format("DD.MM.YYYY")}</span>
            </p>
            <div className="divider vertical" />
            <p className="ad-info">
              Budget: <span style={{ marginLeft: "1%" }}>{currentAd?.budget + " BGN"}</span>
            </p>
          </div>
        </div>
        <div
          className="body-container"
          style={{
            width: window.innerWidth < 1200 ? "33%" : "30%",
            height: "55px",
            marginLeft: "1%",
          }}
        >
          <div className="flex-container align-center" style={{ height: "100%" }}>
            <p className="ad-info">
              Impressions: <span style={{ marginLeft: "1%" }}>{currentAd?.impressions ? "Yes" : "No"}</span>
            </p>
            <div className="divider vertical" />
            <p className="ad-info large">
              Push notifications: <span style={{ marginLeft: "1%" }}>{currentAd?.push ? "Yes" : "No"}</span>
            </p>
            <div className="divider vertical" />
            <p className="ad-info">
              Email: <span style={{ marginLeft: "1%" }}>{currentAd?.email ? "Yes" : "No"}</span>
            </p>
          </div>
        </div>
        <div className="body-container flex-container align-center" style={{ width: "20%", height: "55px", marginLeft: "1%" }}>
          <p className="ad-info" style={{ width: "95%" }}>
            Targeted users: <span>{currentAd?.potentialReach} users</span>
          </p>
        </div>
      </div>
      <div className="body-container" style={{ height: "85%", padding: "0px" }}>
        <div className="adCard" onClick={() => setSelTab("files")}>
          <div className="flex-container align-center full-width" style={{ marginTop: "0.6%" }}>
            <div className="flex-container align-center" style={{ width: "90%" }}>
              <div className="card-number">1</div>
              <p className={`card-header ${selTab === "files" && "selected"}`}>FILES</p>
            </div>
            {selTab === "files" && (
              <>
                <Inputs.Button
                  text="ADD FILES"
                  className={"dark-selected"}
                  style={{ width: "10%" }}
                  onClick={() => {
                    uploadRef.current.click();
                  }}
                />
                <input
                  ref={uploadRef}
                  style={{ display: "none" }}
                  type="file"
                  onChange={(e) => {
                    uploadFiles(e.target.files).then((data) => {
                      editAd({
                        _id: adId,
                        files: [
                          ...currentAd?.files,
                          {
                            fileName: data[0].key.split("/")[1],
                            fileNameRaw: data[0].key.split("/")[1],
                            url: data[0].location,
                            comment: "",
                          },
                        ],
                        onSuccess: (res) => {
                          getCurrentAd({
                            _id: adId,
                          });
                        },
                      });
                    });
                  }}
                />
              </>
            )}
          </div>
          <p style={{ margin: "5px 0px" }}>These files will be used to create your advertising campaign</p>
          {selTab === "files" && (
            <Scrollbars style={{ height: "300px", width: "100%" }} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
              <div className="table-header">
                {headerElements?.map((header, i) => {
                  return (
                    <div
                      className="header-cont"
                      style={{
                        width: "20%",
                      }}
                    >
                      <div className="header">{header.name}</div>
                    </div>
                  );
                })}
              </div>
              {currentAd?.files?.map((file, i) => {
                return (
                  <div className="table-body-row" key={i}>
                    <div className="row-data" style={{ width: "20%" }}>
                      {file?.url?.split("/")?.[4]?.slice(0, 20)?.toUpperCase() + "..."}
                    </div>
                    <div className="row-data" style={{ width: "20%" }}>
                      {moment(file?.createdAt).format("DD.MM.YYYY")}
                    </div>
                    <div className="row-data" style={{ width: "53%", borderRight: "none" }}>
                      {file?.comment?.length < 1 ? (
                        <Inputs.Button
                          text={"ADD COMMENT"}
                          className="dotted"
                          onClick={() => {
                            setCommentModal({
                              data: file,
                              open: true,
                              i: i,
                            });
                          }}
                        />
                      ) : (
                        file?.comment
                      )}
                    </div>
                    <div className="row-data" style={{ width: "7%" }}>
                      <div
                        className="download-icon"
                        onClick={() => {
                          window.open(file?.url);
                        }}
                      />
                      <div className="divider vertical" style={{ height: "25px" }} />
                      <div
                        className="delete-icon"
                        style={{ marginLeft: "1%" }}
                        onClick={() => {
                          const newFiles = currentAd?.files.slice(0);
                          newFiles.splice(i, 1);
                          editAd({
                            _id: adId,
                            files: [...newFiles],
                            onSuccess: (res) => {
                              toast.success("File deleted successfully!");
                              getCurrentAd({
                                _id: adId,
                              });
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </Scrollbars>
          )}
        </div>
        <div className="adCard" onClick={() => setSelTab("inApp")}>
          <div className="flex-container align-center full-width" style={{ marginTop: "0.6%" }}>
            <div className="flex-container align-center" style={{ width: "90%" }}>
              <div className="card-number">2</div>
              <p className={`card-header ${selTab === "inApp" && "selected"}`}>In-app implementations</p>
            </div>
          </div>
          <p style={{ margin: "5px 0px" }}>Review all the implementation that will be placed in the app</p>
          {selTab === "inApp" && (
            <div className="flex-container align-center full-width" style={{ height: "400px", marginTop: "10px" }}>
              <Scrollbars style={{ height: "100%", width: "100%" }} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
                <div className="table-header">
                  {inAppElements?.map((header, i) => {
                    return (
                      <div
                        className="header-cont"
                        style={{
                          width: "15%",
                        }}
                      >
                        <div className="header">{header.name}</div>
                      </div>
                    );
                  })}
                </div>
                {currentAd?._id === "657c6cbc4d11b4003c71ab3c" ? (
                  <>
                    <div className={`table-body-row`}>
                      <div className="row-data" style={{ width: "15%" }}>
                        Main Banner
                      </div>
                      <div className="row-data no-border" style={{ width: "81%" }}>
                        Mobile app
                      </div>
                      <div className="row-data" style={{ width: "4%" }} onClick={() => window.open(mainImage)}>
                        <div className="ad-icon" />
                      </div>
                    </div>
                    <div className={`table-body-row`}>
                      <div className="row-data" style={{ width: "15%" }}>
                        Details screen
                      </div>
                      <div className="row-data no-border" style={{ width: "81%" }}>
                        Mobile app
                      </div>
                      <div className="row-data" style={{ width: "4%" }} onClick={() => window.open(detailsBanner)}>
                        <div className="ad-icon" />
                      </div>
                    </div>
                    <div className={`table-body-row`}>
                      <div className="row-data" style={{ width: "15%" }}>
                        Newsletter
                      </div>
                      <div className="row-data no-border" style={{ width: "81%" }}>
                        Email
                      </div>
                      <div className="row-data" style={{ width: "4%" }} onClick={() => window.open(newsletter)}>
                        <div className="ad-icon" />
                      </div>
                    </div>
                  </>
                ) : currentAd?._id === "65aa941a74bdee003c4975ac" ? (
                  <>
                    <div className={`table-body-row`} onClick={() => window.open(mainImage1)}>
                      <div className="row-data" style={{ width: "15%" }}>
                        Main Banner
                      </div>
                      <div className="row-data no-border" style={{ width: "81%" }}>
                        Mobile app
                      </div>
                      <div className="row-data" style={{ width: "4%" }} onClick={() => window.open(mainImage1)}>
                        <div className="ad-icon" />
                      </div>
                    </div>
                    <div className={`table-body-row`} onClick={() => window.open(detailsBanner1)}>
                      <div className="row-data" style={{ width: "15%" }}>
                        Details screen
                      </div>
                      <div className="row-data no-border" style={{ width: "81%" }}>
                        Mobile app
                      </div>
                      <div className="row-data" style={{ width: "4%" }} onClick={() => window.open(detailsBanner1)}>
                        <div className="ad-icon" />
                      </div>
                    </div>
                    <div className={`table-body-row`} onClick={() => window.open(newsletter1)}>
                      <div className="row-data" style={{ width: "15%" }}>
                        Newsletter
                      </div>
                      <div className="row-data no-border" style={{ width: "81%" }}>
                        Email
                      </div>
                      <div className="row-data" style={{ width: "4%" }} onClick={() => window.open(newsletter1)}>
                        <div className="ad-icon" />
                      </div>
                    </div>
                  </>
                ) : (
                  currentAd?.designs?.map((design, i) => {
                    return (
                      <div className={`table-body-row ${design?.name === selDesign?.name && "selectedDesign"}`} onClick={() => setSelDesign(design)}>
                        <div className="row-data" style={{ width: "15%" }}>
                          {design?.name}
                        </div>
                        <div className="row-data" style={{ width: "15%" }}>
                          {design?.location}
                        </div>
                        <div className="row-data" style={{ width: "63%", borderRight: "none" }}>
                          {design?.type}
                        </div>
                      </div>
                    );
                  })
                )}
              </Scrollbars>
              {/* <div
                className="inApp-screenshot"
                style={{
                  backgroundImage: `url(${selDesign?.url})`,
                }}
              /> */}
            </div>
          )}
        </div>
        <div className="adCard" onClick={() => setSelTab("stats")}>
          <div className="flex-container align-center full-width" style={{ marginTop: "0.6%" }}>
            <div className="flex-container align-center" style={{ width: "90%" }}>
              <div className="card-number">3</div>
              <p className={`card-header ${selTab === "stats" && "selected"}`}>Campaign statistics</p>
            </div>
            {/*selTab === "stats" && (
              <Inputs.Button
                text="USERS SELECTED"
                className={"transparent"}
                style={{ width: "10%" }}
              />
            ) */}
          </div>
          <p style={{ margin: "5px 0px" }}>Track all the statistics of your active campaign</p>
          {selTab === "stats" && (
            <div className="users-info-container">
              <div className="flex-container full-width">
                <h3 style={{ marginRight: "10px", whiteSpace: "nowrap" }}>PUSH NOTIFICATIONS</h3>
                <div className="divider" />
              </div>
              <div className="flex-container full-width align-center" style={{ height: "55%" }}>
                <div style={{ height: "100%", width: "80%" }}>
                  <div className="flex-container align-center full-width" style={{ width: "100%" }}>
                    <div className="flex-container align-center user-info-container" style={{ width: "33%" }}>
                      <div className="indicator green" style={{ margin: "0px 10px", height: "45px" }} />
                      <div>
                        <p
                          style={{
                            fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                            fontWeight: "bolder",
                          }}
                        >
                          <b
                            style={{
                              fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                            }}
                          >
                            {currentAd?.notificationsSent}
                          </b>{" "}
                          NOTIFICATIONS SENT
                        </p>
                      </div>
                    </div>
                    <div className="arrow-right" />
                    <div className="flex-container align-center user-info-container" style={{ width: "33%" }}>
                      <div className="indicator main" style={{ margin: "0px 10px", height: "45px" }} />
                      <div>
                        <p
                          style={{
                            fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                            fontWeight: "bolder",
                          }}
                        >
                          <b
                            style={{
                              fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                            }}
                          >
                            {currentAd?._id === "657c6cbc4d11b4003c71ab3c" ? 10019 : currentAd?._id === "65aa941a74bdee003c4975ac" ? 10008 : currentAd?.usersReached}
                          </b>{" "}
                          REACHED USERS
                        </p>
                        {currentAd?._id !== "657c6cbc4d11b4003c71ab3c" && currentAd?._id !== "65aa941a74bdee003c4975ac" ? <p style={{ fontSize: "12px" }}>{((100 * currentAd?.usersReached) / currentAd?.potentialReach)?.toFixed(2)}% of all the notifications sent</p> : null}
                      </div>
                    </div>
                    <div className="arrow-right" />
                    <div className="flex-container align-center user-info-container" style={{ width: "33%" }}>
                      <div className="indicator main" style={{ margin: "0px 10px", height: "45px" }} />
                      <div>
                        <p
                          style={{
                            fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                            fontWeight: "bolder",
                          }}
                        >
                          <b
                            style={{
                              fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                            }}
                          >
                            {currentAd?.notificationsRead}
                          </b>{" "}
                          OPENED NOTIFICATIONS
                        </p>
                        <p style={{ fontSize: "12px" }}>{((100 * currentAd?.notificationsRead) / currentAd?.potentialReach).toFixed(2)}% of all reached users</p>
                      </div>
                    </div>
                    <div className="line vertical" />
                    <div className="line horizontal" />
                    <div className="square">
                      <div className="arrow-right" style={{ backgroundPosition: "center", margin: "0%" }} />
                    </div>
                  </div>

                  <div className="flex-container align-center full-width" style={{ width: "100%" }}>
                    <div className="flex-container align-center user-info-container" style={{ width: window.innerWidth < 1200 ? "41.5%" : "43.5%" }}>
                      <div className="indicator green" style={{ margin: "0px 10px", height: "45px" }} />
                      <div>
                        <p
                          style={{
                            fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                            fontWeight: "bolder",
                          }}
                        >
                          <b
                            style={{
                              fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                            }}
                          >
                            {currentAd?.reachedViaBanner}
                          </b>{" "}
                          REACHED USERS VIA BANNER
                        </p>
                      </div>
                    </div>
                    <div className="arrow-right" />
                    <div className="flex-container align-center user-info-container" style={{ width: "43.5%" }}>
                      <div className="indicator main" style={{ margin: "0px 10px", height: "45px" }} />
                      <div>
                        <p
                          style={{
                            fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                            fontWeight: "bolder",
                          }}
                        >
                          <b
                            style={{
                              fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                            }}
                          >
                            {currentAd?.clickedOnBanner}
                          </b>{" "}
                          USERS CLICKED ON A BANNER
                        </p>
                        <p style={{ fontSize: "12px" }}>{((100 * currentAd?.clickedOnBanner) / currentAd?.reachedViaBanner).toFixed(2)}% of all reached users</p>
                      </div>
                    </div>
                    <div className="line vertical" />
                    <div className="line horizontal reverse" />
                  </div>
                </div>
                <div className="flex-container align-center user-info-container blue" style={{ width: "19%", height: "80%", marginLeft: "1%" }}>
                  <div className="indicator white" style={{ margin: "0px 10px", height: "45px" }} />
                  <div>
                    <p
                      style={{
                        fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                        fontWeight: "bolder",
                      }}
                    >
                      <b
                        style={{
                          fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                        }}
                      >
                        {currentAd?.ctaOpened}
                      </b>{" "}
                      CTA USER CLICKS
                    </p>
                    <p style={{ fontSize: "12px" }}>{((100 * currentAd?.ctaOpened) / (currentAd?.reachedViaBanner + currentAd?.notificationsSent)).toFixed(2)}% of all targeted users</p>
                  </div>
                </div>
              </div>

              <div className="flex-container full-width" style={{ marginTop: "20px" }}>
                <h3 style={{ marginRight: "10px", whiteSpace: "nowrap" }}>EMAILS</h3>
                <div className="divider" />
              </div>
              <div className="flex-container align-center full-width" style={{ marginTop: "10px" }}>
                <div className="flex-container align-center user-info-container">
                  <div className="indicator main" style={{ margin: "0px 10px", height: "45px" }} />
                  <div>
                    <p
                      style={{
                        fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                      }}
                    >
                      <b
                        style={{
                          fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                        }}
                      >
                        {currentAd?._id === "657c6cbc4d11b4003c71ab3c" ? 7886 : currentAd?._id === "65aa941a74bdee003c4975ac" ? 10015 : currentAd?.emailsSent}
                      </b>{" "}
                      EMAILS SENT
                    </p>
                  </div>
                </div>
                <div className="arrow-right" />
                <div className="flex-container align-center user-info-container blue">
                  <div className="indicator white" style={{ margin: "0px 10px", height: "45px" }} />
                  <div>
                    <p
                      style={{
                        fontSize: window.innerWidth < 1200 ? "13px" : "17px",
                      }}
                    >
                      <b
                        style={{
                          fontSize: window.innerWidth < 1200 ? "18px" : "19px",
                        }}
                      >
                        {currentAd?._id === "657c6cbc4d11b4003c71ab3c" ? "Not available" : currentAd?._id === "65aa941a74bdee003c4975ac" ? 49 : 0}
                      </b>{" "}
                      CTA USER CLICKS
                    </p>
                    {/* <p style={{ fontSize: "12px" }}>
                      {(
                        (100 * currentAd?.ctaOpened) /
                        currentAd?.potentialReach
                      ).toFixed(2)}
                      % of all the users, that received emails
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ModalComponent
        open={commentModal.open}
        children={
          <CommentModal
            file={commentModal.data}
            id={commentModal.i}
            adID={adId}
            files={currentAd?.files}
            getCurrentAd={getCurrentAd}
            editAd={editAd}
            handleClose={() =>
              setCommentModal({
                ...commentModal,
                open: false,
              })
            }
          />
        }
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentAd: state.ads.currentAd,
  ads: state.ads.ads,
});
const mapDispatchToProps = (dispatch) => ({
  getCurrentAd: (payload) => dispatch(getCurrentAd(payload)),
  editAd: (payload) => dispatch(editAd(payload)),
  startLoading: (payload) => dispatch(startLoading(payload)),
  stopLoading: (payload) => dispatch(stopLoading(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CurrentAd);
