import { ArticlesStats } from "../actions";

const initialState = {
  articles: {
    totalPages: 0,
    docs: [],
  },
  article: {},
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.data;
  const _docs = payload.data.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const articles = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case ArticlesStats.GET_ARTICLES_SUCCESS:
      return {
        ...state,
        articles: {
          totalPages: payload.totalPages,
          docs: handleNewDocs(state, payload, "articles"),
        },
      };
    case ArticlesStats.GET_CURRENT_ARTICLE_SUCCESS:
      return {
        ...state,
        article: payload,
      };
    default:
      return state;
  }
};

export default articles;
