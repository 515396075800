import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import starter from "./starter";
import dashboard from "./dashboard";
import app from "./app";
import articles from "./articles";
import offices from "./offices";
import finances from "./finances";
import login from "./auth";
import ads from "./ads";
import discounts from "./discounts";
import tasks from "./tasks";

export default combineReducers({
  starter,
  dashboard,
  app,
  articles,
  offices,
  finances,
  login,
  ads,
  discounts,
  tasks,
  routerReducer,
});
