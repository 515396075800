/* eslint-disable */
import moment from "moment";
import React, { useState, useRef } from "react";
import { history } from "../../../config/stores";
import Scrollbars from "react-custom-scrollbars";
import "../styles.scss";

const ArticlesGrid = ({ getArticles, articles }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && articles.totalPages >= curPage) {
      tableRef.current.scrollTop(20);
      getArticles({
        page: curPage,
        limit: 20,
      });
      setCurPage(curPage + 1);
    }
  };

  return (
    <Scrollbars
      style={{ height: "95.5%", width: "100%", marginTop: "1%" }}
      onUpdate={handleUpdate}
      ref={tableRef}
      id={"scrollbar"}
      renderView={(props) => (
        <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
      )}
    >
      {articles?.docs?.map((article, i) => {
        return (
          <div
            className="table-body-row flex-container align-center space-between pointer"
            style={{ height: "65px" }}
            key={i}
            onClick={() => history.push(`/article/edit/${article?._id}`)}
          >
            <div className="flex-container align-center">
              <div
                className="article-image"
                style={{ backgroundImage: `url(${article?.coverPhoto})` }}
              />
              <h3>{article?.title}</h3>
            </div>
            <h4 style={{ marginRight: "2%" }}>
              {moment(article?.createdAt).format("DD.MM.YYYY")}
            </h4>
          </div>
        );
      })}
    </Scrollbars>
  );
};

export default ArticlesGrid;
