import React from "react";
import "./styles.scss";
import { forwardRef } from "react";
import ReactQuill from "react-quill";
const RichText = forwardRef(
  (
    { label, compulsory, className, style, allTools, value, edit, ...props },
    ref
  ) => {
    const defaultEditorStyles = {
      margin: "10px 0",
      borderColor: "rgb(165, 164, 164)",
      borderRadius: "6px",
      padding: "1px",
      fontSize: "18px",
      width: "100%",
      height: "100%",
    };

    return (
      <div className="input-container" style={style}>
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label}{" "}
          {compulsory && (
            <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
          )}
        </label>

        {edit ? (
          value && (
            <ReactQuill
              style={
                className
                  ? { ...defaultEditorStyles, borderColor: "red" }
                  : defaultEditorStyles
              }
              value={value}
              {...(!allTools && { modules: { toolbar: ['link'] } })}
              {...props}
              ref={ref}
            />
          )
        ) : (
          <ReactQuill
            style={
              className
                ? { ...defaultEditorStyles, borderColor: "red" }
                : defaultEditorStyles
            }
            {...(!allTools && { modules: { toolbar: ['link'] } })}
            {...props}
            ref={ref}
          />
        )}
      </div>
    );
  }
);
export default RichText;
