import * as XLSX from 'xlsx'
import { startLoading, stopLoading } from '../actions';
import { store } from "../config/stores";
import moment from 'moment'
import httpClient from './httpClient';
import { getPeriod } from './HelperFunctions';
import { capitalize, sortBy } from 'lodash';

export const exportDashboardStatistics = async (period) => {
    store.dispatch(startLoading())
    const wb = XLSX.utils.book_new()

    const downloads = new Promise(async (resolve, reject) => {
        try {
            const response = await httpClient.post(`/statsAdmin/totalDownloads`, { ...getPeriod(period) })
            const payload = response.data.payload

            const aoa = [['Total', '', 'Operation System', 'Downloads'], ...payload.map(({ operationSystem, totalDownloads }) => ['', '', operationSystem, totalDownloads])]
            console.log(payload)
            aoa[1][0] = payload.reduce((acc, { totalDownloads }) => acc + Number(totalDownloads), 0)

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'Total Downloads' })
        } catch (error) { return reject(error) }
    })

    const activeUsers = new Promise(async (resolve, reject) => {
        try {
            const response = await httpClient.post(`/statsAdmin/activeUsers`, { ...getPeriod(period) })
            const { fastChecks, newlyRegistered, verified } = response.data.payload //activeAppSessions, totalUsers

            const aoa = [['Newly Registered', 'Fast Check', 'Verified Profiles'], [newlyRegistered, fastChecks, verified]]

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'Active Users' })
        } catch (error) { return reject(error) }
    })

    const transactionsIncomeRevenue = new Promise(async (resolve, reject) => {
        try {
            const response = await httpClient.post(`/statsAdmin/transactionsIncomeRevenue`, { ...getPeriod(period) })
            const { income, byStream, byTransactions, byAmountSpent, byChannel } = response.data.payload

            const aoa = [['Income', '', 'By stream', '', '', 'Transactions', '', '', 'Amount spend', '', '', 'Chanel'], [income]];
            [byStream, byTransactions, byAmountSpent, byChannel].forEach((obj, index) =>
                Object.entries(obj).filter(([key]) => keyMap[key]).sort((a, b) => b[1] - a[1]).forEach(([key, value], i) => {
                    if (!aoa[i + 1]) aoa[i + 1] = []
                    aoa[i + 1][2 + (index * 3)] = keyMap[key];
                    aoa[i + 1][3 + (index * 3)] = value;
                })
            )

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'Income and Revenue' })
        } catch (error) { return reject(error) }
    })

    const sessions = new Promise(async (resolve, reject) => {
        try {
            const response = await httpClient.post(`/statsAdmin/appSessions`, { ...getPeriod(period) })
            const { total, unique, avg, byDuration, timesOpened, } = response.data.payload

            const aoa = [['Total in app sessions', total, '', 'Sessions length', '', '', 'Times opened'], ['Unique active users', unique], ['Average user sessions', avg]];
            [byDuration, timesOpened,].forEach((obj, index) => Object.entries(obj).forEach(([key, value], i) => {
                if (!aoa[i + 1]) aoa[i + 1] = []
                aoa[i + 1][3 + (index * 3)] = key;
                aoa[i + 1][4 + (index * 3)] = value;
            }))

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'App Sessions' })
        } catch (error) { return reject(error) }
    })

    const users = new Promise(async (resolve, reject) => {
        try {
            const usersResponse = await httpClient.post(`/statsAdmin/users`, { ...getPeriod(period) })
            const { total, byVerification, byAccountType, byAgeGroup, byLocation, byOS, byGender, } = usersResponse.data.payload

            const vehicleResponse = await httpClient.post(`/statsAdmin/vehiclesAdded`, { ...getPeriod(period) })
            const vehiclePayload = vehicleResponse.data.payload

            const licensesResponse = await httpClient.post(`/statsAdmin/drivingLicensesAdded`, { ...getPeriod(period) })
            const licensesPayload = licensesResponse.data.payload

            const aoa = [['Registered users', total, '', 'OS', '', '', 'Gender', '', '', 'User verification', '', '', 'Premium users', '', '', 'Added vehicles', '', '', "Added driver's licenses", '', '', 'Age group', '', '', 'Location']];
            [byOS, byGender, byVerification, byAccountType, vehiclePayload, licensesPayload, byAgeGroup, byLocation,].forEach((obj, index) => Object.entries(obj).forEach(([key, value], i) => {
                if (!aoa[i + 1]) aoa[i + 1] = []
                aoa[i + 1][3 + (index * 3)] = capitalize(key);
                aoa[i + 1][4 + (index * 3)] = value;
            }))

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'User Profiles' })
        } catch (error) { return reject(error) }
    })

    const premium = new Promise(async (resolve, reject) => {
        try {
            const activeResponse = await httpClient.post(`/statsAdmin/activeSubscriptions`)
            const activePayload = activeResponse.data.payload
            const { subscriptions: activeIOS } = activePayload.find(({ source }) => source === 'iOS')
            const { subscriptions: activeAndroid } = activePayload.find(({ source }) => source === 'android')

            const newResponse = await httpClient.post(`/statsAdmin/newSubscriptions`, { ...getPeriod(period) })
            const newPayload = newResponse.data.payload
            const { subscriptions: newIOS } = newPayload.find(({ source }) => source === 'iOS')
            const { subscriptions: newAndroid } = newPayload.find(({ source }) => source === 'android')

            const aoa = [
                ['Total', '', 'Active iOS', '', '', 'Active android', '', '', 'New active iOS', '', '', 'New active android'],
                [[...activeIOS, ...activeAndroid].reduce((acc, { userCount }) => acc + userCount, 0), '',]
            ];
            [activeIOS, activeAndroid, newIOS, newAndroid].forEach((obj, index) => obj.map(Object.values).forEach(([key, value], i) => {
                if (!aoa[i + 1]) aoa[i + 1] = []
                aoa[i + 1][2 + (index * 3)] = [].includes(key) ? capitalize(key) : key;
                aoa[i + 1][3 + (index * 3)] = value;
            })
            )

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'Premium User Profiles' })
        } catch (error) { return reject(error) }
    })

    const vehicles = new Promise(async (resolve, reject) => {
        try {
            const response = await httpClient.post(`/statsAdmin/vehicles`, { ...getPeriod(period) })
            const { total, byBrand, byRegion, byUsers } = response.data.payload

            const aoa = [['Added in the app', total, '', 'Brand', '', '', 'Registration plate', '', '', 'Vehicles shared between users']];
            [byBrand, byRegion, byUsers].forEach((obj, index) => Object.entries(obj).forEach(([key, value], i) => {
                if (!aoa[i + 1]) aoa[i + 1] = []
                aoa[i + 1][3 + (index * 3)] = key;
                aoa[i + 1][4 + (index * 3)] = value;
            }))

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'Vehicles' })
        } catch (error) { return reject(error) }
    })

    const growth = new Promise(async (resolve, reject) => {
        try {
            const responseGrowth = await httpClient.post(`/statsAdmin/appGrowth`, { ...getPeriod(period) })
            const { userSignup, vehiclesAdded, } = responseGrowth.data.payload

            const responseDownloads = await httpClient.post(`/statsAdmin/downloadsByDay`, { ...getPeriod(period) })
            const downloads = responseDownloads.data.payload

            const aoa = [
                ['', 'App download', 'User signups', 'Newly added vehicles'],
                ['Total', ...[downloads, userSignup, vehiclesAdded].map((obj) => Object.values(obj).reduce((acc, cur) => acc + cur, 0))],
                []
            ];
            Object.entries(downloads).forEach(([key, value], i) => {
                if (!aoa[i + 3]) aoa[i + 3] = []
                aoa[i + 3][0] = key;
                aoa[i + 3][1] = value;
            })
            Object.values(userSignup).forEach((value, i) => aoa[i + 3][2] = value)
            Object.values(vehiclesAdded).forEach((value, i) => aoa[i + 3][3] = value)

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'App growth' })
        } catch (error) { return reject(error) }
    })

    const reviews = new Promise(async (resolve, reject) => {
        try {

            const responseRatings = await httpClient.post('/statsAdmin/ratings')
            const responseReviewsIOS = await httpClient.post('/statsAdmin/reviews/iOS')
            const responseReviewsAndroid = await httpClient.post('/statsAdmin/reviews/Android')

            const reviews = sortBy([...responseReviewsIOS.data.payload.reviews, ...responseReviewsAndroid.data.payload.reviews], 'date').reverse()

            const aoa = [
                ['', 'Rating', 'Total reviews', '', 'Име', 'Заглавие', 'Текст', 'Дата', 'Рейтинг', 'ОС'],
                ['Android', responseRatings.data.payload.Android.rating, responseRatings.data.payload.Android.totalRatings],
                ['iOS', responseRatings.data.payload.iOS.rating, responseRatings.data.payload.iOS.totalRatings]
            ]

            reviews.forEach((review, index) => ['author', 'title', 'text', 'date', 'rating', 'source',].forEach((field, i) => {
                if (!aoa[index + 1]) aoa[index + 1] = []
                aoa[index + 1][4 + i] = review[field]
            }))

            return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: 'Reviews' })
        } catch (error) { return reject(error) }
    })

    try {
        const sheets = await Promise.all([downloads, activeUsers, transactionsIncomeRevenue, sessions, users, premium, vehicles, growth, reviews])
        sheets.forEach(({ sheet, sheetName }) => XLSX.utils.book_append_sheet(wb, sheet, sheetName))
        XLSX.writeFile(wb, `statistics-${moment().format('DD-MM-YYYY')}.xlsx`)
        store.dispatch(stopLoading())
    } catch (error) { console.error(error) }
}

const keyMap = {

    "MultipleInsurancePolicyInstallmentsIncome": "Insurance installments",
    "VignetteIncome": "Vignettes",
    "FineIncome": "Tickets and fines",
    "VignetteFineIncome": "Vignette fines",
    "CustomerSupportPaymentIncome": "Customer support payment",
    "OrderedInsurancePolicyIncome": "Insurances",
    "AppraisalIncome": "Appraisal",
    "SubscriptionIncome": "Subscriptions",
    "GeoWashIncome": "Geo wash - car wash",

    "1": "Completed",
    "2": "Incomplete",
    "1-100": "1-100",
    "101-500": "101-500",
    "501-1000": "501-1000",
    "card": "Card",
    "delivery": "Delivery",
    "mobileStore": "Mobile store",

}