import React from 'react'
import Inputs from "../../components/Inputs";
import { useState } from "react"
import "./styles.scss"

export const periods = [
    { label: 'Last 24 hours', value: 'day' },
    { label: 'Last 7 days', value: 'week' },
    { label: 'Last 31 days', value: 'month' },
    { label: 'Last 6 months', value: 'six-months' },
    { label: 'Last 12 months', value: 'twelve-months' },
    { label: 'All', value: 'all' },
]


const ExportOptions = ({ close, handleExport }) => {
    const [selected, setSelected] = useState(periods[0].value)
    return <div className="popup-export-options-container">
        <h2>Choose period</h2>
        <Inputs.RadioButtons
            value={selected}
            onChange={setSelected}
            options={periods}
            column
        />
        <Inputs.Button text="EXPORT" onClick={() => handleExport(selected)} />
    </div>
}

export default ExportOptions