/* eslint-disable */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { getDashCam } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";
import { min } from "lodash";
import { useQuery } from "../../../utilites/helpers";
import FiltersPopup from "./FiltersPopup";
import Popup from "reactjs-popup";
import "./styles.scss";

const headerElements = [
  {
    name: "User",
    query: { key: "uploaded", type: "checkboxes", options: null },
  },
  {
    name: "Phone",
    query: { key: "uploaded", type: "checkboxes", options: null },
  },
  {
    name: "Session start date",
    query: { key: "uploaded", type: "checkboxes", options: null },
  },
  {
    name: "Session length",
    query: { key: "uploaded", type: "checkboxes", options: null },
  },
  {
    name: "Session highlights",
    query: { key: "uploaded", type: "checkboxes", options: null },
  },
  {
    name: "Uploaded highlights",
    query: {
      key: "uploaded",
      type: "checkboxes",
      options: [
        { label: "Да", value: "yes" },
        { label: "Не", value: "no" },
      ],
    },
  },
  {
    name: "Session reports",
    query: {
      key: "reported",
      type: "checkboxes",
      options: [
        { label: "Да", value: "yes" },
        { label: "Не", value: "no" },
      ],
    },
  },
];

const Dash = ({ getDashCam, dashcam, period, loading, setLoading }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const timerRef = useRef(null);
  const [innerLoading, setInnerLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const filter = useMemo(() => filters, [filters]);

  useEffect(() => {
    getDashCam({
      pageNumber: 1,
      limit: 20,
      startFrom: period.createdAtFrom,
      startTo: period.createdAtTo,
      onSuccess: (res) => {
        setLoading(false);
      },
    });
  }, []);

  const mappedFilter = useMemo(() => {
    const { uploaded, reported, ...payload } = filter;
    if (uploaded) {
      if (uploaded?.length > 1) payload.uploaded = undefined;
      else payload.uploaded = uploaded?.[0] === "yes" ? true : false;
    }
    if (reported) {
      if (reported?.length > 1) payload.reported = undefined;
      else payload.reported = reported?.[0] === "yes" ? true : false;
    }

    return payload;
  }, [filter]);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && dashcam.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      if (period.createdAtFrom || period.createdAtTo) {
        getDashCam({
          pageNumber: curPage,
          limit: 20,
          startFrom: period.createdAtFrom,
          startTo: period.createdAtTo,
          ...mappedFilter,
          onSuccess: (res) => setInnerLoading(false),
        });
      } else
        getDashCam({
          pageNumber: curPage,
          limit: 20,
          ...mappedFilter,
          onSuccess: (res) => setInnerLoading(false),
        });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    getDashCam({
      pageNumber: 1,
      limit: 20,
      startFrom: period.createdAtFrom,
      startTo: period.createdAtTo,
      ...mappedFilter,
      onSuccess: (res) => {
        setLoading(false);
      },
    });
  }, [fetch, mappedFilter, period, setCurPage]);

  useEffect(() => {
    const timer = timerRef.current;
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  const handleUpdateDebounced = (...payload) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => handleUpdate(...payload), 100);
  };

  const handleDuration = (duration) => {
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    return `${hours} h. | ${minutes} min. | ${seconds} sec.`;
  };

  return (
    <>
      {loading === true ? (
        <div style={{ height: "90%" }}>{<Statistics.LoaderInline center />}</div>
      ) : (
        <>
          <h2 style={{ marginBottom: "20px", textTransform: "uppercase" }}>users with dashcam sessions</h2>
          <Scrollbars style={{ height: "92%", width: "100%" }} onUpdate={handleUpdateDebounced} ref={tableRef} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                    {header.query.options && (
                      <Popup trigger={<div className={`page-arrow-icon min-w-[16px] h-4 w-4 ml-[6px]`} />} position={i === 0 ? "bottom left" : i === header.length - 2 ? "bottom right" : "bottom center"} className="anvil" contentStyle={{ width: 300, padding: 0 }}>
                        {(close) => <FiltersPopup close={close} filterOptions={header.query} filter={filter} handleSubmit={(filter) => setFilters(filter)} />}
                      </Popup>
                    )}
                  </div>
                );
              })}
            </div>
            {dashcam?.docs?.map((cam, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12%" }}>
                    {cam?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {cam?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {moment(cam?.start).format("DD.MM.YYYY") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {cam?.duration ? handleDuration(cam?.duration) : "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {cam?.videos}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {cam?.urls?.length}
                    {!!cam?.urls?.length && (
                      <Popup
                        on={["click"]}
                        className="dashcam_popUp_options"
                        trigger={(open) => {
                          return <div className="info-icon black" style={{ width: "15px", height: "15px", marginLeft: "15px" }} />;
                        }}
                      >
                        {(close) => {
                          return (
                            <>
                              {cam?.urls?.map((url, i) => (
                                <p style={{ color: "blue", textDecoration: "underline", cursor: "pointer", marginBottom: "10px" }} onClick={() => window.open(url)}>
                                  Линк {i + 1}
                                </p>
                              ))}
                            </>
                          );
                        }}
                      </Popup>
                    )}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {cam?.reportedVideos}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  dashcam: state.app.dashcam,
});
const mapDispatchToProps = (dispatch) => ({
  getDashCam: (payload) => dispatch(getDashCam(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Dash);
