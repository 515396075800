import { nanoid } from "nanoid";
import { User } from "./User";

export class Headers {
  static get() {
    return {
      "Content-Type": "application/json",
    };
  }
  static get_new() {
    return {
      "Content-Type": "application/json",
      "amarant-admin": nanoid(),
      Authorization: ``,
    };
  }
  static get_auth() {
    return {
      "Content-Type": "application/json",
      "amarant-admin": true,
      Authorization: `${User.getToken()}`,
    };
  }
}
