import * as XLSX from "xlsx";
import moment from "moment";
import _ from "lodash";

export const exportAppInsurances = async (data) => {
  const wb = XLSX.utils.book_new();

  const policies = new Promise(async (resolve, reject) => {
    try {
      const payload = data;
      const aoa = [
        ["User", "Phone", "Order time", "Type", "Insurance company", "Policy start date", "Policy end date", "Policy number", "Amount"],
        ...payload.map(({ fullName, phoneNumber, createdAt, policyType, insuranceAgency, policyStartDate, policyEndDate, policyNumber, premium }) => [fullName, phoneNumber, moment(createdAt).format("DD.MM.YYYY  HH:mm"), policyType, insuranceAgency, moment(policyStartDate).format("DD.MM.YYYY  HH:mm"), moment(policyEndDate).format("DD.MM.YYYY  HH:mm"), policyNumber?.slice(0, 32), premium]),
      ];
      return resolve({ sheet: XLSX.utils.aoa_to_sheet(aoa), sheetName: "Added Insurance policies" });
    } catch (error) {
      return reject(error);
    }
  });

  try {
    const sheets = await Promise.all([policies]);
    sheets.forEach(({ sheet, sheetName }) => XLSX.utils.book_append_sheet(wb, sheet, sheetName));
    XLSX.writeFile(wb, `policies-${moment().format("DD-MM-YYYY")}.xlsx`);
  } catch (error) {
    console.error(error);
  }
};

export const exportAppFines = async (data) => {
  const wb = XLSX.utils.book_new();

  const fines = new Promise(async (resolve, reject) => {
    try {
      const payload = data;
      const aoa = [
        ["_id", "User", "phoneNumber", "createdAt", "lateBy", "checkedBy", "companyName", "drivingLicense", "PIN", "UIC", "Manager", "Type", "Identifier", "Payment date", "Payment processor", "mainPrice", "toBePaid"],
        ...payload.map((fine) => [
          ...fine?.fineDetails?.map((det) => [
            fine?._id,
            fine?.fullName || "-",
            fine?.phoneNumber || "-",
            moment(fine?.createdAt).format("DD.MM.YYYY HH:mm"),
            `${fine?.status !== "paid" ? moment(fine.updatedAt).diff(moment(fine.createdAt), "hours") + " hours" : "-"}`,
            fine?.drivingLicense?.type || "drivingLicense",
            "Personal",
            fine?.drivingLicense || "-",
            fine?.drivingLicense?.PIN || "-",
            fine?.drivingLicense?.UIC || "-",
            fine?.drivingLicense?.manager || "-",
            det?.type || "-",
            det?.id || "-",
            moment(det?.paymentDate).format("DD.MM.YYYY HH:mm"),
            det?.paymentProcessor || "-",
            det?.price || "-",
            det?.total || "-",
          ]),
        ]),
      ];
      const fieldNames = aoa[0];
      const restFlattened = _.flatten(aoa.slice(1));

      const finalAoa = [fieldNames, ...restFlattened];

      const columnWidths = fieldNames.map((_, colIndex) => {
        const colContent = finalAoa.map((row) => (row[colIndex] || "").toString());
        const maxLength = Math.max(...colContent.map((val) => val.length));
        return { wch: maxLength + 2 }; // adding some padding
      });

      const sheet = XLSX.utils.aoa_to_sheet(finalAoa);
      sheet["!cols"] = columnWidths;

      return resolve({ sheet, sheetName: "Police Fines" });
    } catch (error) {
      return reject(error);
    }
  });

  try {
    const sheets = await Promise.all([fines]);
    sheets.forEach(({ sheet, sheetName }) => XLSX.utils.book_append_sheet(wb, sheet, sheetName));
    XLSX.writeFile(wb, `fines-${moment().format("DD-MM-YYYY")}.xlsx`);
  } catch (error) {
    console.error(error);
  }
};
