import axios from 'axios';
import { stopLoading } from '../actions';
import { store } from "../config/stores";
import { API_BOS_URL } from '../config/settings';
import Swal from 'sweetalert2';
import { User } from './User';

const httpClient = axios.create({ baseURL: API_BOS_URL });

httpClient.interceptors.request.use(
    async (config) => {
        const tokenStorage = User.getToken() ?? ''
        config.headers = { ...(tokenStorage && { Authorization: tokenStorage }) }
        return config
    },
    (error) => { Promise.reject(error) }
)

httpClient.interceptors.response.use(
    function (response) { return response },
    function (error) {
        store.dispatch(stopLoading())
        if ([401, 403].includes(error.response.status)) {
            // store.dispatch(logOut())
        } else {
            const errorMessage = error?.response?.data?.message;
            if (errorMessage) Swal.fire({
                icon: 'error',
                title: 'Грешка!',
                text: errorMessage,
                confirmButtonColor: '#0099ff',
            })
        }
        return Promise.reject(error)
    });

export default httpClient;
