/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import {
  getStatisticsSuccess,
  getRatings,
  getReviewsByOs,
} from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import moment from "moment";
import { options } from "../../../config/constants";
import Scrollbars from "react-custom-scrollbars";
import Statistics from "..";

const ratingContainers = [
  {
    key: "iOS",
    head: "APP STORE",
  },
  {
    key: "Android",
    head: "GOOGLE PLAY",
  },
];

const Reviews = ({
  getStatisticsSuccess,
  getRatings,
  ratings,
  getReviewsByOs,
  reviews,
  reviewsTotalPages,
}) => {
  const [selOS, setSelOS] = useState("iOS");
  const [currentPage, setCurrentPage] = useState(1);
  const [innerLoading, setInnerLoading] = useState(false);
  const tableRef = useRef();

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.99 && reviewsTotalPages >= currentPage) {
      setInnerLoading(true);
      getReviewsByOs({
        pageNumber: currentPage,
        os: selOS,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    getStatisticsSuccess({ type: "ratings", loading: true });
    getRatings({
      startDate: options[0].value,
      endDate: moment().startOf("day").format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
    getReviewsByOs({
      startDate: options[0].value,
      endDate: moment().startOf("day").format("YYYY-MM-DD") + "T00:00:00.000Z",
      pageNumber: 1,
      os: selOS,
    });
    setCurrentPage(currentPage + 1);
  }, []);

  useEffect(() => {
    getReviewsByOs({ os: selOS, pageNumber: 1 });
    setCurrentPage(2);
  }, [selOS]);

  return (
    <>
      <div className="statistics-sessions-container col">
        <div style={{ display: "flex", width: "100%" }}>
          <h2 className="main-header">
            <b>reviews</b>
          </h2>
        </div>
        <div className="outer-container">
          {ratingContainers.map((obj, i) => {
            return (
              <div className="border-container half" key={i}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  <div className="indicator-circle gray" />
                  <h2
                    className="statistics-info-box-text"
                    style={{ width: "95%", fontSize: "18px" }}
                  >
                    {ratings?.[obj.key]?.rating || "---"}{" "}
                    <p style={{ fontSize: "15px" }}>{obj.head}</p>
                    <p style={{ fontSize: "11px", width: "100%" }}>
                      Based on reviews from from{" "}
                      {ratings?.[obj.key]?.totalRatings || "---"} users
                    </p>
                  </h2>
                </div>

                {/* <div className="info-icon black" />*/}
              </div>
            );
          })}
        </div>
        <div className="statistics-sessions-content">
          <div className="selector-container">
            <div
              className={`select ${selOS === "iOS" && "selected"}`}
              onClick={() => setSelOS("iOS")}
            >
              IOS
            </div>
            <div
              className={`select ${selOS === "Android" && "selected"}`}
              onClick={() => setSelOS("Android")}
            >
              ANDROID
            </div>
          </div>
          <Scrollbars
            style={{ height: "92%", width: "100%" }}
            onUpdate={handleUpdate}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
            ref={tableRef}
            id={"scrollbar"}
          >
            {reviews?.length > 0 ? (
              <>
                {reviews?.map((review, i) => {
                  return (
                    <React.Fragment key={i}>
                      <div className="review-container" key={i}>
                        <div className="review">
                          <div className="line-container">
                            <h2 className="name">{review?.author || "---"}</h2>
                            <p className="date">
                              {moment(review?.date).format("DD.MM.YYYY")}
                            </p>
                          </div>
                          <div className="line-container">
                            <h4 className="title">{review?.title}</h4>
                            <div className="stars-container">
                              {review?.rating &&
                                Array(review?.rating)
                                  .fill("0")
                                  .map((key, j) => {
                                    return <div className="star" key={j} />;
                                  })}
                            </div>
                          </div>
                          <p>{review?.text}</p>
                        </div>
                        {review?.reply && (
                          <div className="response">
                            <h2 className="name">Amarant's response</h2>
                            <p>{review?.reply}</p>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  );
                })}
                {innerLoading && <Statistics.LoaderInline style={{height:"10%"}}/>}
              </>
            ) : (
              <Statistics.LoaderInline />
            )}
          </Scrollbars>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ratings: state.dashboard.ratings,
  reviews: state.dashboard.reviews,
  reviewsTotalPages: state.dashboard.reviewsTotalPages,
});
const mapDispatchToProps = (dispatch) => ({
  getRatings: (payload) => dispatch(getRatings(payload)),
  getReviewsByOs: (payload) => dispatch(getReviewsByOs(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
