import { StatisticsStats } from "../actions";
import { socketTypes } from "../actions";

const initialState = {
  activeUsers: { loading: false },
  activeSessions: { loading: false },
  totalDownloads: { loading: false },
  incomes: { loading: false },
  sessions: { loading: false },
  registeredUsers: { loading: false },
  innerVehicles: { loading: false },
  innerLicenses: { loading: false },
  vehicles: { loading: false },
  appGrowth: { loading: true },
  downloadsByDay: { loading: false },
  reviews: [],
  reviewsTotalPages: 0,
  ratings: { loading: false },
  newSubscriptions: { loading: false },
  activeSubscriptions: { loading: false },
  socketConnected: false,
};

const handleNewRatings = (state, payload, page) => {
  if (page === 1) return payload;
  const _docs = payload.filter((newDoc) => !state.find((oldDoc) => newDoc._id === oldDoc._id));
  return [...state, ..._docs];
};

const handleMergeStats = (state, payload, body) => {
  const newState = { ...state };
  const newData = newState[payload.type].data || [];
  newData.push(payload);
  return { ...state[payload.type], data: newData, loading: false };
};

const dashboard = (state = initialState, { type, payload, body } = {}) => {
  switch (type) {
    case StatisticsStats.GET_STATISTICS_SUCCESS_MERGE:
      return {
        ...state,
        [payload.type]: handleMergeStats(state, payload, body),
      };
    case StatisticsStats.GET_STATISTICS_SUCCESS:
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          [payload.type]: {
            ...(state?.[payload.type] || {}),
            ...payload,
            docs: [...(state?.[payload.type]?.docs || {}), ...payload.docs],
          },
        };
      else
        return {
          ...state,
          [payload.type]: { ...(state?.[payload.type] || {}), ...payload },
        };
    case StatisticsStats.GET_DOWNLOADS_BY_DAY_SUCCESS:
      return {
        ...state,
        downloadsByDay: { ...payload },
      };
    case StatisticsStats.GET_REVIEWS_BY_OS_SUCCESS:
      return {
        ...state,
        reviews: handleNewRatings(state.reviews, payload.reviews, payload.page),
        reviewsTotalPages: payload.totalPages,
      };

    case socketTypes.STATS_CONNECTED:
      return {
        ...state,
        socketConnected: true,
      };
    case "stats/UPDATE":
      return {
        ...state,
        activeSessions: payload,
      };
    case "stats/INIT":
      return {
        ...state,
        activeSessions: payload,
      };
    default:
      return state;
  }
};

export default dashboard;
