/* eslint-disable */
import React from "react";
import "./styles.scss";
import moment from "moment";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { emit } from "../../../utilites/helpers";
// import { socket } from "../../../actions";
import { history } from "../../../config/stores";

export default function SingleTask({ task, index }) {
  // const currentDate = new Date().toISOString().split('T')[0].split('-').join('.');
  const lastOpenedTask = useSelector((state) => state.tasks.lastOpenedTask)
  const scrollRef = useRef()
  const socket = useSelector(({ tasks }) => tasks?.taskSocket) || null;
  const taskPriority = {
    low: "#25E083",
    medium: "#F7E05D",
    high: "#FF7590",
    completed: "#4D5D6E",
  };

  // let overdue = Math.floor(moment.duration(moment(task.endDate).diff(moment())).asDays())
  let overdue = task?.status === 'completed' ?
   Math.floor(moment(task?.completedAt).diff(moment(task?.endDate), "days")) :
   moment().isBefore(moment(task?.endDate)) ?
   -1 :
   Math.floor(moment(task?.endDate).diff(moment(), "days"))
  let isFinished = task?.status === 'completed'

  return (
    <div
    // ref={scrollRef}
    key={task?._id}
    className="draggable-tasks-wrapper"
    onClick={() => {
      emit(socket, { action: 'task/markActivityAsSeen', payload: { taskId: task?._id } })
      // history.push(`/review-task/${task?._id}`)
      window.open(`/review-task/${task?._id}`,"_blank")
      }}>
    {/* onClick={() => history.push(`/review-task/${task?._id}`)}> */}
    <ul
      className="single-task-wrapper"
      style={{ borderColor: isFinished ? taskPriority[task?.status] : taskPriority[task?.priority] }}>
      <li className="flex">
        <h4>{task?.title}</h4>

        {task?._hasNewActivity ?
        <div className="icon">
          <img src={require('../../../assets/icons/new-activity.svg')} />
        </div> : <></>}
      </li>

      <li className="due-date">
        {task?.endDate ?  <span>Срок:&nbsp;<span className="task-data" style={{marginLeft:"5px"}}>{moment(task?.endDate).format('DD.MM.YYYY')}</span></span> : <span>Срок: няма</span>}
        {!isFinished && <span className="red">&nbsp;{overdue > 0 && <h5>(просрочен с {overdue} дни)</h5>}</span>}
      </li>

      {isFinished &&
      <li className="due-date">
        <span>
          Завършен на:&nbsp;
          <span className="task-data finished">
            {moment(task?.completedAt).format('DD.MM.YYYY')}
            {/* {moment(task?.completedAt).format('DD.MM.YYYY | HH:mm')} */}
          </span>
        </span>

        <span className="red">{(overdue > 0 && overdue > 1) && <h5>(просрочен с {overdue} дни)</h5>}</span>
        <span className="red">{(overdue > 0 && overdue === 1) && <h5>(просрочен с {overdue} ден)</h5>}</span>
      </li>}

        <li className="employees-wrapper">
          <h5>{task?.assignedTo?.map(el => el?.name + " " + el?.lastName)?.slice(0, 2)?.join(', ')}</h5>

          {task?.assignedTo && task?.assignedTo?.length > 2 &&
          <div className="names-count-wrapper">
            <div className="names-count">
              {`+${task?.assignedTo.length - 2}`}
            </div>

            <div className="more-names">
              {task?.assignedTo.slice(2).map((employee, index) => {
                return (
                  <h5 className="task-data" key={index * 100}>
                    {employee?.name + " " + employee?.lastName}
                  </h5>
                );
              })}
            </div>
          </div>}
        </li>
    </ul>
  </div>

    // <>
    //   <Draggable draggableId={task._id} index={index}>
    //     {(provided) => {
    //       return (
    //         <div
    //           key={task._id}
    //           {...provided.draggableProps}
    //           {...provided.dragHandleProps}
    //           ref={provided.innerRef}
    //           className="draggable-tasks-wrapper"
    //         >
    //           <ul
    //             className="single-task-wrapper"
    //             style={{ borderColor: taskPriority[task.priority] }}
    //           >
    //             <li>
    //               <h4>{task.title}</h4>
    //             </li>

    //             <li className="due-date">
    //               <span>
    //                 Срок:&nbsp;
    //                 <span className="task-data">
    //                   {task.dateEnd
    //                     .toString()
    //                     .split("T")[0]
    //                     .split("-")
    //                     .reverse()
    //                     .join(".")}
    //                 </span>
    //               </span>

    //               {/* <span>{task.overdue && <h5>Просрочен с {task.overdue} дни</h5>}</span> */}
    //             </li>

    //             {task.assignedTo && task.assignedTo.length > 2 ? (
    //               <li className="employees-wrapper">
    //                 <h5 className="task-data">
    //                   {task.assignedTo[0].fullName +
    //                     ", " +
    //                     task.assignedTo[1].fullName}
    //                 </h5>

    //                 <div className="names-count-wrapper">
    //                   <div className="names-count">
    //                     {`+${task.assignedTo.length - 2}`}
    //                   </div>

    //                   <div className="more-names">
    //                     {task.assignedTo.slice(2).map((employee, index) => {
    //                       return (
    //                         <h5 className="task-data" key={index * 100}>
    //                           {employee.fullName}
    //                         </h5>
    //                       );
    //                     })}
    //                   </div>
    //                 </div>
    //               </li>
    //             ) : (
    //               <li className="employees-wrapper">
    //                 <h5 className="task-data">
                   
    //               <ul>
    //                 {task.assignedTo.map((employee) => {
    //                   return <li>{employee.fullName}</li>;
    //                 })}
    //               </ul>
                
    //                 </h5>
    //               </li>
    //             )}
    //           </ul>
    //         </div>
    //       );
    //     }}
    //   </Draggable>
    // </>
  );
}
