import sign from "jwt-encode";
import { nanoid } from "nanoid";
import { useHistory, useLocation } from "react-router-dom";

export const emit = (socket, { action, ...rest } = {}) => {
  if (!socket) return;
  const payload = { action, ...rest };
  console.log("Socket out: ", payload);
  socket.emit("task-message", payload);
};
export const useQuery = () => {
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  const handleUrlChange = (newValues = {}) => {
    Object.entries(newValues).forEach(([key, value]) => {
      if (!value) query.delete(key);
      else if (query.has(key)) query.set(key, value);
      else query.append(key, value);
    });

    history.replace({ ...location, search: "?" + query.toString() });
  };

  return { ...Array.from(query.keys()).reduce((a, key) => ({ ...a, [key]: query.get(key) }), {}), handleUrlChange };
};

export const generateLoginToken = async () => {
  const token = await sign(
    {
      app: "amarant",
      date: new Date(),
      val: nanoid(),
    },
    "am4R4ntPSMSN0Sp4M%^&212198))1A1____121--&&&1212112"
  );
  return token;
};
