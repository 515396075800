/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { forwardRef } from "react";

const TextInput = forwardRef(({ label, compulsory, style, inputStyle, number, disabled, suffix, prefix, password, className, symbolSuffix, ...props }, ref) => {
  return (
    <div className="input-container" style={style}>
      {label && (
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
        </label>
      )}

      <div className={`prefix-input-container ${disabled && "disabled"} ${className}`} style={inputStyle}>
        {prefix && <div className={`prefix`}>+359</div>}
        <input className={`inner-input`} type={password ? "password" : number ? "number" : "text"} id={password && "input"} disabled={disabled} {...props} ref={ref} />
        {suffix && <div className="suffix">{suffix}</div>}
      </div>
    </div>
  );
});

export default TextInput;
