/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { listReached } from "../../../actions";
import "./styles.scss";
import Scrollbars from "react-custom-scrollbars";
import Statistics from "../../Statistics";
import { connect } from "react-redux";
import { Popover } from "@blueprintjs/core";
import Inputs from "../../Inputs";

const headerElements = [
  {
    name: "Name",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Email",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone number",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Age",
    query: { filter: "Time", options: [] },
  },
  {
    name: "OS",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Spend total",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Number of vehicles",
    query: { filter: "Role", options: [] },
  },
];

const ActiveUsersList = ({ listReached, reachedList, activeSessions }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(null);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && reachedList.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      listReached({
        query: {
          _id: { $in: firstLoad.activeUserIds },
        },
        pageNumber: curPage,
        pageSize: 20,
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    if (activeSessions.activeUserIds && !firstLoad) {
      setFirstLoad(activeSessions);
      listReached({
        query: {
          _id: { $in: activeSessions.activeUserIds },
        },
        pageNumber: 1,
        pageSize: 20,
      });
    }
  }, [activeSessions]);

  return (
    <>
      <div className="main-container">
        <div
          className="body-container"
          style={{
            height: "100%",
            marginTop: "0%",
            overflowX: window.innerWidth < 1200 && "auto",
          }}
        >
          <div className="flex-container full-width space-between">
            <h2>Active users</h2>
            <Inputs.Button
              selected
              text="Refresh"
              onClick={() => {
                setFirstLoad(activeSessions.activeUserIds);
                listReached({
                  query: {
                    _id: { $in: activeSessions.activeUserIds },
                  },
                  pageNumber: 1,
                  pageSize: 20,
                });
                setCurPage(2);
              }}
            />
          </div>
          <Scrollbars
            style={{
              height: "93%",
              width: window.innerWidth < 1200 ? "180%" : "100%",
              marginTop: "2%",
            }}
            onUpdate={handleUpdate}
            ref={tableRef}
            id={"scrollbar"}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
          >
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12.5%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {reachedList?.docs?.map((doc, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div
                    className="row-data"
                    style={{ width: "12.5%", fontSize: "13px" }}
                  >
                    {doc?.name + " " + doc?.lastName}
                  </div>
                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      wordBreak: "break-all",
                    }}
                  >
                    {doc?.user?.split("|")[0]}
                  </div>
                  <div
                    className="row-data"
                    style={{ width: "12.5%", fontSize: "13px" }}
                  >
                    {doc?.user?.split("|")[1]}
                  </div>
                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {doc?.ages?.[0] || "---"}
                  </div>

                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {doc?.os}
                  </div>
                  <div
                    className="row-data"
                    style={{
                      width: "12.5%",
                      fontSize: "13px",
                      textTransform: "capitalize",
                    }}
                  >
                    {doc?.spendTotal ? doc?.spendTotal + " BGN" : "---"}
                  </div>
                  <div
                    className="row-data"
                    style={{ width: "12.5%", fontSize: "13px" }}
                  >
                    {doc?._vehicles?.length}
                    {doc?.carMakes?.length > 0 && (
                      <Popover
                        interactionKind="hover"
                        content={
                          <div className="car-makes-container">
                            {doc?.carMakes?.map((car) => (
                              <p>{car || "---"}</p>
                            ))}
                          </div>
                        }
                      >
                        <div
                          className="info-icon black"
                          style={{ marginLeft: "10px" }}
                        />
                      </Popover>
                    )}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  reachedList: state.ads.reachedList,
  activeSessions: state.dashboard.activeSessions,
});
const mapDispatchToProps = (dispatch) => ({
  listReached: (payload) => dispatch(listReached(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ActiveUsersList);
