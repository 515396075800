/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { forwardRef } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

const TextInput = forwardRef(
  (
    {
      label,
      compulsory,
      style,
      inputStyle,
      disabled,
      className,
      field,
      setValue,
      value,
      edit,
    },
    ref
  ) => {
    const [address, setAddress] = useState("");

    useEffect(() => {
      setAddress(value);
    }, [value]);

    return (
      <div className="input-container" style={style}>
        {label && (
          <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
            {label}{" "}
            {compulsory && (
              <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            )}
          </label>
        )}
        {edit ? (
          address?.length > 1 && (
            <GooglePlacesAutocomplete
              apiKey="AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q"
              autocompletionRequest={{
                componentRestrictions: {
                  country: ["bg"],
                },
                types: ["address"],
              }}
              id="address"
              selectProps={{
                defaultInputValue: address,
                address,
                isDisabled: disabled,
                onChange: (address) => {
                  setAddress(address);
                  geocodeByAddress(address["label"])
                    .then((results) => getLatLng(results[0]))
                    .then(({ lat, lng }) => {
                      setValue(field, [lng, lat]);
                      setValue("address", address.label);
                    });
                },
              }}
            />
          )
        ) : (
          <GooglePlacesAutocomplete
            apiKey="AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q"
            autocompletionRequest={{
              componentRestrictions: {
                country: ["bg"],
              },
              types: ["address"],
            }}
            id="address"
            selectProps={{
              defaultInputValue: address,
              address,
              onChange: (address) => {
                setAddress(address);
                geocodeByAddress(address["label"])
                  .then((results) => getLatLng(results[0]))
                  .then(({ lat, lng }) => {
                    setValue(field, [lng, lat]);
                    setValue("address", address.label);
                  });
              },
            }}
          />
        )}
      </div>
    );
  }
);

export default TextInput;
