/* eslint-disable */
import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Statistics from "../../components/Statistics";
import "./styles.scss";
import { useEffect } from "react";
import { startLoading } from "../../actions";

const Dashboard = () => {
  return (
    <div className="screen-statistics-container row">
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "50vw" : "35vw" }}>
        <Statistics.Sessions />
      </div>
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "50vw" : "35vw" }}>
        <Statistics.Income />
      </div>
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "50vw" : "35vw" }}>
        <Statistics.AppSessions />
      </div>
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "50vw" : "35vw" }}>
        <Statistics.RegisteredUsers />
      </div>
      <div className="col" style={{ minWidth: "28vw" }}>
        <Statistics.PremiumUsers />
      </div>
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "50vw" : "35vw" }}>
        <Statistics.Vehicles />
      </div>
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "55vw" : "42vw" }}>
        <Statistics.AppGrowth />
      </div>
      <div className="col" style={{ minWidth: window.innerWidth < 1200 ? "45vw" : "30vw" }}>
        <Statistics.Reviews />
      </div>
    </div>
  );
};

export default Dashboard;
