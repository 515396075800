/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { forwardRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "./styles.scss";

const CustomInput = React.forwardRef((props, ref, className) => {
  return (
    <div className={`date-picker flex-container align-center ${className}`}>
      <label onClick={props.onClick} ref={ref} style={{ width: "93%", paddingLeft: "2%" }}>
        {props.value || props.placeholder}
      </label>
      <div className="date-icon" onClick={props.onClick} />
    </div>
  );
});

const TextInput = forwardRef(({ label, compulsory, style, inputStyle, disabled, className, value, ...props }, ref) => {
  return (
    <div className="input-container" style={style}>
      {label && (
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label} {compulsory && <span style={{ paddingLeft: "5px", color: "black" }}>*</span>}
        </label>
      )}
      <DatePicker
        customInput={<CustomInput />}
        {...props}
        selected={value}
        disabled={disabled}
        ref={ref}
        wrapperClassName={className}
        style={{ outline: "none" }}
      />
    </div>
  );
});

export default TextInput;
