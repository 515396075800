import React, { useEffect, useRef } from "react";
import "./styles.scss";

const LoginVerification = ({ code, setCode, failed, setFailed }) => {
  const inputRefs = useRef(new Array(4));

  useEffect(() => { if (inputRefs?.current?.[0]) inputRefs.current[0].focus() }, [failed])

  return (
    <div className="login-verification-conatiner">
      {failed === "invalid" && <div className="row code-invalid">Кодът е грешен</div>}
      <div className="code-container">
        <div className="row code-conatiner-inner">
          {new Array(4).fill("").map((v, i) => (
            <input
              onChange={() => { }}
              key={`input-${i}`}
              ref={(ref) => (inputRefs.current[i] = ref)}
              className={`digit-container ${failed === true ? "invalid" : ""} ${failed === false ? "valid" : ""}`}
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
              value={code[i] || ""}
              onKeyDown={({ key, keyCode }) => {
                const newCode = code.slice();
                if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
                  newCode.splice(i, 1, key);
                  if (i < code.length - 1) inputRefs.current[i + 1].focus();
                  else inputRefs.current[i].blur();
                } else if (keyCode === 8) {
                  newCode.splice(i, 1, "");
                  if (i > 0) inputRefs.current[i - 1].focus();
                }
                setCode(newCode);
                if (failed) setFailed(null)
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoginVerification;
