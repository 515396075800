/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getPaidFines } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";
import Popup from "reactjs-popup";
import { exportAppFines } from "../../../utilites/exportAppData";

const headerElements = [
  {
    name: "User",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Payment date",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Amount",
    query: { filter: "Role", options: [] },
  },
];

const innerTableHeaderElements = [
  {
    name: "ID",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Total",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Payment processor",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Payment date",
    query: { filter: "Invitation sent by", options: [] },
  },
];

const PaidFines = ({ getPaidFines, paidFines, period, loading, setLoading }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && paidFines.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getPaidFines({
        pageNumber: curPage,
        limit: 20,
        ...period,
        createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
        onSuccess: (res) => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    if (period.createdAtFrom) {
      setLoading(true);
      getPaidFines({
        pageNumber: 1,
        limit: 20,
        ...period,
        createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
        onSuccess: (res) => {
          setLoading(false);
        },
      });
      setCurPage(2);
    }
  }, [period]);

  const handleGetPaidFinesForPeriod = () => {
    setLoading(true);
    getPaidFines({
      pagination: false,
      ...period,
      createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
      onSuccess: (res) => {
        exportAppFines(res?.payload?.docs);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {loading === true ? (
        <div style={{ height: "90%" }}>{<Statistics.LoaderInline center />}</div>
      ) : (
        <>
          {" "}
          <div className="flex-container align-center space-between" style={{ marginBottom: "15px", width: window.innerWidth < 1200 ? "160%" : "100%" }}>
            <h2 style={{ textTransform: "uppercase" }}>users with paid tickets and fines</h2>
            <div className="export-icon-grid" onClick={handleGetPaidFinesForPeriod} />
          </div>
          <Scrollbars style={{ height: "92%", width: window.innerWidth < 1200 ? "160%" : "100%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {paidFines?.docs?.map((fine, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12%" }}>
                    {fine?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {fine?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {moment(fine?.createdAt).format("DD.MM.YYYY") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "64%" }}>
                    {fine?.amount.toFixed(2) + " BGN" || "---"}
                    <Popup trigger={<div className="info-icon black" style={{ marginLeft: "25px", width: "17px", height: "17px" }} />} className="anvil" contentStyle={{ width: 700, padding: 20, border: "1px solid #002c78", borderRadius: "8px" }} position={"right bottom"}>
                      <Scrollbars style={{ minHeight: "200px", width: "100%" }} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
                        <div className="table-header">
                          {innerTableHeaderElements?.map((header, i) => {
                            return (
                              <div
                                className="header-cont"
                                key={i}
                                style={{
                                  width: "25%",
                                  fontSize: "11px",
                                }}
                              >
                                <div className="header">{header.name}</div>
                              </div>
                            );
                          })}
                        </div>

                        {fine?.fineDetails?.map((det) => (
                          <div className="table-body-row" key={det?.id}>
                            <div className="row-data" style={{ width: "25%", fontSize: "12px" }}>
                              {det?.id || "---"}
                            </div>
                            <div className="row-data" style={{ width: "25%", fontSize: "12px" }}>
                              {det?.total || "---"} BGN
                            </div>
                            <div className="row-data" style={{ width: "25%", fontSize: "12px" }}>
                              {det?.paymentProcessor || "---"}
                            </div>
                            <div className="row-data" style={{ width: "25%", fontSize: "12px" }}>
                              {moment(det?.paymentDate).format("DD.MM.YYYY | HH:mm")}
                            </div>
                            {/* <div className="row-data" style={{ width: "25%", fontSize: "13px" }}>
                              {det?.paid ? "Да" : "Не"}
                            </div> */}
                          </div>
                        ))}
                      </Scrollbars>
                    </Popup>
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  paidFines: state.app.paidFines,
});
const mapDispatchToProps = (dispatch) => ({
  getPaidFines: (payload) => dispatch(getPaidFines(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaidFines);
