/* eslint-disable */
import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import Statistics from "../../components/Statistics";
import Inputs from "../../components/Inputs";

const firstFieldNames = [
  "Car Liability policies - GO",
  "Car Liability policies - FOND",
  "Autocasco Insurances policies",
  "Other Insurances policies",
  "Insurance instalments",
];
const secondFieldNames = ["Police fines", "Vignettes", "Geowash"];
const secondInnerFieldNames = ["Issued fines", "Expiring Vignettes"];

const inputStyle = {
  border: "none",
  borderRadius: "0px",
  borderBottom: "1px solid black",
};

const PotentialIncome = ({ docs, getPotentialIncome }) => {
  const [loading, setLoading] = useState(true);
  const [finalIncomes, setFinalIncomes] = useState([]);
  const [finalInApp, setFinalInApp] = useState([]);

  useEffect(() => {
    getPotentialIncome({
      onSuccess: (res) => {
        setFinalIncomes([
          {
            count: res?.go?.goCount,
            avgPrice: res?.go?.avgPrice,
            percent: 0,
          },
          {
            count: res?.go?.fondCount,
            avgPrice: res?.go?.avgPrice,
            percent: 0,
          },
          {
            count: res?.kasko?.count,
            avgPrice: res?.kasko?.avgPrice,
            percent: 0,
          },
          {
            count: res?.otherPolicies?.count,
            avgPrice: res?.otherPolicies?.avgPrice,
            percent: 0,
          },
          {
            count: res?.installments?.count,
            avgPrice: res?.installments?.avgPrice,
            percent: 0,
          },
        ]);
        setFinalInApp([
          {
            count: res?.fines?.count,
            avgPrice: res?.fines?.avgPrice,
            percent: "",
          },
          {
            count: res?.vignettes?.count,
            avgPrice: res?.vignettes?.avgPrice,
            percent: "",
          },
          {
            count: "",
            avgPrice: res?.geoWash?.avgPrice,
            percent: 0,
          },
        ]);
        setLoading(false);
      },
    });
  }, []);
  return (
    <>
      <div className="potential-container">
        <div className="category-divider full-width flex-container align-center">
          <h3>INSURANCES</h3>
          <div className="indicator black special" />
        </div>
        {loading === true ? (
          <Statistics.LoaderInline style={{ height: "20%" }} />
        ) : (
          finalIncomes.map((income, i) => {
            return (
              <div className="potential-row" key={i}>
                <div className="row-title">{firstFieldNames[i]}</div>
                <div className="users-count">
                  <div className="indicator purple" />
                  <p className="user-text">
                    <b style={{ fontSize: "15px" }}>{income?.count}</b> EXPIRING
                    POLICIES
                  </p>
                </div>
                <div className="multiply-icon" />
                <div className="users-count" style={{ width: "45%" }}>
                  <Inputs.TextInput
                    symbolSuffix
                    number
                    inputStyle={inputStyle}
                    value={income.percent || ""}
                    style={{
                      height: "100%",
                      width: "25%",
                    }}
                    onChange={(e) => {
                      const newFinalIncomes = finalIncomes.slice(0);
                      newFinalIncomes[i] = {
                        ...income,
                        percent: Number(e.target.value),
                      };
                      setFinalIncomes(newFinalIncomes);
                    }}
                  />
                  <p className="user-text" style={{ width: "17%" }}>
                    {i === 4 ? "PAID" : "RENEWED"} VIA APP
                  </p>
                  <>
                    {" "}
                    <div
                      className="indicator black"
                      style={{ width: "0.3%", margin: "0px 20px" }}
                    />
                    <Inputs.TextInput
                      suffix="BGN"
                      number
                      inputStyle={inputStyle}
                      disabled={i === 4 && true}
                      value={income.avgPrice || ""}
                      style={{
                        height: "100%",
                        width: "25%",
                      }}
                      onChange={(e) => {
                        const newFinalIncomes = finalIncomes.slice(0);
                        newFinalIncomes[i] = {
                          ...income,
                          avgPrice: Number(e.target.value),
                        };
                        setFinalIncomes(newFinalIncomes);
                      }}
                    />
                    <p className="user-text">AVERAGE PRICE</p>
                  </>
                </div>
                <div className="equal-icon" />
                <div
                  className="users-count"
                  style={{ marginLeft: "0%", width: "19%" }}
                >
                  <div className="indicator blue" />
                  <p className="user-text">
                    <b style={{ fontSize: "15px" }}>
                      {(
                        ((Number(income?.percent) * Number(income?.count)) /
                          100) *
                        income?.avgPrice
                      ).toFixed(2)}
                    </b>{" "}
                    BGN POTENTIAL INCOME
                  </p>
                </div>
              </div>
            );
          })
        )}
        <div className="category-divider full-width flex-container align-center">
          <h3 style={{ width: "12%" }}>IN-APP PURCHASES</h3>
          <div className="indicator black special" />
        </div>
        {finalInApp.map((doc, i) => {
          return (
            <div className="potential-row" key={i}>
              <div className="row-title">{secondFieldNames[i]}</div>
              {i < 2 && (
                <>
                  <div className="users-count">
                    <div className="indicator purple" />
                    <p className="user-text">
                      <b style={{ fontSize: "15px" }}>{doc?.count}</b>{" "}
                      {secondInnerFieldNames[i]}
                    </p>
                  </div>
                  <div className="multiply-icon" />
                </>
              )}

              <div className="users-count" style={{ width: i === 2 ? "65%" :"45%" }}>
                <Inputs.TextInput
                  symbolSuffix={i < 2 && true}
                  suffix={i === 2 && "orders"}
                  number
                  inputStyle={inputStyle}
                  value={i === 2 ? doc?.count : doc?.percent}
                  style={{
                    height: "100%",
                    width: "25%",
                  }}
                  onChange={(e) => {
                    const newFinalIncomes = finalInApp.slice(0);
                    if (i === 2)
                      newFinalIncomes[i] = {
                        ...doc,
                        count: Number(e.target.value),
                      };
                    else
                      newFinalIncomes[i] = {
                        ...doc,
                        percent: Number(e.target.value),
                      };
                    setFinalInApp(newFinalIncomes);
                  }}
                />
                <p className="user-text">
                  {i === 2 ? "RESERVED AND PAID" : "RENEWED"} VIA APP
                </p>
                <>
                  {" "}
                  <div
                    className="indicator black"
                    style={{ width: "0.3%", margin: "0px 20px" }}
                  />
                  <Inputs.TextInput
                    suffix="BGN"
                    number
                    inputStyle={inputStyle}
                    disabled={i === 4 && true}
                    value={doc.avgPrice || ""}
                    style={{
                      height: "100%",
                      width: "25%",
                    }}
                  />
                  <p className="user-text">AVERAGE PRICE</p>
                </>
              </div>
              <div className="equal-icon" />
              <div
                className="users-count"
                style={{ marginLeft: "0%", width: "19%" }}
              >
                <div className="indicator blue" />
                <p className="user-text">
                  <b style={{ fontSize: "15px" }}>
                    {i < 2
                      ? (
                          ((Number(doc?.percent) * Number(doc?.count)) / 100) *
                          doc?.avgPrice
                        ).toFixed(2)
                      : (doc?.count * doc?.avgPrice).toFixed(2)}
                  </b>{" "}
                  BGN POTENTIAL INCOME
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <p style={{width:"100%",textAlign:"right",marginTop:"2%"}}>Period: <b>Next 30 days</b></p>
    </>
  );
};

export default PotentialIncome;
