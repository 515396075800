/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

const Button = ({ text, style, disabled, onClick, selected, className, deleted, icon, cancel }) => {
  return (
    <div
      className={`button-container ${className} ${selected && "selected"} ${disabled && "disabled"} ${cancel && "cancel"} ${
        deleted && "delete"
      }`}
      style={style}
      onClick={onClick}
    >
      <b style={{ textTransform: "uppercase", paddingLeft: icon && "2%" }}>{text}</b>
      {icon && icon}
    </div>
  );
};
export default Button;
