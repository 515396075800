/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getNewPolicies } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";
import _ from "lodash";
import { exportAppInsurances } from "../../../utilites/exportAppData";

const headerElements = [
  {
    name: "User",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Order time",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Type of insurance",
    query: { filter: "Role", options: [] },
  },
  {
    name: "Insurance company",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Policy start date",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Policy end date",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Policy number",
    query: { filter: "Invitation sent to", options: [] },
  },
  {
    name: "Amount",
    query: { filter: "Role", options: [] },
  },
];

const NewInsurances = ({ getNewPolicies, newPolicies, period, setLoading, loading }) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && newPolicies.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      getNewPolicies({
        pageNumber: curPage,
        limit: 20,
        ...period,
        createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
        onSuccess: () => setInnerLoading(false),
      });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    setLoading(true);
    getNewPolicies({
      pageNumber: 1,
      limit: 20,
      ...period,
      createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
      onSuccess: (res) => {
        setLoading(false);
      },
    });
    setCurPage(2);
  }, [period]);

  const handleGetAppPoliciesForPeriod = () => {
    setLoading(true);
    getNewPolicies({
      pagination: false,
      ...period,
      createdAtFrom: period?.createdAtFrom || moment("2020-07-21").startOf("day"),
      onSuccess: (res) => {
        exportAppInsurances(res?.payload?.docs);
        setLoading(false);
      },
    });
  };

  return (
    <>
      {loading === true ? (
        <div style={{ height: "90%" }}>{<Statistics.LoaderInline center />}</div>
      ) : (
        <>
          <div className="flex-container align-center space-between" style={{ marginBottom: "15px", width: window.innerWidth < 1200 ? "160%" : "100%" }}>
            <h2 style={{ textTransform: "uppercase" }}>Users with added policies</h2>
            <div className="export-icon-grid" onClick={handleGetAppPoliciesForPeriod} />
          </div>
          <Scrollbars style={{ height: "92%", width: window.innerWidth < 1200 ? "160%" : "100%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "11%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {newPolicies?.docs?.map((policy, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "11%" }}>
                    {policy?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {policy?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {moment(policy?.createdAt).format("DD.MM.YYYY") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {policy?.policyType || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {policy?.insuranceAgency || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {moment(policy?.policyStartDate).format("DD.MM.YYYY") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {moment(policy?.policyEndDate).format("DD.MM.YYYY") || "---"}
                  </div>
                  <div className="row-data" style={{ width: "11%", wordBreak: "break-all" }}>
                    {policy?.policyNumber?.slice(0, 50) + "..."}
                  </div>
                  <div className="row-data" style={{ width: "11%" }}>
                    {policy?.premium + " BGN" || "---"}
                  </div>
                </div>
              );
            })}
            {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  newPolicies: state.app.newPolicies,
});
const mapDispatchToProps = (dispatch) => ({
  getNewPolicies: (payload) => dispatch(getNewPolicies(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(NewInsurances);
