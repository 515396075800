export const AppStats = {
  GET_STATS: "app/GET_STATS",
  GET_STATS_SUCCESS: "app/GET_STATS_SUCCESS",

  GET_INFO_SUCCESS: "app/GET_INFO_SUCCESS",
  GET_NEW_POLICIES: "app/GET_NEW_POLICIES",
  GET_ADDED_POLICIES: "app/GET_ADDED_POLICIES",
  GET_INSTALLMENTS: "app/GET_INSTALLEMENTS",
  GET_PAID_FINES: "app/GET_PAID_FINES",
  GET_VIGNETTES: "app/GET_VIGNETTES",
  GET_PARKING: "app/GET_PARKING",
  GET_CLAIMS: "app/GET_CLAIMS",
  GET_COSTS: "app/GET_COSTS",
  GET_EVENTS: "app/GET_EVENTS",
  GET_GEOWASH_ORDERS: "app/GET_GEOWASH_ORDERS",
  GET_DASHCAM: "app/GET_DASHCAM",
};

export const getStats = (payload) => ({
  type: AppStats.GET_STATS,
  payload,
});

export const getNewPolicies = (payload) => ({
  type: AppStats.GET_NEW_POLICIES,
  payload,
});
export const getOrderedPolicies = (payload) => ({
  type: AppStats.GET_ADDED_POLICIES,
  payload,
});
export const getInstallments = (payload) => ({
  type: AppStats.GET_INSTALLMENTS,
  payload,
});
export const getPaidFines = (payload) => ({
  type: AppStats.GET_PAID_FINES,
  payload,
});
export const getVignettes = (payload) => ({
  type: AppStats.GET_VIGNETTES,
  payload,
});
export const getParking = (payload) => ({
  type: AppStats.GET_PARKING,
  payload,
});
export const getClaims = (payload) => ({
  type: AppStats.GET_CLAIMS,
  payload,
});
export const getCosts = (payload) => ({
  type: AppStats.GET_COSTS,
  payload,
});
export const getEvents = (payload) => ({
  type: AppStats.GET_EVENTS,
  payload,
});
export const getGeowashOrders = (payload) => ({
  type: AppStats.GET_GEOWASH_ORDERS,
  payload,
});

export const getDashCam = (payload) => ({
  type: AppStats.GET_DASHCAM,
  payload,
});
