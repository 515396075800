/* eslint-disable */
import { jsx } from "@emotion/react";
import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import "./styles.scss";
const headerElements = [
  {
    name: "Name",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone number",
    query: { filter: "Time", options: [] },
  },
];

const AccessModal = ({ handleClose, data }) => {
  console.log(data);

  return (
    <div className="users-modal-component" style={{justifyContent:"flex-start",gap:"30px"}}>
      <div className="flex-container full-width align-center" style={{ marginBottom: "20px" }}>
        <div className="close-icon" style={{ marginRight: "2%" }} onClick={() => handleClose()} />
        <h2>Users that have access</h2>
      </div>
      {data && (
        <Scrollbars style={{ height: "400px", width: "100%" }} id={"scrollbar"} renderView={(props) => <div {...props} style={{ ...props.style, overflowX: "hidden" }} />}>
          <div className="table-header">
            {headerElements?.map((header, i) => {
              return (
                <div
                  className="header-cont"
                  key={i}
                  style={{
                    width: "50%",
                  }}
                >
                  <div className="header">{header.name}</div>
                </div>
              );
            })}
          </div>
          {data?.map((ad, i) => {
            return (
              <div className="table-body-row" key={i}>
                <div className="row-data" style={{ width: "50%" }}>
                  {ad?.name + " " +ad?.lastName}
                </div>
                <div className="row-data" style={{ width: "50%" }}>
                  {ad?.phoneNumber || "---"}
                </div>
              </div>
            );
          })}
        </Scrollbars>
      )}
    </div>
  );
};

export default AccessModal;
