import { AppStats } from "../actions";

const initialState = {
  stats: {},
  newPolicies: {
    totalPages: 0,
    docs: [],
  },
  orderedPolicies: {
    totalPages: 0,
    docs: [],
  },
  installments: {
    totalPages: 0,
    docs: [],
  },
  paidFines: {
    totalPages: 0,
    docs: [],
  },
  claims: {
    totalPages: 0,
    docs: [],
  },
  costs: {
    totalPages: 0,
    docs: [],
  },
  events: {
    totalPages: 0,
    docs: [],
  },
  geoWash: {
    totalPages: 0,
    docs: [],
  },
  dashcam: {
    totalPages: 0,
    docs: [],
  },
};

const handleNewDocs = (state, payload, key) => {
  if (payload.page === 1) return payload.docs;
  const _docs = payload.docs.filter(
    (newDoc) => !state[key].docs.find((oldDoc) => newDoc._id === oldDoc._id)
  );
  return [...state?.[key]?.docs, ..._docs];
};

const app = (state = initialState, { type, payload, key } = {}) => {
  switch (type) {
    case AppStats.GET_STATS_SUCCESS:
      return {
        ...state,
        stats: { ...state.stats, ...payload },
      };
    case AppStats.GET_INFO_SUCCESS:
      return {
        ...state,
        [key]: {
          totalPages: payload.totalPages,
          docs: handleNewDocs(state, payload, key),
        },
      };
    default:
      return state;
  }
};

export default app;
