import S3FileUpload from "react-s3";
import { nanoid } from "nanoid";
import { s3Credentials, s3CredentialsTasks } from "../config/settings";
import _ from "lodash";
import * as XLSX from 'xlsx'

export const uploadFile = (file) =>
  S3FileUpload.uploadFile(
    new File([file], file?.name || nanoid(), {
      type: file?.type || "",
      lastModified: file?.lastModified || new Date(),
    }),
    s3Credentials
  );

export const uploadFiles = (files) => {
  const promises = [];
  Array.from(files).forEach((f) =>
    promises.push(
      S3FileUpload.uploadFile(
        new File([f], f?.name || nanoid(), {
          type: f?.type,
          lastModified: f?.lastModified || new Date(),
        }),
        s3Credentials
      )
    )
  );
  return Promise.all(promises);
};
export const parseXLSX = (file, handleResults) => {
  const reader = new FileReader();
  reader.onload = function (e) {
    const data = e.target.result;
    const wb = XLSX.read(data, { type: "binary" });
    const results = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1, raw: false }));
    const parsed = _.flatten(
      results
        .filter((arr) => !_.isEmpty(arr))
        .map((sheet) => {
          const first = sheet.shift().map((header) => header?.trim() || "");
          return sheet.map((el) => el.reduce((acc, cur, i) => ({ ...acc, [first[i]]: cur.toString().trim() }), {}));
        })
    );
    handleResults(parsed);
  };
  reader.readAsBinaryString(file);
};


const Files = {
  uploadFiles(files) {
    const promises = []
    Array.from(files).forEach((f) => {
      const file = new File([f], nanoid(), { type: f.type })
      promises.push(S3FileUpload.uploadFile(file, s3CredentialsTasks))
    })
    return Promise.all(promises)
  },
  uploadFile(file, name) {
    const uploadFile = new File([file], name || nanoid(), {
      type: file.type,
      lastModified: file.lastModified,
    })
    return S3FileUpload.uploadFile(uploadFile, s3CredentialsTasks)
  },
}

export default Files