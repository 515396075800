/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { forwardRef } from "react";
import "./styles.scss";
import Select, { components } from "react-select";
import ArrowDown from "../../../assets/icons/arrow-down.svg";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        className="close-icon"
        style={{
          backgroundImage: `url(${ArrowDown})`,
          width: "20px",
          height: "20px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </components.DropdownIndicator>
  );
};

const Dropdown = forwardRef(
  (
    {
      optionsArray,
      disabled,
      onChange,
      className,
      style,
      single,
      customStyles,
      value,
    },
    ref
  ) => {
    return (
      <div
        className="input-container"
        style={style ? style : { width: "100%", marginLeft: "-1.3%" }}
      >
        <Select
          options={optionsArray?.map((opt) => {
            return { label: opt.label, value: opt.value };
          })}
          ref={ref}
          isDisabled={disabled}
          isMulti={!single}
          defaultMenuIsOpen={true}
          className={className}
          styles={customStyles}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          onChange={onChange}
        />
      </div>
    );
  }
);

export default Dropdown;
