/* eslint-disable */
import React from "react";
import "./styles.scss";
import { forwardRef } from "react";
import Select, { components } from "react-select";
import ArrowDown from "../../../assets/icons/arrow-down-blue.svg";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 24.2,
    height: 39,
    display: "flex",
    border: "1px solid rgb(165, 164, 164)",
    borderRadius: "7px",
    marginTop: "3px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div
        className="close-icon"
        style={{
          backgroundImage: `url(${ArrowDown})`,
          width: "20px",
          height: "20px",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
    </components.DropdownIndicator>
  );
};

const SingleSelect = forwardRef(
  (
    {
      optionsArray,
      disabled,
      onChange,
      field,
      label,
      compulsory,
      style,
      value,
    },
    ref
  ) => {
    return (
      <div className="input-container" style={style}>
        {label && (
          <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
            {label}{" "}
            {compulsory && (
              <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
            )}
          </label>
        )}

        <Select
          options={optionsArray.map((opt) => {
            return { label: opt[field], value: opt };
          })}
          ref={ref}
          isDisabled={disabled}
          styles={customStyles}
          value={value}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator,
          }}
          onChange={onChange}
        />
      </div>
    );
  }
);

export default SingleSelect;
