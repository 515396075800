import { default as NewInsurances } from "./NewInsurances";
import { default as OrderedInsurances } from "./OrderedInsurances";
import { default as Installments } from "./Installments";
import { default as PaidFines } from "./PaidFines";
import { default as Vignettes } from "./Vignettes";
import { default as Parking } from "./Parking";
import { default as Claims } from "./Claims";
import { default as Costs } from "./Costs";
import { default as Events } from "./Events";
import { default as GeoWash } from "./GeoWash";
import { default as Dash } from "./Dash";
const AppGrids = {
  NewInsurances,
  OrderedInsurances,
  Installments,
  PaidFines,
  Vignettes,
  Parking,
  Costs,
  Claims,
  Events,
  GeoWash,
  Dash,
};

export default AppGrids;
