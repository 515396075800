/* eslint-disable */
import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { FinancesStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getGeneratedIncomeOnlineEpic = (action$) =>
  action$.pipe(
    ofType(FinancesStats.GET_GENERATED_INCOME_ONLINE),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_URL}/finances/incomeOnline`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: FinancesStats.GET_INCOME_SUCCESS,
              payload: response.payload,
              key: "incomeOnline",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getGeneratedIncomeInstallmentsEpic = (action$) =>
  action$.pipe(
    ofType(FinancesStats.GET_GENERATED_INCOME_INSTALLEMENTS),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_URL}/finances/incomeDeliveryInstallments`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: FinancesStats.GET_INCOME_SUCCESS,
              payload: response.payload,
              key: "incomeInstallments",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getGeneratedIncomePoliciesEpic = (action$) =>
  action$.pipe(
    ofType(FinancesStats.GET_GENERATED_INCOME_POLICIES),
    switchMap(({ onSuccess, payload }) =>
      ajax({
        url: `${API_URL}/finances/incomeDeliveryPolicies`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: FinancesStats.GET_INCOME_SUCCESS,
              payload: response.payload,
              key: "incomePolicies",
            });
            if (payload.onSuccess) payload.onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
export const getPotentialIncomeEpic = (action$) =>
  action$.pipe(
    ofType(FinancesStats.GET_POTENTIAL_INCOME),
    switchMap(({ payload }) =>
      ajax({
        url: `${API_URL}/finances/potentialIncome`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            obs.next({
              type: FinancesStats.GET_POTENTIAL_INCOME_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response.payload);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            if (err.status === 401) {
              localStorage.clear();
              window.location.href = "/login";
            }
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
