export const OfficesStats = {
  GET_OFFICES: "offices/GET_OFFICES",
  GET_OFFICES_SUCCESS: "offices/GET_OFFICES_SUCCESS",
  GET_CURRENT_OFFICE: "offices/GET_CURRENT_OFFICE",
  GET_CURRENT_OFFICE_SUCCESS: "offices/GET_CURRENT_OFFICE_SUCCESS",
  ADD_OFFICE: "offices/ADD_OFFICE",
  EDIT_OFFICE: "offices/EDIT_OFFICE",
  DELETE_OFFICE: "offices/DELETE_OFFICE",
};
export const getOffices = (payload) => ({
  type: OfficesStats.GET_OFFICES,
  payload,
});
export const getCurrentOffice = (payload) => ({
  type: OfficesStats.GET_CURRENT_OFFICE,
  payload,
});
export const addOffice = (payload) => ({
  type: OfficesStats.ADD_OFFICE,
  payload,
});
export const editOffice = (payload) => ({
  type: OfficesStats.EDIT_OFFICE,
  payload,
});
export const deleteOffice = (payload) => ({
  type: OfficesStats.DELETE_OFFICE,
  payload,
});
