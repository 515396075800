/* eslint-disable */
import React, { useEffect, useMemo, useState, useRef } from "react";
import "./styles.scss";
import moment from "moment";
// import { getMachineSchedule, addPerformer } from "../../actions"
import { useDispatch, useSelector } from "react-redux";
import Inputs from "../Inputs";

export default function CheckListPopup({ data, startDate, endDate, onClose, handleCheckList, payloadPerformers, print, addButtonDisabled, usersList }) {
  const closeIcon = require("../../assets/icons/close.svg");
  const dispatch = useDispatch();
  const checkListRef = useRef();
  const requestData = useSelector((state) => state?.production?.productSchedule);

  const [chosen, setChosen] = useState([]);
  const [performersList, setPerformersList] = useState(data);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPerformerAdded, setIsPerformerAdded] = useState(false);
  let performersListLowerCase = performersList.slice().map((performer) => (performer = performer.toLowerCase()));

  let isPerformerNew = !performersListLowerCase.includes(searchQuery.toLowerCase()) && searchQuery.length >= 2;

  const checkPerformers = (requestData) => {
    let newData = data.slice(0);
    requestData &&
      startDate &&
      Object.values(requestData).map((machineRequests) => {
        machineRequests.map((request) => {
          if (
            moment(startDate).startOf("minute").isBetween(moment(request.start).startOf("minute"), moment(request.end).startOf("minute")) ||
            (moment(startDate).startOf("minute").isBefore(moment(request.start).startOf("minute")) &&
              moment(endDate).startOf("minute").isBetween(moment(request.start).startOf("minute"), moment(request.end).startOf("minute"))) ||
            moment(startDate).startOf("minute").isSame(moment(request.start).startOf("minute"))
          ) {
            newData = newData.filter((performer) => (request?.performers ? !request?.performers.includes(performer) : request?.performer !== performer));
          }
        });
      });
    return newData;
  };

  useEffect(() => {
    payloadPerformers && setChosen(payloadPerformers);
    setPerformersList(checkPerformers(requestData));
  }, [payloadPerformers, startDate, endDate, data]);

  useEffect(() => {
    if (usersList) setChosen(usersList);
  }, [usersList]);

  return (
    <div className="check-list-popup-container" style={{ padding: "10px" }}>
      <div className="flex-container align-center space-between">
        <h3 className="heading">Добави изпълнител</h3>
        <img src={closeIcon} alt="x" onClick={onClose} className="close-icon" style={{ minWidth: 12, maxWidth: 12, minHeight: 12, maxHeight: 12 }}></img>
      </div>

      <div className="check-list-main">
        <div className="check-list-top-wrapper">
          <Inputs.TextInput
            placeholder="Търси по име"
            label={"Членове на екипа"}
            style={{ margin: "10px 0px" }}
            value={searchQuery}
            onChange={(e) => {
              if (isPerformerAdded) {
                setIsPerformerAdded(false);
                e.target.value = "";
              }
              setSearchQuery(e.target.value);
            }}
          />
        </div>

        <div className="check-list-wrapper">
          <div className="check-list-top">
            <div className={`performer ${data?.length === chosen?.length && "selected"}`}>Избери всички</div>
            <div
              className={`checkbox-container ${data?.length === chosen?.length && "selected"}`}
              onClick={() => {
                if (data?.length === chosen?.length) {
                  setChosen([]);
                } else {
                  setChosen(data);
                }
              }}
            >
              {data?.length === chosen?.length && <div className="checkbox" />}
            </div>
          </div>

          {performersList
            .filter((el) => el.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((performer, index) => {
              const checked = chosen.findIndex((el) => el === performer) !== -1;
              return (
                <div className="check-list" key={`checklist-performer-${index}`}>
                  <div className={`performer ${checked && "selected"}`}>{performer}</div>
                  <div
                    className={`checkbox-container ${checked && "selected"}`}
                    onClick={() => {
                      const newChosen = chosen.slice(0);
                      const index = newChosen.findIndex((el) => el === performer);
                      if (index === -1) {
                        newChosen.push(performer);
                      } else {
                        newChosen.splice(index, 1);
                      }
                      setChosen(newChosen);
                    }}
                  >
                    {checked && <div className="checkbox" />}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="btn-wrapper">
        <Inputs.Button
          text="Избери"
          style={{ width: "100%" }}
          onClick={() => {
            handleCheckList(chosen);
            onClose();
          }}
        />
        {!addButtonDisabled && (
          <Inputs.Button
            text="Добави"
            className="raised-btn add"
            disabled={performersList.includes(searchQuery) || searchQuery.length < 2 || !(searchQuery.replace(/\s/g, "").length !== 0)}
            onClick={() => {
              if (isPerformerNew) {
                let updatedList = performersList.slice();
                updatedList.push(searchQuery);
                setPerformersList(updatedList);
                setSearchQuery("");
                setIsPerformerAdded(true);
              }
            }}
          />
        )}
      </div>
    </div>
  );
}
