/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { getGeowashOrders } from "../../../actions";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment";
import { connect } from "react-redux";
import Statistics from "../../Statistics";

const headerElements = [
  {
    name: "User",
    query: { filter: "Date", options: [] },
  },
  {
    name: "Phone",
    query: { filter: "Time", options: [] },
  },
  {
    name: "Payment date",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Type",
    query: { filter: "Invitation sent by", options: [] },
  },
  {
    name: "Amount",
    query: { filter: "Invitation sent by", options: [] },
  },
];

const GeoWash = ({
  getGeowashOrders,
  geoWash,
  period,
  setLoading,
  loading,
}) => {
  const [curPage, setCurPage] = useState(2);
  const tableRef = useRef();
  const [innerLoading, setInnerLoading] = useState(false);

  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && geoWash.totalPages >= curPage && !innerLoading) {
      setInnerLoading(true);
      if (period.createdAtFrom) {
        getGeowashOrders({
          pageNumber: curPage,
          limit: 20,
          ...period,
          onSuccess: (res) => setInnerLoading(false),
        });
      } else
        getGeowashOrders({
          pageNumber: curPage,
          limit: 20,
          onSuccess: (res) => setInnerLoading(false),
        });
      setCurPage(curPage + 1);
    }
  };

  useEffect(() => {
    getGeowashOrders({
      pageNumber: 1,
      limit: 20,
      onSuccess: (res) => {
        setLoading(false);
      },
    });
  }, []);

  useEffect(() => {
    if (period.createdAtFrom) {
      getGeowashOrders({
        pageNumber: 1,
        limit: 20,
        ...period,
        onSuccess: (res) => {
          setLoading(false);
        },
      });
      setCurPage(2);
    }
  }, [period]);

  return (
    <>
      {loading === true ? (
        <div style={{ height: "90%" }}>
          {<Statistics.LoaderInline center />}
        </div>
      ) : (
        <>
          <h2 style={{ marginBottom: "20px", textTransform: "uppercase" }}>
            Users with created orders
          </h2>
          <Scrollbars
            style={{ height: "92%", width: "100%" }}
            onUpdate={handleUpdate}
            ref={tableRef}
            id={"scrollbar"}
            renderView={(props) => (
              <div {...props} style={{ ...props.style, overflowX: "hidden" }} />
            )}
          >
            <div className="table-header">
              {headerElements?.map((header, i) => {
                return (
                  <div
                    className="header-cont"
                    key={i}
                    style={{
                      width: "12%",
                    }}
                  >
                    <div className="header">{header.name}</div>
                  </div>
                );
              })}
            </div>
            {geoWash?.docs?.map((order, i) => {
              return (
                <div className="table-body-row" key={i}>
                  <div className="row-data" style={{ width: "12%" }}>
                    {order?.fullName || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {order?.phoneNumber || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {moment(order?.paymentDate).format("DD.MM.YYYY")}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {order?.reservationType || "---"}
                  </div>
                  <div className="row-data" style={{ width: "12%" }}>
                    {order?.price + " BGN" || "---"}
                  </div>
                </div>
              );
            })}
             {innerLoading && (
              <div className="inner-loader-container">
                <Statistics.LoaderInline center />
              </div>
            )}
          </Scrollbars>
        </>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  geoWash: state.app.geoWash,
});
const mapDispatchToProps = (dispatch) => ({
  getGeowashOrders: (payload) => dispatch(getGeowashOrders(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GeoWash);
