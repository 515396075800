/* eslint-disable */
import React, { useEffect, useState } from "react";
import Inputs from "../../../../components/Inputs";
import { Controller } from "react-hook-form";
import "../../styles.scss";

const discountOptions = [
  {
    label: "All insurances",
    value: "all",
  },
  {
    label: "Custom",
    value: "custom",
  },
];

const typeButtons = [
  {
    label: "BGN",
    value: false,
  },
  {
    label: "%",
    value: true,
  },
];

const DiscountInformation = ({ control, setValue, register, errors, watch, discountInfo, setDiscountInfo, insurancesOptions }) => {
  return (
    <div className="body-container" style={{ height: "unset", marginTop: "1%" }}>
      <h2 className="inner-title" style={{ margin: "1% 0%" }}>
        Discount information
      </h2>
      <div className="flex-container full-width align-center">
        <div style={{ width: "17%" }}>
          <Controller
            control={control}
            name={"genericDiscount"}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Inputs.RadioButtons
                options={discountOptions}
                value={discountInfo.type}
                label="Discount for"
                className={error && "failed"}
                compulsory
                onChange={(opt) => {
                  if (opt === "all") setValue("genericDiscount", true);
                  else setValue("genericDiscount", false);
                  setDiscountInfo({ ...discountInfo, type: opt });
                }}
              />
            )}
            rules={{
              validate: (value) => {
                if (value == null || value == undefined) {
                  return false;
                }
                return true;
              },
            }}
          />
        </div>
        {discountInfo.type === "all" && (
          <>
            <Inputs.TextInput label={"Discount"} compulsory number style={{ width: "10%", margin: "0% 1%" }} className={errors?.discount && "failed"} {...register(`discount`, { required: discountInfo.type === "all" && true, valueAsNumber: true })} />
          </>
        )}
        {discountInfo.type && (
          <>
            {typeButtons.map((type) => (
              <Controller
                control={control}
                name={"discountInPercent"}
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Inputs.Button
                    text={type.label}
                    key={type.label}
                    selected={watch("discountInPercent") === type.value}
                    style={{
                      width: "7%",
                      marginTop: "1%",
                      borderBottomLeftRadius: type.value && "0px",
                      borderTopLeftRadius: type.value && "0px",
                      borderBottomRightRadius: !type.value && "0px",
                      borderTopRightRadius: !type.value && "0px",
                      border: error && "1px solid red",
                    }}
                    onClick={() => setValue("discountInPercent", type.value)}
                  />
                )}
                rules={{
                  validate: (value) => {
                    if (value == null || value == undefined) {
                      return false;
                    }
                    return true;
                  },
                }}
              />
            ))}
          </>
        )}
      </div>
      {discountInfo.type === "custom" && (
        <Controller
          control={control}
          name={"customDiscounts"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.CheckBoxes
              options={insurancesOptions}
              value={value}
              label="Discounted insurances"
              compulsory
              column
              input={(box) => {
                return (
                  value?.filter((obj) => obj.insuranceType === box.value)?.[0] && (
                    <div className="flex-container align-center justify-end" style={{ width: "30%" }}>
                      <Inputs.Switch
                        label={"General discount"}
                        value={value?.filter((obj) => obj.insuranceType === box.value)?.[0]?.general}
                        style={{ marginRight: "2%" }}
                        onChange={(e) => {
                          const valueCopy = value.slice(0);
                          const foundInsuranceType = value.findIndex((obj) => obj.insuranceType === box.value);
                          if (valueCopy[foundInsuranceType].general) valueCopy[foundInsuranceType].discount = null;
                          else valueCopy[foundInsuranceType].companies.map((comp) => (comp.discount = 0));
                          valueCopy[foundInsuranceType].companies = [...new Map(valueCopy[foundInsuranceType].companies.map((v) => [v.insuranceAgency, v])).values()]; // removes copies from companies
                          valueCopy[foundInsuranceType].general = !valueCopy[foundInsuranceType].general;

                          setValue("customDiscounts", valueCopy);
                        }}
                      />
                      <Inputs.TextInput
                        number
                        style={{ width: "40%" }}
                        disabled={!value?.filter((obj) => obj.insuranceType === box.value)?.[0]?.general}
                        suffix={<div className="flex-container justify-center align-center">{watch().discountInPercent === undefined ? "-" : watch().discountInPercent === true ? "%" : "BGN"}</div>}
                        value={value?.filter((obj) => obj.insuranceType === box.value)?.[0]?.discount || "---"}
                        onChange={(e) => {
                          const valueCopy = value.slice(0);
                          const foundInsuranceType = value.findIndex((obj) => obj.insuranceType === box.value);
                          valueCopy[foundInsuranceType].discount = Number(e.target.value);
                          setValue("customDiscounts", valueCopy);
                        }}
                      />
                    </div>
                  )
                );
              }}
              innerFields={(box) => {
                const foundInsurance = value?.filter((obj) => obj.insuranceType === box.value);
                console.log();
                1;
                return foundInsurance.length && !foundInsurance[0]?.general ? (
                  <div className="body-container flex-container full-width wrap" style={{ marginBottom: "2%" }}>
                    {foundInsurance[0]?.companies?.map((company) => (
                      <React.Fragment key={company?.insuranceAgency}>
                        <Inputs.TextInput
                          style={{ width: "11%", margin: "0.5% 1% 0% 0%" }}
                          label={company.insuranceAgencyName}
                          value={company?.discount || ""}
                          suffix={<div className="flex-container justify-center align-center">{watch().discountInPercent === undefined ? "-" : watch().discountInPercent === true ? "%" : "BGN"}</div>}
                          onChange={(e) => {
                            const newValueInfo = value.slice(0);
                            const foundInsuranceType = value.findIndex((obj) => obj.insuranceType === box.value);
                            newValueInfo[foundInsuranceType].companies.filter((comp) => comp.insuranceAgency === company.insuranceAgency)[0].discount = Number(e.target.value);
                            setValue("customDiscounts", newValueInfo);
                          }}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                ) : null;
              }}
              onChange={(e) => {
                const foundIndex = value.findIndex((disc) => disc.insuranceType === e.value);
                if (foundIndex > -1)
                  setValue(
                    "customDiscounts",
                    value.filter((disc, i) => i !== foundIndex)
                  );
                else setValue("customDiscounts", [...value, { insuranceType: e.value, discount: null, general: true, companies: e.companies }]);
              }}
            />
          )}
        />
      )}
    </div>
  );
};

export default DiscountInformation;
