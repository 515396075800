export const discountsStats = {
  GET_DISCOUNTS: "discounts/GET_DISCOUNTS",
  GET_DISCOUNTS_SUCCESS: "discounts/GET_DISCOUNTS_SUCCESS",
  GET_DISCOUNTS_USED: "discounts/GET_DISCOUNTS_USED",
  GET_DISCOUNTS_USED_SUCCESS: "discounts/GET_DISCOUNTS_USED_SUCCESS",
  CREATE_DISCOUNT: "discounts/CREATE_DISCOUNT",
  EDIT_UPCOMING_DISCOUNT: "discounts/EDIT_UPCOMING_DISCOUNT",
  DELETE_UPCOMING_DISCOUNT: "discounts/DELETE_UPCOMING_DISCOUNT",
  GET_INSURANCE_AGENCIES: "discounts/GET_INSURANCE_AGENCIES",
  GET_INSURANCE_AGENCIES_SUCCESS: "discounts/GET_INSURANCE_AGENCIES_SUCCESS",
  DEACTIVATE_ACTIVE_DISCOUNT: "discounts/DEACTIVATE_ACTIVE_DISCOUNT",
  GET_CURRENT_DISCOUNT: "discounts/GET_CURRENT_DISCOUNT",
};
export const getDiscounts = (payload) => ({
  type: discountsStats.GET_DISCOUNTS,
  payload,
});
export const getDiscountsUsed = (payload) => ({
  type: discountsStats.GET_DISCOUNTS_USED,
  payload,
});
export const getInsuranceAgencies = (payload) => ({
  type: discountsStats.GET_INSURANCE_AGENCIES,
  payload,
});
export const getCurrentDiscount = (payload) => ({
  type: discountsStats.GET_CURRENT_DISCOUNT,
  payload,
});
export const createDiscount = (payload) => ({
  type: discountsStats.CREATE_DISCOUNT,
  payload,
});
export const editUpcomingDiscount = (payload) => ({
  type: discountsStats.EDIT_UPCOMING_DISCOUNT,
  payload,
});
export const deleteUpcomingDiscount = (payload) => ({
  type: discountsStats.DELETE_UPCOMING_DISCOUNT,
  payload,
});
export const deactivateActiveDiscount = (payload) => ({
  type: discountsStats.DEACTIVATE_ACTIVE_DISCOUNT,
  payload,
});
