import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { authTypes } from "../actions";
import { API_URL } from "../config/settings";
import { User } from "../utilites";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";

export const loginRequestCodeEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.REQUEST_CODE),
    switchMap(({ payload }) => {
      const { token, ...rest } = payload;
      return ajax({
        url: `${API_URL}/user/dispatcher/${nanoid()}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "amarant-admin": nanoid(),
        },
        body: JSON.stringify(rest),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err?.response?.message);
            obs.complete();
          });
        })
      );
    })
  );

export const checkCodeEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.ACTIVATE_CODE),
    switchMap(({ payload }) => {
      const { token, ...rest } = payload;
      return ajax({
        url: `${API_URL}/user/dispatcher/${nanoid()}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "amarant-admin": nanoid(),
        },
        body: JSON.stringify(rest),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err?.response?.message);
            payload.onError();
            obs.complete();
          });
        })
      );
    })
  );

export const logInEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.LOG_IN),
    switchMap(({ payload }) => {
      const { token, ...rest } = payload;
      return ajax({
        url: `${API_URL}/user/dispatcher/${nanoid()}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          "amarant-admin": nanoid(),
        },
        body: JSON.stringify(rest),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            User.authenticate(response.payload.token, {
              token: response.payload.token,
              ...response.payload,
            });
            obs.next({
              type: authTypes.LOG_IN_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );
export const logOut = (action$) =>
  action$.pipe(
    ofType(authTypes.LOG_OUT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        // User.signout();
        // window.localStorage.clear();
        // obs.next({
        //   type: authTypes.LOG_OUT_SUCCESS,
        // });
      })
    )
  );
