/* eslint-disable */
import React, { useState } from "react";
import Inputs from "../../../../components/Inputs";
import { connect } from "react-redux";
import { Controller } from "react-hook-form";
import "../../styles.scss";
import moment from "moment";

const applyForOptions = [
  {
    label: "First order",
    value: "first",
  },
  {
    label: "All orders",
    value: "all",
  },
];

const GeneralInformation = ({ control, setValue, register, errors, disabled }) => {
  return (
    <div className="body-container" style={{ height: "unset", marginTop: "1%" }}>
      <h2 className="inner-title" style={{ margin: "1% 0%" }}>
        General information
      </h2>
      <div className="flex-container align-center full-width">
        <Inputs.TextInput label={"Campaign title"} compulsory style={{ width: "22%", margin: "0% 1% 0% 0%" }} disabled={disabled} className={errors.name && "failed"} {...register(`name`, { required: true })} />
        <Controller
          control={control}
          name={"startDate"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.DatePicker
              label={"Start date"}
              compulsory
              style={{ width: "13%", height: "100%", margin: "0% 1%" }}
              value={value ? moment(value)._d : null}
              disabled={disabled}
              onChange={(e) => {
                setValue("startDate", e.toISOString());
              }}
              className={error && "failed"}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={"endDate"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.DatePicker
              label={"End date"}
              style={{ width: "13%", height: "100%", margin: "0% 1%" }}
              value={value ? moment(value)._d : null}
              // disabled={disabled}
              onChange={(e) => {
                setValue("endDate", moment(e).endOf("day").toISOString());
              }}
              className={error && "failed"}
            />
          )}
        />
        <Controller
          control={control}
          name={"discountCondition"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.SingleSelect
              optionsArray={applyForOptions}
              label={"Apply for"}
              field="label"
              style={{ width: "25%", margin: "0% 1%" }}
              compulsory
              value={{ value: value, label: value }}
              disabled={disabled}
              className={error && "error"}
              onChange={(e) => {
                setValue("discountCondition", e.value.value);
              }}
            />
          )}
          rules={{ required: true }}
        />
        <Inputs.TextInput label={"Code"} compulsory style={{ width: "15%", margin: "0% 1%" }} disabled={disabled} className={errors?.discountCode && "failed"} {...register(`discountCode`, { required: true, pattern: /^[a-z0-9]+$/i })} />
      </div>
      <Inputs.TextInput label={"Comment"} style={{ width: "99%", margin: "1% 0%" }} disabled={disabled} className={errors?.comment && "failed"} {...register(`comment`)} />
    </div>
  );
};

export default GeneralInformation;
